import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { Title, LoadingCard } from '../../Shared';
import { equipmentActions } from '../../_actions';
import ReactPlayer from 'react-player';

function EquipmentSingle(props) {
  const { single, getSingle, loading } = props;
  useEffect(() => {
    const { equipId } = props.match.params;
    if (equipId) {
      getSingle(equipId);
    }
  }, [getSingle, props.match.params]);

  return (
    <Fragment>
      {loading && <LoadingCard />}
      {!loading && single && (
        <Fragment>
          <Title text={single.name} />
          <Container className="mt-4">
            <Row>
              <Col>
                <h3>{single.name}</h3>
                {single.videoUrl && (
                  <div className="player-wrapper">
                    <ReactPlayer
                      className="react-player"
                      url={single.videoUrl}
                      width="100%"
                      height="100%"
                    />
                  </div>
                )}
                {single.imageUrl && (
                  <img className="img-fluid" alt={single.name} src={single.imageUrl} />
                )}
                <p>
                  <strong>Instructions</strong>
                </p>
                <div dangerouslySetInnerHTML={{ __html: single.instructions }} />
                {single.content}
              </Col>
            </Row>
          </Container>
        </Fragment>
      )}
    </Fragment>
  );
}

function mapStateToProps(state) {
  const { single = null, loading = true } = state.equipment;
  return { single, loading };
}

const mapDispatchToProps = {
  getSingle: equipmentActions.getSingle
};

const connectedEquipmentSingle = connect(
  mapStateToProps,
  mapDispatchToProps
)(EquipmentSingle);
export { connectedEquipmentSingle as EquipmentSingle };
