import React from 'react';
import PropTypes from 'prop-types';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export class RichEditor extends React.Component {
  state = { hasFocus: false };
  classes = 'editor-class';
  focusClasses = 'editor-class editor-has-focus';

  componentDidUpdate = prevProps => {
    // only make this ugly ass update when the component is intialising with state from api
    if (prevProps.initialState !== this.props.initialState && !this.props.controlled) {
      const blocksFromHtml = htmlToDraft(this.props.initialState || '<p></p>');
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      const editorState = EditorState.createWithContent(contentState);
      this.editorReference.update(editorState);
    }
  };

  setRef = ref => {
    this.editorReference = ref;
  };

  onChange = editorState => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const markup = draftToHtml(rawContentState);
    const { name = 'summary' } = this.props;
    this.props.handleChange({ target: { name: name, value: markup } });
  };
  render() {
    const blocksFromHtml = htmlToDraft(this.props.initialState || '');
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const editorState = EditorState.createWithContent(contentState);
    return (
      <Editor
        editorRef={this.setRef}
        onEditorStateChange={this.onChange}
        onFocus={() => this.setState({ hasFocus: true })}
        onBlur={() => this.setState({ hasFocus: false })}
        editorClassName={this.state.hasFocus ? this.focusClasses : this.classes}
        defaultEditorState={editorState}
        stripPastedStyles={true}
        toolbar={{
          options: ['inline', 'blockType', 'fontSize', 'list', 'link', 'emoji', 'textAlign'],
          inline: {
            options: ['bold', 'italic', 'underline'],
            bold: { className: 'bordered-option-classname' },
            italic: { className: 'bordered-option-classname' },
            underline: { className: 'bordered-option-classname' }
          },
          textAlign: {
            inDropdown: true
          },
          blockType: {
            className: 'bordered-option-classname',
            options: ['Normal', 'H3', 'H4', 'H5', 'H6', 'Blockquote']
          },
          fontSize: {
            className: 'bordered-option-classname',
            options: [8, 9, 10, 11, 12, 14, 16, 18, 24]
          }
        }}
      />
    );
  }
}

RichEditor.propTypes = {
  initialState: PropTypes.string,
  name: PropTypes.string,
  handleChange: PropTypes.func.isRequired
};
