import React from 'react';
import { InputGroup, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const divStyles = {
  position: 'absolute',
  right: 0,
  padding: `0.375rem 0.75rem`,
  zIndex: 3
};

export function LookupFilter(props) {
  const { handleChange } = props;
  return (
    <InputGroup>
      <Input name="terms" onChange={handleChange} placeholder="Filter results" />
      <div style={divStyles}>
        <FontAwesomeIcon icon="times" />
      </div>
    </InputGroup>
  );
}
