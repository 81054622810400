import { userManagementConstants } from '../_constants';

export function userManagement(state = {}, action) {
  switch (action.type) {
    case userManagementConstants.CREATE_USER_REQUEST:
      return {
        ...state
      };
    case userManagementConstants.CREATE_USER_SUCCESS:
      return {
        ...state,
        user: action.data
      };
    case userManagementConstants.CREATE_USER_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case userManagementConstants.EDIT_USER_REQUEST:
      return {
        ...state,
        editing: true
      };
    case userManagementConstants.EDIT_USER_SUCCCESS:
      return {
        ...state,
        user: action.data,
        editing: false
      };
    case userManagementConstants.EDIT_USER_FAILURE:
      return {
        ...state,
        error: action.error,
        editing: false
      };
    case userManagementConstants.GET_ROLES_SUCCESS:
      return {
        ...state,
        roles: action.data
      };
    case userManagementConstants.GET_USERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userManagementConstants.GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.data
      };
    case userManagementConstants.GET_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userManagementConstants.GET_USER_REQUEST:
      return {
        ...state,
        user: null,
        loading: true
      };
    case userManagementConstants.GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.data
      };
    case userManagementConstants.GET_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userManagementConstants.DISABLE_USER_REQUEST:
      return {
        ...state,
        disable: true
      };
    case userManagementConstants.DISABLE_USER_SUCCESS:
      const user = state.users.filter(e => e.id !== action.data);
      return {
        ...state,
        users: user,
        disable: true
      };
    case userManagementConstants.DISABLE_USER_FAILURE:
      return {
        ...state,
        disable: false
      };
    case userManagementConstants.FILTER_USERS:
      const filtered = state.users.filter(
        u => u.userName.indexOf(action.terms) >= 0
      );
      return {
        ...state,
        filtering: true,
        filtered
      };
    case userManagementConstants.CLEAR_FILTER:
      return {
        ...state,
        filtering: false,
        filtered: null
      };
    case userManagementConstants.GET_ROLES_FAILURE:
    case userManagementConstants.GET_ROLES_REQUEST:
    default:
      return state;
  }
}
