import { useState, useEffect } from 'react';
import validate from './addUserFormValidation';
const defaultValues = {
  firstName: '',
  lastName: '',
  userName: '',
  email: '',
  phoneNumber: '',
  password: ''
};
const defaultErrors = {
  firstName: null,
  lastName: null,
  userName: null,
  email: null,
  phoneNumber: null,
  password: null
};
export const useAddUserForm = (callback, success) => {
  const [values, setValues] = useState({ ...defaultValues });
  const [errors, setErrors] = useState({ ...defaultErrors });

  useEffect(() => {
    if (success) {
      setValues({ ...defaultValues });
      setErrors({ ...defaultErrors });
    }
  }, [success]);

  const handleSubmit = event => {
    if (event) {
      event.preventDefault();
    }
    setErrors(validate(values, errors));
    if (Object.values(errors).every(e => e === true)) {
      callback({ ...values });
    }
  };

  const handleChange = event => {
    event.persist();
    setValues(values => ({ ...values, [event.target.name]: event.target.value }));
    setErrors(validate(values, errors));
  };

  return {
    handleChange,
    handleSubmit,
    values,
    errors
  };
};
