import { authFetch, headers, handleResponse } from '../_helpers';

export const leaderboardService = {
  listResults,
  listUserResults,
  addToLeaderboard,
  myResults,
  getOverviewLeaderboard
};

async function listResults(locationId) {
  const res = await authFetch('/api/Leaderboard/ListResults', headers.postPrimitive(locationId));
  return res;
}

async function listUserResults() {
  const res = await authFetch('/api/Leaderboard/ListUserResults', headers.post());
  return res;
}

async function getOverviewLeaderboard() {
  const response = await fetch(`/api/Leaderboard/GetOverview`, headers.get());
  const data = await handleResponse(response);
  return data;
}

async function addToLeaderboard(result) {
  const res = await authFetch('/api/Leaderboard/AddToLeaderboard', headers.post(result));
  return res;
}

async function myResults() {
  const res = await authFetch('/api/Leaderboard/MyResults', headers.get());
  return res;
}
