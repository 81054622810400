import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { Title, LoadingCard } from '../../Shared';
import { locationActions } from '../../_actions';

function LocationSingle(props) {
  const { single, loading, getSingle } = props;

  useEffect(() => {
    const { path } = props.match.params;
    if (path) {
      getSingle(path);
    }
  }, [getSingle, props.match.params]);

  return (
    <Container className="mt-4">
      <Row>
        {loading && <LoadingCard />}
        {!loading && single && (
          <Fragment>
            <Title text={single.name} />
            <Col>
              {Object.keys(single).map((k, idx) => (
                <p key={idx}>
                  <strong>{k}</strong>: {single[k]}
                </p>
              ))}
            </Col>
          </Fragment>
        )}
      </Row>
    </Container>
  );
}

function mapStateToProps(state) {
  const { single, loading } = state.location;
  return { single, loading };
}

const mapDispatchToProps = {
  getSingle: locationActions.getSingle
};
const connectedLocationSingle = connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationSingle);

export { connectedLocationSingle as LocationSingle };
