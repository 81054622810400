import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, Row } from 'reactstrap';
import { EventCard } from './EventCard';
import { Title, LoadingCard, LookupFilter } from '../../Shared';
import { eventActions } from '../../_actions';
import { AdminHeader, ViewEditFooter, RemoveFooter, RemoveModal } from '../';
import { isEmpty, debounce } from 'lodash';

function EventList(props) {
  const { getAllEvents, events, loading, remove, removeEvent, filtered, filtering } = props;
  const [modal, setModal] = useState(false);
  const [removeId, setRemoveId] = useState(null);
  useEffect(() => {
    getAllEvents();
  }, [getAllEvents]);

  const toggleModal = () => {
    setModal(!modal);
  };

  const handleRemove = id => {
    setRemoveId(id);
    toggleModal();
  };

  const handleDelete = id => {
    toggleModal();
    removeEvent(id);
  };

  const delayedFilter = debounce(e => {
    props.filter(e.target.value);
  }, 350);

  const handleChange = e => {
    e.persist();
    delayedFilter(e);
  };

  const event = events && events.find(e => e.id === removeId);

  return (
    <Fragment>
      <Title text="All Events" />
      <Container>
        <AdminHeader goBack title="Manage Events" />
        <LookupFilter handleChange={handleChange} />
        <Row style={{ marginBottom: '20px', marginTop: '20px' }}>
          {loading && <LoadingCard />}
          {!loading &&
            filtering &&
            !isEmpty(filtered) &&
            filtered.map((event, idx) => (
              <EventCard key={idx} event={event}>
                {remove ? (
                  <RemoveFooter id={event.id} handleRemove={handleRemove} />
                ) : (
                  <ViewEditFooter id={event.id} path={event.path} urlPath="Event" />
                )}
              </EventCard>
            ))}
          {!filtering &&
            !isEmpty(events) &&
            events.map((event, idx) => (
              <EventCard key={idx} event={event}>
                {remove ? (
                  <RemoveFooter id={event.id} handleRemove={handleRemove} />
                ) : (
                  <ViewEditFooter id={event.id} path={event.path} urlPath="Event" />
                )}
              </EventCard>
            ))}
        </Row>
      </Container>
      {event && (
        <RemoveModal
          item={event}
          modal={modal}
          toggle={toggleModal}
          handleDelete={handleDelete}
          title="Delete Event"
        />
      )}
    </Fragment>
  );
}
const mapDispatchToProps = {
  getAllEvents: eventActions.getAll,
  removeEvent: eventActions.remove,
  filter: eventActions.filter,
  removefilter: eventActions.removefilter
};
function mapStateToProps(state) {
  const { events, filtered, filtering = false, loading = true } = state.event;
  return { events, filtered, filtering, loading };
}

const connectedEventList = connect(
  mapStateToProps,
  mapDispatchToProps
)(EventList);
export { connectedEventList as EventList };
