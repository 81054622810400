import React from 'react';
import PropTypes from 'prop-types';
import { NavItem, NavLink } from 'reactstrap';
import { NavLink as RRNavLink } from 'react-router-dom';

export function RefereeNavItems({ navigated }) {
  return (
    <NavItem>
      <NavLink tag={RRNavLink} to="/Referee" onClick={navigated}>
        Referee
      </NavLink>
    </NavItem>
  );
}
RefereeNavItems.propTypes = {
  navigated: PropTypes.func.isRequired
};
