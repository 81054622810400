import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, FormGroup, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormWrapper, EmailInput, PasswordInput, UsernameInput } from './';
import { accountActions, authActions } from '../_actions';
import { inputValidation } from '../_helpers';
import { MobileInput } from '../Shared';

function SignUp(props) {
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [userName, setUserName] = useState('');
  const [validate, setValidate] = useState({ email: null, password: null, phoneNumber: null });
  const { registering, error, message, logoutNoRedirect } = props;
  useEffect(() => {
    logoutNoRedirect();
  }, [logoutNoRedirect]);

  const validatePassword = value => {
    const password = inputValidation.password(value);
    setValidate({ ...validate, password });
  };

  const validateEmail = value => {
    const email = inputValidation.email(value);
    setValidate({ ...validate, email });
  };

  const validatePhoneNumber = value => {
    const phoneNumber = inputValidation.mobile(value);
    setValidate({ ...validate, phoneNumber });
  };

  const handleSubmit = e => {
    e.preventDefault();
    props.signUp({ email, phoneNumber, password, userName });
  };

  return (
    <FormWrapper title="Sign Up">
      {message && <div style={{ color: '#66be6b' }}>{message}</div>}
      {error && <div style={{ color: 'red' }}>{error}</div>}
      <p>
        Signing up allows you to save your times, post to Leaderboards, view your history and obtain
        details of competition events.
      </p>
      {!message && (
        <Form onSubmit={handleSubmit}>
          <EmailInput
            handleChange={setEmail}
            email={email}
            validateEmail={validateEmail}
            validation={validate.email}
            withTip
          />

          <MobileInput
            handleChange={setPhoneNumber}
            phoneNumber={phoneNumber}
            validatePhoneNumber={validatePhoneNumber}
            validation={validate.phoneNumber}
            withTip
          />

          <UsernameInput withTip userName={userName} handleChange={setUserName} />

          <PasswordInput
            handleChange={setPassword}
            password={password}
            validatePassword={validatePassword}
            validation={validate.password}
          />

          <FormGroup>
            {registering ? (
              <Button block color="primary">
                <FontAwesomeIcon icon="spinner" spin /> Signing Up
              </Button>
            ) : (
              <Button block color="primary">
                Sign Up
              </Button>
            )}
          </FormGroup>
        </Form>
      )}
    </FormWrapper>
  );
}

const mapDispatch = {
  signUp: accountActions.signUp,
  logoutNoRedirect: authActions.logoutNoRedirect
};

function mapStateToProps(state) {
  const { registering, error, message } = state.auth;
  return { registering, error, message };
}

const connectedSignUp = connect(
  mapStateToProps,
  mapDispatch
)(SignUp);
export { connectedSignUp as SignUp };
