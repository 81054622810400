import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, Col, Row, InputGroup, CustomInput } from 'reactstrap';
import { locationActions } from '../_actions';
import { LocationCard } from '../Locations';
import { isEmpty } from 'lodash';

function FindLocation(props) {
  const { filtered, filtering, states, getLocationsAndStates } = props;

  useEffect(() => {
    getLocationsAndStates();
  }, [getLocationsAndStates]);

  const filterByState = value => {
    if (value === '0') {
      props.clearFilter();
    } else {
      props.filter(value);
    }
  };

  return (
    <Container>
      <Row>
        <Col xs={12}>
          <h4>Find Your Nearest Park Warrior Location</h4>
        </Col>
        <Col>
          {states && (
            <InputGroup>
              <CustomInput
                type="select"
                id="stateselect"
                name="stateId"
                onChange={e => filterByState(e.target.value)}
                defaultValue={0}
                required
              >
                <option value="0">Select State</option>
                {states.map((s, idx) => (
                  <option key={idx} value={s}>
                    {s}
                  </option>
                ))}
              </CustomInput>
            </InputGroup>
          )}
        </Col>
      </Row>

      <Row className="mt-3">
        {filtering &&
          !isEmpty(filtered) &&
          filtered.map((loc, idx) => <LocationCard key={idx} location={loc} />)}
      </Row>
    </Container>
  );
}

function mapStateToProps(state) {
  const { filtered, filtering = false, states } = state.location;
  return { filtered, filtering, states };
}
const mapDispatchToProps = {
  filter: locationActions.filter,
  clearFilter: locationActions.removeFilter,
  getLocationsAndStates: locationActions.getLocationsAndStates,
  locationsByDistance: locationActions.locationsByDistance
};
const connectedFindLocation = connect(
  mapStateToProps,
  mapDispatchToProps
)(FindLocation);

export { connectedFindLocation as FindLocation };
