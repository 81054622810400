import React, { Fragment } from 'react';
import { Container } from 'reactstrap';
import { Title, LineSeparate } from '../Shared';
import {
  EquipmentPanels,
  LocationPanels,
  EventPanels,
  UserMgmtPanels,
  AdminHeader,
  ContentPanels,
  SmsPanels
} from './';

export function Admin() {
  return (
    <Fragment>
      <Title text="Admin Tools" />
      <Container>
        <AdminHeader title="Admin Tools" />
        <EquipmentPanels />
        <LineSeparate />
        <LocationPanels />
        <LineSeparate />
        <EventPanels />
        <LineSeparate />
        <UserMgmtPanels />
        <LineSeparate />
        <ContentPanels />
        <LineSeparate />
        <SmsPanels />
      </Container>
    </Fragment>
  );
}
