import { userManagementConstants } from '../_constants';
import { userManagementService } from '../_services';
import { toastr } from 'react-redux-toastr';
import { history } from '../_helpers';

export const userManagementActions = {
  create,
  editUser,
  filter,
  removeFilter,
  getUsers,
  getSingle,
  disable,
  listRoles
};

function create(newUser) {
  return dispatch => {
    dispatch(request(newUser));
    userManagementService.create(newUser).then(
      data => {
        dispatch(success(data));
        toastr.success('User Added');
        history.push(`/Admin/UserManagement/Edit/${data.path}`);
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: userManagementConstants.CREATE_USER_REQUEST, data };
  }
  function success(data) {
    return { type: userManagementConstants.CREATE_USER_SUCCESS, data };
  }
  function failure(error) {
    return { type: userManagementConstants.CREATE_USER_FAILURE, error };
  }
}

function editUser(updatedUser) {
  return dispatch => {
    dispatch(request(updatedUser));
    userManagementService.editUser(updatedUser).then(
      data => {
        dispatch(success(data));
        toastr.success('User Updated');
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: userManagementConstants.EDIT_USER_REQUEST, data };
  }
  function success(data) {
    return { type: userManagementConstants.EDIT_USER_SUCCESS, data };
  }
  function failure(error) {
    return { type: userManagementConstants.EDIT_USER_FAILURE, error };
  }
}

function filter(terms) {
  return dispatch => {
    dispatch(filter(terms));
  };
  function filter(terms) {
    return { type: userManagementConstants.FILTER_USERS, terms };
  }
}

function removeFilter() {
  return dispatch => {
    dispatch(clear());
  };
  function clear() {
    return { type: userManagementConstants.CLEAR_FILTER };
  }
}

function getUsers() {
  return dispatch => {
    dispatch(request());
    userManagementService.getUsers().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: userManagementConstants.GET_USERS_REQUEST };
  }
  function success(data) {
    return { type: userManagementConstants.GET_USERS_SUCCESS, data };
  }
  function failure(error) {
    return { type: userManagementConstants.GET_USERS_FAILURE, error };
  }
}

function getSingle(userId) {
  return dispatch => {
    dispatch(request(userId));
    userManagementService.getSingle(userId).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: userManagementConstants.GET_USER_REQUEST, data };
  }
  function success(data) {
    return { type: userManagementConstants.GET_USER_SUCCESS, data };
  }
  function failure(error) {
    return { type: userManagementConstants.GET_USER_FAILURE, error };
  }
}

function disable(userId) {
  return dispatch => {
    dispatch(request(userId));
    userManagementService.disable(userId).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: userManagementConstants.DISABLE_USER_REQUEST, data };
  }
  function success(data) {
    return { type: userManagementConstants.DISABLE_USER_SUCCESS, data };
  }
  function failure(error) {
    return { type: userManagementConstants.DISABLE_USER_FAILURE, error };
  }
}

function listRoles() {
  return dispatch => {
    dispatch(request());
    userManagementService.listRoles().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: userManagementConstants.GET_ROLES_REQUEST };
  }
  function success(data) {
    return { type: userManagementConstants.GET_ROLES_SUCCESS, data };
  }
  function failure(error) {
    return { type: userManagementConstants.GET_ROLES_FAILURE, error };
  }
}
