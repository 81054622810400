import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

export function RemoveFooter(props) {
  return (
    <Button className="w-100 view" color="danger" onClick={() => props.handleRemove(props.id)}>
      Delete
    </Button>
  );
}
RemoveFooter.props = {
  handleRemove: PropTypes.func.isRequired
};
