import React from 'react';
import PropTypes from 'prop-types';
import { Col, Card, CardBody, CardFooter } from 'reactstrap';
import { date } from '../../_helpers/date';

export function EventCard(props) {
  const { event } = props;
  return (
    <Col xs={12} sm={4} md={3} className="mb-2 equipment-card">
      <Card className="shadow h-100">
        <CardBody className="text-center">
          <h5 className="mb-0">{event.name}</h5>
          <p className="mb-0">Event Details</p>
          <p className="mb-0">From: {date.event(event.startTime)}</p>
          <p className="mb-0">To: {date.event(event.endTime)}</p>
        </CardBody>
        <CardFooter>
          <div className="d-flex">{props.children}</div>
        </CardFooter>
      </Card>
    </Col>
  );
}
EventCard.propTypes = {
  event: PropTypes.object.isRequired
};
