import { authFetch, headers } from '../_helpers';

export const feedbackService = {
  submit
};

async function submit(feedback) {
  const response = await authFetch('/api/Account/EmailFeedback', headers.post(feedback));
  return response;
}
