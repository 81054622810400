import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { Title } from '../Shared';

export function FormWrapper(props) {
  const { title } = props;
  return (
    <Fragment>
      <Title text={title} />
      <Container className="mt-4">
        <Row>
          <Col sm={12} md={{ size: 4, offset: 4 }}>
            <h4>{title}</h4>
            {props.children}
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

FormWrapper.propTypes = {
  title: PropTypes.string.isRequired
};
