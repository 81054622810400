import { authConstants } from '../_constants';

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { loggedIn: true, user } : {};

export function auth(state = initialState, action) {
  switch (action.type) {
    case authConstants.LOGIN_NO_REDIRECT_REQUEST:
      return {
        loggingIn: true,
        user: action.user
      };
    case authConstants.LOGIN_SUCCESS:
    case authConstants.LOGIN_NO_REDIRECT_SUCCESS:
      return {
        loggedIn: true,
        loggingIn: false,
        user: action.user
      };
    case authConstants.LOGIN_FAILURE:
    case authConstants.LOGIN_NO_REDIRECT_FAILURE:
      return {
        error: true,
        loggingIn: false,
        message: 'The credentials provided are not valid'
      };
    case authConstants.LOGOUT:
      return {};
    default:
      return state;
  }
}
