import React from 'react';
import PropTypes from 'prop-types';
import { Col, Card, CardBody, CardFooter } from 'reactstrap';

export function EquipmentCard(props) {
  const { equipment } = props;
  return (
    <Col xs={12} sm={4} md={3} className="mb-2 equipment-card">
      <Card className="shadow h-100">
        <CardBody className="text-center">
          <p className="mb-0">{equipment.name}</p>
        </CardBody>
        <CardFooter>
          <div className="d-flex">{props.children}</div>
        </CardFooter>
      </Card>
    </Col>
  );
}
EquipmentCard.propTypes = {
  equipment: PropTypes.object.isRequired
};
