import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, Row } from 'reactstrap';
import { EquipmentCard } from './EquipmentCard';
import { Title, LoadingCard, LookupFilter } from '../../Shared';
import { equipmentActions } from '../../_actions';
import { AdminHeader, ViewEditFooter, RemoveFooter, RemoveModal } from '../';
import { debounce } from 'lodash';

function EquipmentList(props) {
  const { allEquipment, getAllEquipment, loading, remove, filtered, filtering } = props;
  const [modal, setModal] = useState(false);
  const [removeId, setRemoveId] = useState(null);

  useEffect(() => {
    getAllEquipment();
  }, [getAllEquipment]);

  const toggleModal = () => {
    setModal(!modal);
  };

  const handleRemove = id => {
    setRemoveId(id);
    toggleModal();
  };

  const handleDelete = id => {
    toggleModal();
    props.removeEquipment(id);
  };

  const delayedFilter = debounce(e => {
    props.filter(e.target.value);
  }, 350);

  const handleChange = e => {
    e.persist();
    delayedFilter(e);
  };

  const equipment = allEquipment && allEquipment.find(e => e.id === removeId);

  return (
    <Fragment>
      <Title text="All Equipment" />
      <Container>
        <AdminHeader goBack title="Manage Equipment" />
        <LookupFilter handleChange={handleChange} />
        <Row style={{ marginBottom: '20px', marginTop: '20px' }}>
          {loading && <LoadingCard />}
          {!loading &&
            filtering &&
            filtered.map((eq, idx) => (
              <EquipmentCard key={idx} equipment={eq}>
                {remove ? (
                  <RemoveFooter id={eq.id} handleRemove={handleRemove} />
                ) : (
                  <ViewEditFooter id={eq.id} path={eq.path} urlPath="Equipment" />
                )}
              </EquipmentCard>
            ))}
          {!loading &&
            !filtering &&
            allEquipment &&
            allEquipment.map((eq, idx) => (
              <EquipmentCard key={idx} equipment={eq}>
                {remove ? (
                  <RemoveFooter id={eq.id} handleRemove={handleRemove} />
                ) : (
                  <ViewEditFooter id={eq.id} path={eq.path} urlPath="Equipment" />
                )}
              </EquipmentCard>
            ))}
        </Row>
      </Container>
      {equipment && (
        <RemoveModal
          item={equipment}
          modal={modal}
          toggle={toggleModal}
          handleDelete={handleDelete}
          title="Delete Equipment"
        />
      )}
    </Fragment>
  );
}

const mapDispatchToProps = {
  getAllEquipment: equipmentActions.getAll,
  removeEquipment: equipmentActions.remove,
  filter: equipmentActions.filter,
  removeFilter: equipmentActions.removeFilter
};

function mapStateToProps(state) {
  const { allEquipment, filtered, filtering = false, loading = true } = state.equipment;
  return { allEquipment, loading, filtered, filtering };
}

const connectedEquipmentList = connect(
  mapStateToProps,
  mapDispatchToProps
)(EquipmentList);
export { connectedEquipmentList as EquipmentList };
