import React, { Fragment } from 'react';
import { Row } from 'reactstrap';
import { PanelCard } from '../Shared';

export function ContentPanels() {
  return (
    <Fragment>
      <h4>Site Content</h4>
      <Row>
        <PanelCard icon="pencil-alt" url="/Admin/Content/Add">
          <p className="mb-0">Add Content</p>
        </PanelCard>
        <PanelCard icon={['far', 'edit']} url="/Admin/Content/Edit">
          <p className="mb-0">Edit Content</p>
        </PanelCard>
        <PanelCard icon={['far', 'newspaper']} url="/Admin/Content/View">
          <p className="mb-0">View Content</p>
        </PanelCard>
      </Row>
    </Fragment>
  );
}
