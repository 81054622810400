export const equipmentConstants = {
  CREATE_EQUIPMENT_REQUEST: 'CREATE_EQUIPMENT_REQUEST',
  CREATE_EQUIPMENT_SUCCESS: 'CREATE_EQUIPMENT_SUCCESS',
  CREATE_EQUIPMENT_FAILURE: 'CREATE_EQUIPMENT_FAILURE',
  LIST_EQUIPMENT_REQUEST: 'LIST_EQUIPMENT_REQUEST',
  LIST_EQUIPMENT_SUCCESS: 'LIST_EQUIPMENT_SUCCESS',
  LIST_EQUIPMENT_FAILURE: 'LIST_EQUIPMENT_FAILURE',
  GET_EQUIPMENT_REQUEST: 'GET_EQUIPMENT_REQUEST',
  GET_EQUIPMENT_SUCCESS: 'GET_EQUIPMENT_SUCCESS',
  GET_EQUIPMENT_FAILURE: 'GET_EQUIPMENT_FAILURE',
  UPDATE_EQUIPMENT_REQUEST: 'UPDATE_EQUIPMENT_REQUEST',
  UPDATE_EQUIPMENT_SUCCESS: 'UPDATE_EQUIPMENT_SUCCESS',
  UPDATE_EQUIPMENT_FAILURE: 'UPDATE_EQUIPMENT_FAILURE',
  DELETE_EQUIPMENT_REQUEST: 'DELETE_EQUIPMENT_REQUEST',
  DELETE_EQUIPMENT_SUCCESS: 'DELETE_EQUIPMENT_SUCCESS',
  DELETE_EQUIPMENT_FAILURE: 'DELETE_EQUIPMENT_FAILURE',
  UPLOAD_IMAGE_REQUEST: 'UPLOAD_IMAGE_REQUEST',
  UPLOAD_IMAGE_SUCCESS: 'UPLOAD_IMAGE_SUCCESS',
  UPLOAD_IMAGE_FAILURE: 'UPLOAD_IMAGE_FAILURE',
  FILTER_EQUIPMENT: 'FILTER_EQUIPMENT',
  CLEAR_FILTER: 'CLEARFILTER'
};
