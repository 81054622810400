import { authFetch, headers } from '../_helpers';

export const userManagementService = {
  create,
  listRoles,
  editUser,
  getUsers,
  getSingle,
  disable
};

async function create(newUser) {
  const response = await authFetch('/api/UserManagement/Create', headers.post(newUser));
  return response;
}

async function editUser(updatedUser) {
  const response = await authFetch('/api/UserManagement/EditUser', headers.post(updatedUser));
  return response;
}

async function getUsers() {
  const response = await authFetch('/api/UserManagement/GetUsers', headers.get());
  return response;
}

async function getSingle(userId) {
  const response = await authFetch(`/api/UserManagement/GetSingle`, headers.postPrimitive(userId));
  return response;
}

async function disable(userId) {
  const response = await authFetch(`/api/UserManagement/Disable`, headers.postPrimitive(userId));
  return response;
}

async function listRoles() {
  const response = await authFetch('/api/UserManagement/ListRoles', headers.post());
  return response;
}
