import { authFetch, headers } from '../_helpers';

export const smsService = {
  send,
  sendToGroup,
  locationSubscribers,
  getRecipientNumbers
};

async function send(data) {
  const td = await authFetch(`/api/Sms/Send`, headers.post(data));
  return td;
}

async function sendToGroup(data) {
  const td = await authFetch(`/api/Sms/SendToGroup`, headers.post(data));
  return td;
}

async function getRecipientNumbers() {
  const td = await authFetch(`/api/Sms/Recipients`, headers.get());
  return td;
}

async function locationSubscribers() {
  const td = await authFetch(`/api/Sms/LocationSubscribers`, headers.get());
  return td;
}
