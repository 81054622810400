import { authFetch, handleResponse, headers } from '../_helpers';
export const accountService = {
  confirmEmail,
  forgotPassword,
  resetPassword,
  myAccount,
  updateAccount,
  signUp,
  verifyCode,
  handleSubscribe,
  resendPhoneCode,
  getLocationSubscriptions,
  updatePhoto
};

async function resendPhoneCode() {
  const response = await authFetch(`/api/Account/ResendPhoneCode`, headers.post());
  return response;
}

async function confirmEmail(model) {
  const response = await fetch(`/api/Account/ConfirmEmail`, headers.post(model));
  const data = await handleResponse(response);
  return data;
}

async function forgotPassword(model) {
  const response = await fetch(`/api/Account/ForgotPassword`, headers.postPrimitive(model));
  const data = await handleResponse(response);
  return data;
}

async function resetPassword(model) {
  const response = await fetch(`/api/Account/ResetPassword`, headers.post(model));
  const data = await handleResponse(response);
  return data;
}

async function myAccount() {
  const response = await authFetch(`/api/Account/MyAccount`, headers.get());
  return response;
}

async function updateAccount(user) {
  const response = await authFetch(`/api/Account/UpdateAccount`, headers.post(user));
  return response;
}

async function signUp(data) {
  const td = await fetch(`/api/Account/SignUp`, headers.post(data));
  const response = await handleResponse(td);
  return response;
}

async function verifyCode(data) {
  const code = await authFetch(`/api/Account/ConfirmPhoneNumber`, headers.postPrimitive(data));
  return code;
}

async function handleSubscribe(data) {
  const response = await authFetch(`/api/Account/HandleSubscribe`, headers.postPrimitive(data));
  return response;
}

async function getLocationSubscriptions() {
  const response = await authFetch(`/api/Account/GetLocationSubscriptions`, headers.get());
  return response;
}

async function updatePhoto(file) {
  const response = await authFetch(`/api/Account/UpdatePhoto`, headers.postFile(file));
  return response;
}
