import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const RefereeRoute = ({ component: Component, ...rest }) => {
  const { loggedIn, user, remove = false } = rest;
  if (loggedIn && (user.isAdmin || user.isReferee)) {
    return <Route {...rest} render={props => <Component {...props} remove={remove} />} />;
  } else if (!loggedIn) {
    return (
      <Route
        {...rest}
        render={props => (
          <Redirect
            to={{
              pathname: '/Account/Login',
              state: { from: props.location }
            }}
          />
        )}
      />
    );
  } else {
    return <Redirect to="/Unauthorized" />;
  }
};

function mapStateToProps(state) {
  const { loggedIn, user } = state.auth;
  return { loggedIn, user };
}

const connectedRefereeRoute = connect(mapStateToProps)(RefereeRoute);

export { connectedRefereeRoute as RefereeRoute };
