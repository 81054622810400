import { authFetch, headers } from '../_helpers';

export const locationEquipmentService = {
  getForLocation,
  getSiteEquipment,
  addEquipment,
  removeEquipment,
  updateIndex
};

async function updateIndex(newIndex) {
  const td = await authFetch(`/api/Location/UpdateEquipmentIndex`, headers.postPrimitive(newIndex));
  return td;
}

async function getForLocation(locationId) {
  const td = await authFetch(`/api/Location/GetEquipment`, headers.postPrimitive(locationId));
  return td;
}

async function getSiteEquipment(locationId) {
  const td = await authFetch(`/api/Location/GetSiteEquipment`, headers.postPrimitive(locationId));
  return td;
}

async function addEquipment(addEquip) {
  const td = await authFetch(`/api/Location/AddEquipment`, headers.post(addEquip));
  return td;
}

async function removeEquipment(equipmentId) {
  const td = await authFetch(`/api/Location/RemoveEquipment`, headers.postPrimitive(equipmentId));
  return td;
}
