import React from 'react';
import PropTypes from 'prop-types';
import DateTimePicker from 'react-datetime-picker';

function PwDateTimePicker(props) {
  const { defaultValue, value, handleChange, name } = props;
  const date = value || defaultValue;
  return (
    <div>
      <DateTimePicker
        value={date}
        onChange={value => {
          handleChange({ target: { name, value } });
        }}
        disableClock
        locale="en-AU"
        showLeadingZeros
        amPm
        required
      />
      {props.children}
    </div>
  );
}

PwDateTimePicker.propTypes = {
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  handleChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired
};

export { PwDateTimePicker as DateTimePicker };
