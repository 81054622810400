import { authFetch, handleResponse, headers } from '../_helpers';

export const eventService = {
  create,
  update,
  remove,
  getAll,
  getSingle,
  getUpcoming,
  getPast
};

async function create(newEvent) {
  const response = await authFetch('/api/Event/Create', headers.post(newEvent));
  return response;
}

async function update(event) {
  const response = await authFetch(`/api/Event/Update`, headers.post(event));
  return response;
}

async function remove(eventId) {
  const response = await authFetch(`/api/Event/Delete`, headers.postPrimitive(eventId));
  return response;
}

async function getAll() {
  const response = await fetch(`/api/Event/ListAll`, headers.get());
  const events = await handleResponse(response);
  return events;
}

async function getSingle(eventId) {
  const response = await fetch(`/api/Event/GetSingle`, headers.postPrimitive(eventId));
  const data = await handleResponse(response);
  return data;
}

async function getUpcoming() {
  const response = await fetch(`/api/Event/Upcoming`, headers.get());
  const events = await handleResponse(response);
  return events;
}

async function getPast() {
  const response = await fetch(`/api/Event/Past`, headers.get());
  const events = await handleResponse(response);
  return events;
}
