import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { isEmpty } from 'lodash';
import { Title, PageHeader, LocationSelect } from '../Shared';
import { contentActions, leaderboardActions, locationActions } from '../_actions';
import { OverviewRow } from './';

function Leaderboards(props) {
  const { bySlug, bySectionSlug } = props;
  const slug = 'leaderboards';
  const content = bySlug[slug];
  const { overviews, locations, getLocations, getOverviewLeaderboard } = props;
  const [locationId, setLocationId] = useState(0);

  useEffect(() => {
    getLocations();
    getOverviewLeaderboard();
    bySectionSlug();
  }, [getLocations, getOverviewLeaderboard, bySectionSlug]);

  const handleChange = e => {
    setLocationId(parseFloat(e.target.value));
  };

  const lbData = overviews && locationId !== 0 ? overviews.find(l => l.id === locationId) : [];

  return (
    <Fragment>
      <Title text="Leaderboard" />
      <PageHeader className="default">
        <Row>
          <Col sm={12}>
            {isEmpty(content) ? (
              <Fragment>
                <h2>Leaderboards</h2>
                <p>
                  Do you have what it takes to become the Park Warrior Hero or Champion? Come and
                  challenge yourself and others on our courses. Race against yourself, friends, or
                  the course best times to become the true champion of the course.
                </p>
              </Fragment>
            ) : (
              <Fragment>
                <h2>{content.title}</h2>
                <div dangerouslySetInnerHTML={{ __html: content.copy }} />
              </Fragment>
            )}
          </Col>
        </Row>
      </PageHeader>
      <Container className="mt-4">
        <Row>
          <Col xs={12}>
            <h3 className="display-5"> The Social Leaderboard</h3>
            <p>
              The top 20 ‘unofficial’ times by location recorded by individuals using the Park
              Warrior App stop watch. Times can only be recorded after users have signed up and
              logged in.
            </p>
          </Col>
          <Col xs={12}>
            <h3 className="display-5">The Comp Leaderboard</h3>
            <p>
              The top 20 ‘official’ times by location recorded at official Park Warrior events.
              Events are overseen by official referees who record times and ensure the rules are
              followed. All are welcome to compete. Find details of future events{' '}
              <Link to="/Events">here</Link>.
            </p>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col>
            {locations && <LocationSelect handleChange={handleChange} allLocations={locations} />}
          </Col>
        </Row>
      </Container>
      <Container fluid className="mt-2 leaderboard-container">
        {!isEmpty(lbData) && (
          <OverviewRow
            official={lbData.official}
            unofficial={lbData.unofficial}
            locationName={lbData.name}
          />
        )}
      </Container>
    </Fragment>
  );
}

function mapStateToProps(state) {
  const { bySlug } = state.content;
  const { overviews } = state.leaderboard;
  const { locations } = state.location;
  return { bySlug, overviews, locations };
}

const mapDispatchToProps = {
  bySectionSlug: contentActions.bySectionSlug,
  getLocations: locationActions.getAll,
  getOverviewLeaderboard: leaderboardActions.getOverviewLeaderboard
};

const connectedLeaderboards = connect(
  mapStateToProps,
  mapDispatchToProps
)(Leaderboards);

export { connectedLeaderboards as Leaderboards };
