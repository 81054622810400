import React from 'react';
import PropTypes from 'prop-types';
import { NavItem, NavLink } from 'reactstrap';
import { NavLink as RRNavLink } from 'react-router-dom';

export function AdminNavItems({ navigated }) {
  return (
    <NavItem>
      <NavLink tag={RRNavLink} to="/Admin" onClick={navigated}>
        Admin
      </NavLink>
    </NavItem>
  );
}
AdminNavItems.propTypes = {
  navigated: PropTypes.func.isRequired
};
