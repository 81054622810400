import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { FormWrapper } from './';
import { accountActions } from '../_actions';

function ForgotPassword(props) {
  const { requesting, message, error } = props;
  const [email, setEmail] = useState('');

  const handleSubmit = e => {
    e.preventDefault();
    props.forgotPassword(email);
  };

  return (
    <FormWrapper title="Forgot Password">
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="email">Email</Label>
          <Input
            name="email"
            id="email"
            type="email"
            onChange={e => setEmail(e.target.value)}
            invalid={error && error.length}
            valid={message && message.length}
          />
          {message && <div className="valid-feedback">{message}</div>}
          {error && <div className="invalid-feedback">{error}</div>}
        </FormGroup>
        {requesting ? (
          <Button block color="primary" outline>
            Requesting
          </Button>
        ) : (
          <Button block color="primary">
            Submit
          </Button>
        )}
      </Form>
    </FormWrapper>
  );
}

function mapState(state) {
  const { requesting, message, error } = state.account;
  return { requesting, message, error };
}
const mapDispatch = { forgotPassword: accountActions.forgotPassword };

const connectedForgotPassword = connect(
  mapState,
  mapDispatch
)(ForgotPassword);
export { connectedForgotPassword as ForgotPassword };
