export const eventConstants = {
  CREATE_EVENT_REQUEST: 'CREATE_EVENT_REQUEST',
  CREATE_EVENT_SUCCESS: 'CREATE_EVENT_SUCCESS',
  CREATE_EVENT_FAILURE: 'CREATE_EVENT_FAILURE',
  UPDATE_EVENT_REQUEST: 'UPDATE_EVENT_REQUEST',
  UPDATE_EVENT_SUCCESS: 'UPDATE_EVENT_SUCCESS',
  UPDATE_EVENT_FAILURE: 'UPDATE_EVENT_FAILURE',
  GET_EVENT_REQUEST: 'GET_EVENT_REQUEST',
  GET_EVENT_SUCCESS: 'GET_EVENT_SUCCESS',
  GET_EVENT_FAILURE: 'GET_EVENT_FAILURE',
  GET_UPCOMING_EVENTS_REQUEST: 'GET_UPCOMING_EVENTS_REQUEST',
  GET_UPCOMING_EVENTS_SUCCESS: 'GET_UPCOMING_EVENTS_SUCCESS',
  GET_UPCOMING_EVENTS_FAILURE: 'GET_UPCOMING_EVENTS_FAILURE',
  GET_PAST_EVENTS_REQUEST: 'GET_PAST_EVENTS_REQUEST',
  GET_PAST_EVENTS_SUCCESS: 'GET_PAST_EVENTS_SUCCESS',
  GET_PAST_EVENTS_FAILURE: 'GET_PAST_EVENTS_FAILURE',
  LIST_EVENT_REQUEST: 'LIST_EVENT_REQUEST',
  LIST_EVENT_SUCCESS: 'LIST_EVENT_SUCCESS',
  LIST_EVENT_FAILURE: 'LIST_EVENT_FAILURE',
  DELETE_EVENT_REQUEST: 'DELETE_EVENT_REQUEST',
  DELETE_EVENT_SUCCESS: 'DELETE_EVENT_SUCCESS',
  DELETE_EVENT_FAILURE: 'DELETE_EVENT_FAILURE',
  FILTER_EVENTS: 'FILTER_EVENTS',
  CLEAR_EVENT_FILTER: 'CLEAR_FILTER'
};
