import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function Footer() {
  return (
    <div className="footer">
      <Container>
        <Row className="footer-links text-center">
          <Col>
            <Link to="/PrivacyPolicy">Privacy Policy</Link>
          </Col>
          <Col>
            <Link to="/Feedback">Feedback</Link>
          </Col>
          <Col>
            <FontAwesomeIcon size="2x" icon={['fab', 'facebook-square']} />
          </Col>

          <Col>
            <FontAwesomeIcon size="2x" icon={['fab', 'instagram']} />
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="copyright">© 2019 a_space </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
