import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label } from 'reactstrap';
import Geosuggest from 'react-geosuggest';

export class LocationFinder extends React.Component {
  clearInput = () => {
    this._geoSuggest.clear();
  };

  handleLocationSelect = data => {
    try {
      const componentForm = {
        street_number: 'long_name',
        route: 'long_name',
        locality: 'long_name',
        administrative_area_level_1: 'long_name',
        country: 'long_name',
        postal_code: 'long_name'
      };
      const stateLabel = {
        street_number: 'streetNumber',
        route: 'streetName',
        locality: 'city',
        administrative_area_level_1: 'state',
        country: 'country',
        postal_code: 'postCode'
      };

      const addressLength = data.gmaps.address_components.length;
      const addressData = data.gmaps.address_components;

      const gMapsUrl = data.gmaps.url;
      const placeId = data.placeId;
      const { lat, lng } = data.location;

      if (gMapsUrl) {
        this.props.handleChange({ target: { name: 'gMapsUrl', value: gMapsUrl } });
      }

      if (placeId) {
        this.props.handleChange({ target: { name: 'placeId', value: placeId } });
      }

      if (lat && lng) {
        this.props.handleChange({ target: { name: 'lat', value: lat } });
        this.props.handleChange({ target: { name: 'lng', value: lng } });
      }

      for (let i = 0; i < addressLength; i++) {
        let addressType = addressData[i].types[0];
        let addressComponent = stateLabel[addressType];
        if (componentForm[addressType]) {
          let addressValue = data.gmaps.address_components[i][componentForm[addressType]];
          this.props.handleChange({ target: { name: addressComponent, value: addressValue } });
        }
      }
    } catch (e) {
      // fail silently
    }
  };

  render() {
    return (
      <FormGroup>
        <Label for="location">Location</Label>
        <Geosuggest
          ref={el => (this._geoSuggest = el)}
          minLength={3}
          placeholder={this.props.location || ''}
          id="location"
          name="location"
          onSuggestSelect={this.handleLocationSelect}
        />
      </FormGroup>
    );
  }
}
LocationFinder.propTypes = {
  location: PropTypes.string,
  handleChange: PropTypes.func.isRequired
};
