import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Button } from 'reactstrap';
import { LocationCard } from './LocationCard';
import { Title, LoadingCard, LookupFilter } from '../../Shared';
import { locationActions } from '../../_actions';
import { AdminHeader, ViewEditFooter, RemoveFooter, RemoveModal } from '../';
import { debounce } from 'lodash';

function LocationList(props) {
  const { getAll, locations, loading, remove, disable, filtered, filtering } = props;
  const [modal, setModal] = useState(false);
  const [active, setActive] = useState(false);
  const [disableId, setDisableId] = useState(null);

  useEffect(() => {
    getAll();
  }, [getAll]);

  const toggleModal = () => {
    setModal(!modal);
  };

  const handleRemove = id => {
    setDisableId(id);
    toggleModal();
  };

  const handleDisable = id => {
    toggleModal();
    disable(id);
  };

  const delayedFilter = debounce(e => {
    props.filter(e.target.value);
  }, 350);

  const handleChange = e => {
    e.persist();
    delayedFilter(e);
  };

  const location = locations && locations.find(l => l.id === disableId);
  return (
    <Fragment>
      <Title text="All Locations" />
      <Container>
        <AdminHeader goBack title="Manage Locations" />
        <LookupFilter handleChange={handleChange} />
        <Row style={{ marginBottom: '20px', marginTop: '20px' }}>
          <Col>
            <Button color={!active ? 'secondary' : 'primary'} onClick={() => setActive(true)}>
              Disabled
            </Button>
            <Button color={active ? 'secondary' : 'primary'} onClick={() => setActive(false)}>
              Active
            </Button>
          </Col>
        </Row>
        <Row>
          {loading && <LoadingCard />}
          {!loading &&
            filtering &&
            filtered
              .filter(c => c.disabled === active)
              .map((loc, idx) => (
                <LocationCard key={idx} location={loc}>
                  {remove ? (
                    <RemoveFooter id={loc.id} handleRemove={handleRemove} />
                  ) : (
                    <ViewEditFooter
                      id={loc.id}
                      path={loc.path}
                      urlPath="Locations"
                      showTimes="true"
                    />
                  )}
                </LocationCard>
              ))}
          {!loading &&
            !filtering &&
            locations &&
            locations
              .filter(c => c.disabled === active)
              .map((loc, idx) => (
                <LocationCard key={idx} location={loc}>
                  {remove ? (
                    <RemoveFooter id={loc.id} handleRemove={handleRemove} />
                  ) : (
                    <ViewEditFooter
                      id={loc.id}
                      path={loc.path}
                      urlPath="Locations"
                      showTimes="true"
                    />
                  )}
                </LocationCard>
              ))}
        </Row>
      </Container>
      {location && (
        <RemoveModal
          item={location}
          modal={modal}
          toggle={toggleModal}
          handleDelete={handleDisable}
          title="Disable Location"
        />
      )}
    </Fragment>
  );
}
const mapDispatchToProps = {
  getAll: locationActions.getAll,
  disable: locationActions.disable,
  filter: locationActions.filter,
  removeFilter: locationActions.removeFilter
};
function mapStateToProps(state) {
  const { locations, filtered, filtering = false, loading = true } = state.location;
  return { locations, filtered, filtering, loading };
}

const connectedLocationList = connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationList);
export { connectedLocationList as LocationList };
