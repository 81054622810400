import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col, Form, FormGroup, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoginUserInput, PasswordInput } from './';
import { authActions } from '../_actions';
import validate from '../_hooks/validate';

function InlineLogin(props) {
  const { loggingIn = false, error, message } = props;
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = e => {
    e.preventDefault();
    if (user && password) {
      props.loginNoRedirect(user, password);
    }
  };
  // yes this is unused but without it the password input is grumpy
  // eslint-disable-next-line
  const validatePassword = () => {};
  return (
    <Fragment>
      {error && <p>{message}</p>}
      <Form onSubmit={handleSubmit}>
        <LoginUserInput handleChange={setUser} user={user} />
        <PasswordInput
          handleChange={setPassword}
          password={password}
          validation={null}
          validatePassword={validate}
        />
        <FormGroup row>
          <Col>
            {loggingIn ? (
              <Button block color="info">
                <FontAwesomeIcon icon="spinner" spin /> Logging In
              </Button>
            ) : (
              <Button block color="primary">
                Login
              </Button>
            )}
          </Col>
        </FormGroup>
      </Form>
      <Row>
        <Col>
          <small clasNames="form-text text-muted">
            <Link to="/Account/ForgotPassword">Forgot your password?</Link>
          </small>
        </Col>
        <Col className="text-right">
          <small>
            <Link to="/Account/SignUp">Sign Up</Link>
          </small>
        </Col>
      </Row>
    </Fragment>
  );
}
const mapDispatch = {
  logoutNoRedirect: authActions.logoutNoRedirect,
  loginNoRedirect: authActions.loginNoRedirect
};

function mapStateToProps(state) {
  const { loggingIn, error, message } = state.auth;
  return { loggingIn, error, message };
}

const connectedInlineLogin = connect(
  mapStateToProps,
  mapDispatch
)(InlineLogin);
export { connectedInlineLogin as InlineLogin };
