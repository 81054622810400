import React, { Fragment } from 'react';
import { date as dh } from '../_helpers';
import { Thead, ShadowScrollbars } from '../Shared';
import { isEmpty } from 'lodash';

export function UserLeaderboard(props) {
  const { data, title, className } = props;
  return (
    <div className={`leaderboard ${className}`}>
      <div className="title">{title}</div>
      <div className="results">
        {!isEmpty(data) ? (
          <Fragment>
            <table className="table table-sm">
              <Thead headerItems={['Time', 'Location', 'Date']} />
            </table>
            <ShadowScrollbars>
              <table className="table table-sm">
                <tbody>
                  {data.map((res, idx) => (
                    <tr key={idx}>
                      <td>{res.tsDuration}</td>
                      <td>{res.location.name}</td>
                      <td>{dh.getShortDate(new Date(res.timeStarted))}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </ShadowScrollbars>
          </Fragment>
        ) : (
          <div className="no-times">No {title} times recorded</div>
        )}
      </div>
    </div>
  );
}
