export const locationConstants = {
  CREATE_LOCATION_REQUEST: 'CREATE_LOCATION_REQUEST',
  CREATE_LOCATION_SUCCESS: 'CREATE_LOCATION_SUCCESS',
  CREATE_LOCATION_FAILURE: 'CREATE_LOCATION_FAILURE',
  LIST_LOCATION_REQUEST: 'LIST_LOCATION_REQUEST',
  LIST_LOCATION_SUCCESS: 'LIST_LOCATION_SUCCESS',
  LIST_LOCATION_FAILURE: 'LIST_LOCATION_FAILURE',
  LIST_STATE_REQUEST: 'LIST_STATE_REQUEST',
  LIST_STATE_SUCCESS: 'LIST_STATE_SUCCESS',
  LIST_STATE_FAILURE: 'LIST_STATE_FAILURE',
  GET_LOCATION_REQUEST: 'GET_LOCATION_REQUEST',
  GET_LOCATION_SUCCESS: 'GET_LOCATION_SUCCESS',
  GET_LOCATION_FAILURE: 'GET_LOCATION_FAILURE',
  GET_LOCATIONS_AND_STATES_REQUEST: 'GET_LOCATIONS_AND_STATES_REQUEST',
  GET_LOCATIONS_AND_STATES_SUCCESS: 'GET_LOCATIONS_AND_STATES_SUCCESS',
  GET_LOCATIONS_AND_STATES_FAILURE: 'GET_LOCATIONS_AND_STATES_FAILURE',
  LIST_LOCATION_TIMES_REQUEST: 'LIST_LOCATION_TIMES_REQUEST',
  LIST_LOCATION_TIMES_SUCCESS: 'LIST_LOCATION_TIMES_SUCCESS',
  LIST_LOCATION_TIMES_FAILURE: 'LIST_LOCATION_TIMES_FAILURE',
  UPDATE_LOCATION_REQUEST: 'UPDATE_LOCATION_REQUEST',
  UPDATE_LOCATION_SUCCESS: 'UPDATE_LOCATION_SUCCESS',
  UPDATE_LOCATION_FAILURE: 'UPDATE_LOCATION_FAILURE',
  DISABLE_LOCATION_REQUEST: 'DISABLE_LOCATION_REQUEST',
  DISABLE_LOCATION_SUCCESS: 'DISABLE_LOCATION_SUCCESS',
  DISABLE_LOCATION_FAILURE: 'DISABLE_LOCATION_FAILURE',
  DELETE_TIME_REQUEST: 'DELETE_TIME_REQUEST',
  DELETE_TIME_SUCCESS: 'DELETE_TIME_SUCCESS',
  DELETE_TIME_FAILURE: 'DELETE_TIME_FAILURE',
  FILTER_LOCATIONS: 'FILTER_LOCATIONS',
  CLEAR_FILTER: 'CLEAR_FILTER',
  LOCATIONS_BY_DISTANCE_REQUEST: 'LOCATIONS_BY_DISTANCE_REQUEST',
  LOCATIONS_BY_DISTANCE_SUCCESS: 'LOCATIONS_BY_DISTANCE_SUCCESS',
  LOCATIONS_BY_DISTANCE_FAILURE: 'LOCATIONS_BY_DISTANCE_FAILURE'
};
