import { accountConstants } from '../_constants';
import { accountService } from '../_services';
import { toastr } from 'react-redux-toastr';
import { history } from '../_helpers';

export const accountActions = {
  confirmEmail,
  forgotPassword,
  resetPassword,
  myAccount,
  updateAccount,
  signUp,
  verifyCode,
  inlineSignUp,
  subscribe,
  unsubscribe,
  resendPhoneCode,
  getLocationSubscriptions,
  updatePhoto
};

function myAccount() {
  return dispatch => {
    dispatch(request());
    accountService.myAccount().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: accountConstants.MY_ACCOUNT_REQUEST, data };
  }
  function success(data) {
    return { type: accountConstants.MY_ACCOUNT_SUCCESS, data };
  }
  function failure(error) {
    return { type: accountConstants.MY_ACCOUNT_FAILURE, error };
  }
}

function updateAccount(user) {
  return dispatch => {
    dispatch(request());
    accountService.updateAccount(user).then(
      data => {
        dispatch(success(data));
        toastr.success('Account Updated!');
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: accountConstants.UPDATE_MY_ACCOUNT_REQUEST, data };
  }
  function success(data) {
    return { type: accountConstants.UPDATE_MY_ACCOUNT_SUCCESS, data };
  }
  function failure(error) {
    return { type: accountConstants.UPDATE_MY_ACCOUNT_FAILURE, error };
  }
}

function confirmEmail(model) {
  return dispatch => {
    dispatch(request(model));
    accountService.confirmEmail(model).then(
      data => {
        dispatch(success(data));
        toastr.success('Thankyou, your email has been confirmed');
        history.push('/Account/Login');
      },
      error => {
        dispatch(failure(error));
        toastr.error('A problem occured and your email has not been confirmed.');
      }
    );
  };
  function request(data) {
    return { type: accountConstants.CONFIRM_EMAIL_REQUEST, data };
  }
  function success(data) {
    return { type: accountConstants.CONFIRM_EMAIL_SUCCESS, data };
  }
  function failure(error) {
    return { type: accountConstants.CONFIRM_EMAIL_FAILURE, error };
  }
}

function forgotPassword(model) {
  return dispatch => {
    dispatch(request(model));
    accountService.forgotPassword(model).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: accountConstants.FORGOT_PASSWORD_REQUEST, data };
  }
  function success(data) {
    return { type: accountConstants.FORGOT_PASSWORD_SUCCESS, data };
  }
  function failure(error) {
    return { type: accountConstants.FORGOT_PASSWORD_FAILURE, error };
  }
}

function resetPassword(model) {
  return dispatch => {
    dispatch(request(model));
    accountService.resetPassword(model).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: accountConstants.RESET_PASSWORD_REQUEST, data };
  }
  function success(data) {
    return { type: accountConstants.RESET_PASSWORD_SUCCESS, data };
  }
  function failure(error) {
    return { type: accountConstants.RESET_PASSWORD_FAILURE, error };
  }
}

function signUp(data) {
  return dispatch => {
    dispatch(request(data));
    accountService.signUp(data).then(
      data => {
        dispatch(success(data));
        toastr.success(
          'You are now signed up, please check your email and phone number to valdiate.'
        );
        history.push('/Account/Login');
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: accountConstants.SIGN_UP_REQUEST, data };
  }
  function success(data) {
    return { type: accountConstants.SIGN_UP_SUCCESS, data };
  }
  function failure(error) {
    return { type: accountConstants.SIGN_UP_FAILURE, error };
  }
}

function verifyCode(data) {
  return dispatch => {
    dispatch(request(data));
    accountService.verifyCode(data).then(
      data => {
        dispatch(success(data));
        toastr.success('Your phone number has been verified.');
      },
      error => {
        dispatch(failure(error));
        toastr.error('Unable to verify the phone number, Please try again.');
      }
    );
  };
  function request(data) {
    return { type: accountConstants.VALIDATE_PHONENUMBER_REQUEST, data };
  }
  function success(data) {
    return { type: accountConstants.VALIDATE_PHONENUMBER_SUCCESS, data };
  }
  function failure(error) {
    return { type: accountConstants.VALIDATE_PHONENUMBER_FAILURE, error };
  }
}

function inlineSignUp(data) {
  return dispatch => {
    dispatch(request(data));
    accountService.signUp(data).then(
      data => {
        dispatch(success(data));
        toastr.success('Congratulations, your account has been created, you may now login.');
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: accountConstants.SIGN_UP_INLINE_REQUEST, data };
  }
  function success(data) {
    return { type: accountConstants.SIGN_UP_INLINE_SUCCESS, data };
  }
  function failure(error) {
    return { type: accountConstants.SIGN_UP_INLINE_FAILURE, error };
  }
}

function subscribe(locationId) {
  return dispatch => {
    dispatch(request(locationId));
    accountService.handleSubscribe(locationId).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: accountConstants.SUBSCRIBE_TO_EVENT_REQUEST, data };
  }
  function success(data) {
    return { type: accountConstants.SUBSCRIBE_TO_EVENT_SUCCESS, data };
  }
  function failure(error) {
    return { type: accountConstants.SUBSCRIBE_TO_EVENT_FAILURE, error };
  }
}

function unsubscribe(locationId) {
  return dispatch => {
    dispatch(request(locationId));
    accountService.handleSubscribe(locationId).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: accountConstants.LOCATION_UNSUBSCRIBE_REQUEST, data };
  }
  function success(data) {
    return { type: accountConstants.LOCATION_UNSUBSCRIBE_SUCCESS, data };
  }
  function failure(error) {
    return { type: accountConstants.LOCATION_UNSUBSCRIBE_FAILURE, error };
  }
}

function getLocationSubscriptions() {
  return dispatch => {
    dispatch(request());
    accountService.getLocationSubscriptions().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: accountConstants.GET_LOCATION_SUBSCRIPTIONS_REQUEST };
  }
  function success(data) {
    return { type: accountConstants.GET_LOCATION_SUBSCRIPTIONS_SUCCESS, data };
  }
  function failure(error) {
    return { type: accountConstants.GET_LOCATION_SUBSCRIPTIONS_FAILURE, error };
  }
}

function resendPhoneCode() {
  return dispatch => {
    dispatch(request());
    accountService.resendPhoneCode().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: accountConstants.RESEND_PHONE_CODE_REQUEST };
  }
  function success(data) {
    return { type: accountConstants.RESEND_PHONE_CODE_SUCCESS, data };
  }
  function failure(error) {
    return { type: accountConstants.RESEND_PHONE_CODE_FAILURE, error };
  }
}

function updatePhoto(file) {
  return dispatch => {
    dispatch(request());
    accountService.updatePhoto(file).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: accountConstants.UPLOAD_PHOTO_REQUEST };
  }
  function success(data) {
    return { type: accountConstants.UPLOAD_PHOTO_SUCCESS, data };
  }
  function failure(error) {
    return { type: accountConstants.UPLOAD_PHOTO_FAILURE, error };
  }
}
