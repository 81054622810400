const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const date = {
  getTime,
  dateWithMonthName,
  dateTime,
  getShortDate,
  toApi,
  event
};

function toApi(d) {
  return `${d.getFullYear()}-${zeroPad(d.getMonth() + 1)}-${d.getDate()}`;
}
function zeroPad(num) {
  return num <= 9 ? `0${num}` : num;
}

function getTime(d = new Date()) {
  return `${zeroPad(d.getHours())}:${zeroPad(d.getMinutes())}:${d.getSeconds()}`;
}

function dateWithMonthName(date) {
  const d = new Date(date);
  return `${d.getDate()}/${months[d.getMonth()]}/${d.getFullYear()}`;
}

function dateTime(date) {
  return `${getShortDate(date)} ${getTime(date)}`;
}

function getShortDate(date) {
  const d = new Date(date);
  return `${d.getDate()}/${zeroPad(d.getMonth() + 1)}/${d.getFullYear()}`;
}

function event(date) {
  const d = new Date(date);
  const theDate = `${d.getDate()}/${zeroPad(d.getMonth() + 1)}/${d.getFullYear()}`;
  const theTime = timeAmPm(d);
  return `${theDate} ${theTime}`;
}

function timeAmPm(date) {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const amPm = hours >= 12 ? 'pm' : 'am';
  return `${to12Hours(hours)}:${zeroPad(minutes)}${amPm}`;
}

function to12Hours(hours) {
  hours = hours % 12;
  hours = hours ? hours : 12;
  return hours;
}
