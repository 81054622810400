import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import ReactPlayer from 'react-player';

const pnbStyle = {
  color: `#009ad9`,
  padding: `8px`,
  marginBottom: `138px`
};

export function FullscreenModal(props) {
  const { isOpen, toggle, equipmentId, equipment = [], prev, next } = props;
  const display = equipment.find(e => e.id === equipmentId);
  return (
    <Modal isOpen={isOpen} toggle={toggle} className="fullscreen-modal">
      <ModalHeader toggle={toggle}>{display && display.name}</ModalHeader>
      <ModalBody>
        {display && display.videoUrl && (
          <div className="player-wrapper">
            <ReactPlayer
              className="react-player"
              url={display.videoUrl}
              width="100%"
              height="100%"
            />
          </div>
        )}
        {display && <div dangerouslySetInnerHTML={{ __html: display.instructions }} />}
      </ModalBody>
      <div className="d-flex justify-content-between">
        <div style={pnbStyle} onClick={prev}>
          « Prev
        </div>
        <div style={pnbStyle} onClick={next}>
          Next »
        </div>
      </div>
    </Modal>
  );
}
