import { eventConstants } from '../_constants';
import { eventService } from '../_services';
import { toastr } from 'react-redux-toastr';
import { history } from '../_helpers';

export const eventActions = {
  create,
  update,
  remove,
  getAll,
  getSingle,
  getUpcoming,
  getPast,
  filter,
  removeFilter
};

function create(newEvent) {
  return dispatch => {
    dispatch(request(newEvent));
    eventService.create(newEvent).then(
      data => {
        dispatch(success(data));
        toastr.success('Success', `Created ${data.name}`);
        history.push(`/Admin/Event/Edit/${data.path}`);
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: eventConstants.CREATE_EVENT_REQUEST, data };
  }
  function success(data) {
    return { type: eventConstants.CREATE_EVENT_SUCCESS, data };
  }
  function failure(error) {
    return { type: eventConstants.CREATE_EVENT_FAILURE, error };
  }
}

function update(event) {
  return dispatch => {
    dispatch(request(event));
    eventService.update(event).then(
      data => {
        dispatch(success(data));
        toastr.success('Updated', `Successfully updated ${data.name}`);
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: eventConstants.UPDATE_EVENT_REQUEST, data };
  }
  function success(data) {
    return { type: eventConstants.UPDATE_EVENT_SUCCESS, data };
  }
  function failure(error) {
    return { type: eventConstants.UPDATE_EVENT_FAILURE, error };
  }
}

function remove(eventId) {
  return dispatch => {
    dispatch(request(eventId));
    eventService.remove(eventId).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: eventConstants.DELETE_EVENT_REQUEST, data };
  }
  function success(data) {
    return { type: eventConstants.DELETE_EVENT_SUCCESS, data };
  }
  function failure(error) {
    return { type: eventConstants.DELETE_EVENT_FAILURE, error };
  }
}

function getAll() {
  return dispatch => {
    dispatch(request());
    eventService.getAll().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: eventConstants.LIST_EVENT_REQUEST };
  }
  function success(data) {
    return { type: eventConstants.LIST_EVENT_SUCCESS, data };
  }
  function failure(error) {
    return { type: eventConstants.LIST_EVENT_FAILURE, error };
  }
}

function getSingle(path) {
  return dispatch => {
    dispatch(request(path));
    eventService.getSingle(path).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: eventConstants.GET_EVENT_REQUEST, data };
  }
  function success(data) {
    return { type: eventConstants.GET_EVENT_SUCCESS, data };
  }
  function failure(error) {
    return { type: eventConstants.GET_EVENT_FAILURE, error };
  }
}

function getUpcoming() {
  return dispatch => {
    dispatch(request());
    eventService.getUpcoming().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: eventConstants.GET_UPCOMING_EVENTS_REQUEST, data };
  }
  function success(data) {
    return { type: eventConstants.GET_UPCOMING_EVENTS_SUCCESS, data };
  }
  function failure(error) {
    return { type: eventConstants.GET_UPCOMING_EVENTS_FAILURE, error };
  }
}

function getPast() {
  return dispatch => {
    dispatch(request());
    eventService.getPast().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: eventConstants.GET_PAST_EVENTS_REQUEST, data };
  }
  function success(data) {
    return { type: eventConstants.GET_PAST_EVENTS_SUCCESS, data };
  }
  function failure(error) {
    return { type: eventConstants.GET_PAST_EVENTS_FAILURE, error };
  }
}

function filter(data) {
  return dispatch => {
    dispatch(filter(data));
  };
  function filter(data) {
    return { type: eventConstants.FILTER_EVENTS, data };
  }
}

function removeFilter() {
  return dispatch => {
    dispatch(clear());
  };
  function clear() {
    return { type: eventConstants.CLEAR_FILTER };
  }
}
