import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Input, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export function RemoveModal(props) {
  const { toggle, modal, title, item = {} } = props;
  const [canDelete, checkInput] = useState(false);
  return (
    <Modal isOpen={modal} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>
        {title} {item.name}
      </ModalHeader>
      <ModalBody>
        Are you sure you want to delete {item.name || item.userName}?
        <FormGroup>
          <Input
            id="name"
            name="name"
            type="text"
            placeholder="Enter name to delete.."
            onChange={e => checkInput(e.target.value === (item.name || item.userName))}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button
          name="remove"
          color="primary"
          onClick={() => props.handleDelete(item.id)}
          disabled={!canDelete}
        >
          Remove
        </Button>{' '}
        <Button name="Cancel" color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}
RemoveModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  modal: PropTypes.bool.isRequired,
  title: PropTypes.string,
  item: PropTypes.object
};
