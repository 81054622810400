import React from 'react';
import PropTypes from 'prop-types';

export function Thead(props) {
  const { headerItems } = props;
  return (
    <thead>
      <tr>
        {headerItems.map((h, idx) => (
          <th key={idx}>{h}</th>
        ))}
      </tr>
    </thead>
  );
}
Thead.propTypes = {
  headerItems: PropTypes.array.isRequired
};
