import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Input, FormGroup, Button, CustomInput } from 'reactstrap';
import isEmpty from 'lodash/isEmpty';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Title } from '../../Shared';
import { smsActions, locationActions } from '../../_actions';

function Send(props) {
  const { count, getCount, getLocations, locSubs, byLocation, sending, sent } = props;
  const [content, setContent] = useState('');
  const [receivers, setReceivers] = useState([]);

  useEffect(() => {
    getCount();
    getLocations();
    locSubs();
  }, [getCount, getLocations, locSubs]);

  // reset form on send.
  useEffect(() => {
    if (sent) {
      setContent('');
      setReceivers([]);
    }
  }, [sent]);

  const send = e => {
    e.preventDefault();
    if (content && receivers.length) {
      props.sendToGroup({ content, locationIds: receivers });
    }
  };

  const handleChange = id => {
    if (receivers.includes(id)) {
      const idx = receivers.indexOf(id);
      receivers.splice(idx, 1);
      setReceivers([...receivers]);
    } else {
      receivers.push(id);
      setReceivers([...receivers]);
    }
  };

  return (
    <Fragment>
      <Title text="Send SMS" />
      <Container className="mt-4">
        <Row>
          <Col>
            <h5>Select Recipients</h5>
            {!isEmpty(count) && (
              <CustomInput
                type="checkbox"
                label={`Everyone - ${count.confirmed} Confirmed, ${count.unconfirmed} Unconfirmed`}
                id="0"
                value={0}
                onChange={() => handleChange(0)}
                checked={receivers.includes(0)}
              />
            )}

            {byLocation &&
              byLocation.map((l, idx) => {
                return (
                  <CustomInput
                    type="checkbox"
                    id={l.location.id}
                    label={`${l.location.name} - ${l.confirmed} Confirmed, ${
                      l.unconfirmed
                    } Unconfirmed`}
                    key={idx}
                    onChange={() => handleChange(l.location.id)}
                    checked={receivers.includes(l.location.id)}
                  />
                );
              })}
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Input type="textarea" value={content} onChange={e => setContent(e.target.value)} />
              <span className="float-right small">{content.length} / 160</span>
            </FormGroup>
            {sending ? (
              <Button color="primary" disabled>
                <FontAwesomeIcon icon="spinner" spin /> Sending
              </Button>
            ) : (
              <Button color="primary" onClick={send}>
                Send
              </Button>
            )}
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

const mapDispatch = {
  getCount: smsActions.getRecipientNumbers,
  locSubs: smsActions.locationSubscribers,
  sendToGroup: smsActions.sendToGroup,
  getLocations: locationActions.getAll
};

function mapState(state) {
  const { count, byLocation, sending, sent } = state.sms;
  const { locations } = state.location;
  return { count, byLocation, locations, sending, sent };
}

const connectedSend = connect(
  mapState,
  mapDispatch
)(Send);
export { connectedSend as Send };
