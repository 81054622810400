import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

export function Title(props) {
  return <Helmet title={`${props.text} - Park Warrior`} />;
}

Title.propTypes = {
  text: PropTypes.string
};
