import React from 'react';
import { Col } from 'reactstrap';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import isEmpty from 'lodash/isEmpty';
import { DragHandle } from './';

export function EquipmentOnSite(props) {
  const { atSite, getListStyle, getItemStyle } = props;
  return (
    <Col>
      <h4>On Site</h4>
      <Droppable droppableId="onSite">
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
            className="drag-list"
          >
            {isEmpty(atSite) && <div>No Equipment</div>}
            {atSite &&
              atSite.map((item, index) => (
                <Draggable key={index} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      className="drag-item"
                    >
                      <DragHandle {...provided.dragHandleProps} />
                      {item.equipment.name}
                    </div>
                  )}
                </Draggable>
              ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </Col>
  );
}
