import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Col, Card, CardBody } from 'reactstrap';

export function PanelCard(props) {
  return (
    <Col sm={4} className="mb-4 flippable panel-card">
      <Card tag={Link} to={props.url} className="shadow h-100 pb-2">
        <CardBody className="text-center">
          <FontAwesomeIcon size="3x" icon={props.icon} />
          {props.children}
        </CardBody>
      </Card>
    </Col>
  );
}

PanelCard.propTypes = {
  url: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired
};
