import React, { Fragment } from 'react';
import { Container } from 'reactstrap';
import { Title } from '../Shared';
import { EventPanels } from './';
export function Event() {
  return (
    <Fragment>
      <Title text="Event Manager" />
      <Container>
        <EventPanels />
      </Container>
    </Fragment>
  );
}
