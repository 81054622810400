import { smsConstants } from '../_constants';

export function sms(state = {}, action) {
  switch (action.type) {
    case smsConstants.GET_RECIPIENT_NUMBERS_SUCCESS:
      return {
        ...state,
        count: action.data
      };
    case smsConstants.GET_LOCATION_SUBS_SUCCESS:
      return {
        ...state,
        byLocation: action.data
      };
    case smsConstants.SEND_GROUP_SMS_REQUEST:
      return {
        ...state,
        sending: true,
        sent: false
      };
    case smsConstants.SEND_GROUP_SMS_SUCCESS:
      return {
        ...state,
        sending: false,
        sent: true
      };
    case smsConstants.SEND_GROUP_SMS_FAILURE:
      return {
        ...state,
        sending: false,
        error: true
      };
    case smsConstants.GET_LOCATION_SUBS_REQUEST:
    case smsConstants.GET_LOCATION_SUBS_FAILURE:
    case smsConstants.GET_RECIPIENT_NUMBERS_REQUEST:
    case smsConstants.GET_RECIPIENT_NUMBERS_FAILURE:
    case smsConstants.SEND_SMS_REQUEST:
    case smsConstants.SEND_SMS_SUCCESS:
    case smsConstants.SEND_SMS_FAILURE:
      return {
        ...state
      };
    default:
      return state;
  }
}
