import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { LoadingCard, Thead } from '../../Shared';
import { locationActions } from '../../_actions';
import { AdminHeader } from '../';
import { LocationTimeRow } from './';
import { isEmpty } from 'lodash';

function LocationTime(props) {
  const { single, loading, getSingle, getTimes, deleteTime, times } = props;

  useEffect(() => {
    const { path } = props.match.params;
    if (path) {
      getSingle(path);
      getTimes(path);
    }
  }, [getSingle, getTimes, props.match.params]);

  const handleDelete = (timeId) => {
    deleteTime(timeId);
  }

  return (
    <Fragment>
      <Row>
        {loading && <LoadingCard />}
        {!loading && single && (
          <Container>
            <AdminHeader goBack title="Manage Locations" />
            <Row>
              <Col>
                <h1>{single.name}</h1>
              </Col>
            </Row>
            <Row>
              You can delete the recorded times for {single.name} here. Once deleted they will no
              longer be accessible.
            </Row>
            <Row>
              some sort of filter here maybe for user name so they can be checked and/or filter for
              registered vs unregistered results.
            </Row>
            <Container fluid className="leaderboard-container">
              <table className="table table-sm">
                <Thead headerItems={['User Name', 'Time', 'Date', 'Type', 'Delete']} />
                <tbody>
                  {loading && <LoadingCard />}
                  {!loading &&
                    !isEmpty(times) &&
                    times.map((locTime, idx) => (
                      <LocationTimeRow
                        key={idx}
                        idx={idx}
                        locTime={locTime}
                        handleDelete={handleDelete}
                      />
                    ))}
                </tbody>
              </table>
            </Container>
          </Container>
        )}
      </Row>
    </Fragment>
  );
}

function mapStateToProps(state) {
  const { single, times, loading } = state.location;
  return { single, times, loading };
}

const mapDispatchToProps = {
  getSingle: locationActions.getSingle,
  getTimes: locationActions.getLocationTimes,
  deleteTime: locationActions.deleteTime
};

const connectedLocationTime = connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationTime);

export { connectedLocationTime as LocationTime };
