import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { LeaderboardCard } from '../Shared';

export function OverviewRow(props) {
  const { official, unofficial, locationName } = props;
  return (
    <article>
      <Container>
        <Row className="leaderboard-row">
          <Col xs={12}>
            <h4>{locationName} Best Times</h4>
          </Col>
          <Col md={6}>
            <LeaderboardCard
              title="Social"
              data={unofficial}
              className="social"
              tip="The Social Leaderboard lists times recorded outside of official events"
              showTooltip
            />
          </Col>
          <Col md={6}>
            <LeaderboardCard
              title="Comp"
              data={official}
              className="comp"
              tip="The Comp Leaderboard lists all results recorded by an accredited Referee during one of our Events"
              showTooltip
            />
          </Col>
        </Row>
      </Container>
    </article>
  );
}
