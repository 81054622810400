import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  CustomInput,
  Input,
  Label,
  Button
} from 'reactstrap';
import { Title, RichEditor } from '../../Shared';
import { AdminHeader } from '../';
import { contentActions } from '../../_actions';

function EditContent(props) {
  const { saving, updateContent, getContent, getSections, content, sections } = props;
  const { sectionId } = props.match.params;

  const [contentSectionId, setSectionId] = useState(0);
  const [copy, setCopy] = useState('');
  const [title, setTitle] = useState('');

  useEffect(() => {
    getContent();
    getSections();
  }, [getContent, getSections]);

  // if on edit page after posting content grab the sectionId from the route
  // and set it accordingly.
  useEffect(() => {
    if (sectionId) {
      setSectionId(sectionId);
    }
  }, [sectionId]);

  const changeSection = id => {
    setSectionId(id);
    setTitle('');
    setCopy('');
  };

  const handleChange = e => {
    setCopy(e.target.value);
  };

  const handleSubmit = e => {
    e.preventDefault();
    const { id, contentSectionId } = toEdit;
    updateContent({ copy, title, id, contentSectionId });
  };

  const toEdit = content && content.find(c => c.contentSectionId === parseInt(contentSectionId));

  return (
    <Fragment>
      <Title text="Edit Content" />
      <Container>
        <AdminHeader title="Edit Content" goBack />
        <Row>
          <Col>
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="contentSectionId">Section</Label>
                <CustomInput
                  type="select"
                  id="contentSectionId"
                  name="contentSectionId"
                  value={contentSectionId}
                  onChange={e => {
                    changeSection(e.target.value);
                  }}
                >
                  <option value="0">Select...</option>
                  {sections &&
                    sections.map((s, idx) => (
                      <option key={idx} value={s.id}>
                        {s.name}
                      </option>
                    ))}
                </CustomInput>
              </FormGroup>
              {toEdit && (
                <Fragment>
                  <FormGroup>
                    <Label for="title">Title</Label>
                    <Input
                      name="title"
                      id="title"
                      type="text"
                      value={title ? title : toEdit.title}
                      onChange={e => setTitle(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Copy</Label>
                    <RichEditor handleChange={handleChange} initialState={toEdit.copy} />
                  </FormGroup>
                  {saving ? (
                    <Button color="primary" disabled>
                      Saving
                    </Button>
                  ) : (
                    <Button color="primary">Update</Button>
                  )}
                </Fragment>
              )}
            </Form>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

const mapDispatch = {
  updateContent: contentActions.update,
  getContent: contentActions.getAll,
  getSections: contentActions.getSections
};

function mapState(state) {
  const { saving, content, sections } = state.content;
  return { saving, content, sections };
}

const connectedEditContent = connect(
  mapState,
  mapDispatch
)(EditContent);
export { connectedEditContent as EditContent };
