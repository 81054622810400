import React from 'react';
import { Col } from 'reactstrap';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { DragHandle } from './';

export function AllEquipment(props) {
  const { allEquipment, getListStyle, getItemStyle } = props;
  return (
    <Col>
      <h4>All Equipment</h4>
      <Droppable droppableId="available">
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
            className="drag-list"
          >
            {allEquipment.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                    className="drag-item"
                  >
                    <DragHandle {...provided.dragHandleProps} />

                    {item.name}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </Col>
  );
}
