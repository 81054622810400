import React, { useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';

const baseStyle = {
  borderWidth: `1px`,
  borderColor: `#ced4da`,
  borderStyle: 'solid',
  borderRadius: `0.25rem`,
  borderTopRightRadius: `0`,
  borderBottomRightRadius: `0`,
  borderRight: `none`,
  padding: `2em`
};
const activeStyle = {
  borderColor: '#6c6',
  boxShadow: `0 0 0 0.2rem rgba(0, 154, 217, 0.25)`
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};
export function ImageDropzone(props) {
  const { handleChange } = props;
  const onDrop = useCallback(
    acceptedFiles => {
      handleChange({ target: { name: 'file', value: acceptedFiles[0] } });
    },
    [handleChange]
  );

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    accept: 'image/*',
    multiple: false
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <div
      className="h-100 p-2 d-flex justify-content-center align-items-center flex-column"
      {...getRootProps({ style })}
    >
      <input {...getInputProps()} />
      <div>Drag & drop files here or click to select</div>
    </div>
  );
}

ImageDropzone.propTypes = {
  handleChange: PropTypes.func.isRequired
};
