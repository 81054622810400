export const userManagementConstants = {
  CREATE_USER_REQUEST: 'CREATE_USER_REQUEST',
  CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
  CREATE_USER_FAILURE: 'CREATE_USER_FAILURE',
  EDIT_USER_REQUEST: 'EDIT_USER_REQUEST',
  EDIT_USER_SUCCESS: 'EDIT_USER_SUCCESS',
  EDIT_USER_FAILURE: 'EDIT_USER_FAILURE',
  GET_ROLES_REQUEST: 'GET_ROLES_REQUEST',
  GET_ROLES_SUCCESS: 'GET_ROLES_SUCCESS',
  GET_ROLES_FAILURE: 'GET_ROLES_FAILURE',
  GET_USERS_REQUEST: 'GET_USERS_REQUEST',
  GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
  GET_USERS_FAILURE: 'GET_USERS_FAILURE',
  GET_USER_REQUEST: 'GET_USER_REQUEST',
  GET_USER_SUCCESS: 'GET_USER_SUCCESS',
  GET_USER_FAILURE: 'GET_USER_FAILURE',
  DISABLE_USER_REQUEST: 'DISABLE_USER_REQUEST',
  DISABLE_USER_SUCCESS: 'DISABLE_USER_SUCCESS',
  DISABLE_USER_FAILURE: 'DISABLE_USER_FAILURE',
  FILTER_USERS: 'FILTER_USERS',
  CLEAR_FILTER: 'CLEAR_FILTER'
};