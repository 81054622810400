import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Row, InputGroup, CustomInput } from 'reactstrap';
import { locationActions } from '../_actions';
import { LocationCard, LocationFilter } from './';
import { isEmpty, debounce } from 'lodash';

function LocationFinding(props) {
  const { locations, filtered, filtering, states, getLocationsAndStates } = props;

  useEffect(() => {
    getLocationsAndStates();
  }, [getLocationsAndStates]);

  const delayedFilter = debounce(e => {
    props.filter(e.target.value);
  }, 350);

  const filterByState = value => {
    if (value === '0') {
      props.clearFilter();
    } else {
      props.filter(value);
    }
  };

  const handleChange = e => {
    e.persist();
    delayedFilter(e);
  };

  const saveLocation = coords => {
    const { latitude, longitude } = coords;
    props.locationsByDistance({ latitude, longitude });
  };

  return (
    <Fragment>
      <Row>
        <Col xs={12}>
          <h4>Find Your Nearest Park Warrior Location</h4>
        </Col>

        <Col>
          {states && (
            <InputGroup>
              <CustomInput
                type="select"
                id="stateselect"
                name="stateId"
                onChange={e => filterByState(e.target.value)}
                defaultValue={0}
                required
              >
                <option value="0">Select State</option>
                {states.map((s, idx) => (
                  <option key={idx} value={s}>
                    {s}
                  </option>
                ))}
              </CustomInput>
            </InputGroup>
          )}
        </Col>
        <Col>
          <LocationFilter handleChange={handleChange} saveLocation={saveLocation} />
        </Col>
      </Row>
      <Row>
        {filtering &&
          !isEmpty(filtered) &&
          filtered.map((loc, idx) => <LocationCard key={idx} location={loc} />)}
        {!filtering &&
          !isEmpty(locations) &&
          locations.map((loc, idx) => <LocationCard key={idx} location={loc} />)}
      </Row>
    </Fragment>
  );
}

function mapStateToProps(state) {
  const { locations, filtered, filtering = false, states } = state.location;
  return { locations, filtered, filtering, states };
}
const mapDispatchToProps = {
  filter: locationActions.filter,
  clearFilter: locationActions.removeFilter,
  getLocationsAndStates: locationActions.getLocationsAndStates,
  locationsByDistance: locationActions.locationsByDistance
};
const connectedLocationFinding = connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationFinding);

export { connectedLocationFinding as LocationFinding };
