import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export function LeaderboardResult(props) {
  const { result, place } = props;
  const { user } = result;
  return (
    <tr className="lb-result">
      <td className="place">{place}</td>
      <td>
        <div className="profile-data">
          <div className="profile-image">
            {user.current ? (
              <img src={user.current.url} alt="" className="img-fluid img-circle" />
            ) : (
              <FontAwesomeIcon className="img-fluid" icon="user" />
            )}
          </div>
          <div>{user.userName}</div>
        </div>
      </td>
      <td className="text-right">{result.tsDuration}</td>
    </tr>
  );
}
