import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

export function AdminHeader(props) {
  const { title, goBack = false } = props;
  return (
    <Row className="admin-tools-header">
      <Col>
        <h2>
          {goBack && (
            <span>
              <Link className="back-link" to="/Admin">
                Admin Tools
              </Link>{' '}
              »{' '}
            </span>
          )}
          {title}
        </h2>
      </Col>
    </Row>
  );
}
AdminHeader.propTypes = {
  title: PropTypes.string.isRequired,
  goBack: PropTypes.bool
};
