import { refereeConstants } from '../_constants';

const initialState = { users: [], creating: false, success: false };

export function referee(state = { ...initialState }, action) {
  switch (action.type) {
    case refereeConstants.CREATE_USER_REQUEST:
      return {
        ...state,
        success: false,
        creating: true,
        error: null
      };
    case refereeConstants.CREATE_USER_SUCCESS:
      return {
        ...state,
        success: true,
        creating: false
      };
    case refereeConstants.CREATE_USER_FAILURE:
      return {
        ...state,
        success: false,
        creating: false,
        error: action.data
      };
    case refereeConstants.GET_USER_SUCCESS:
      return {
        ...state,
        users: action.data
      };
    case refereeConstants.GET_USER_REQUEST:
    case refereeConstants.GET_USER_FAILURE:
    case refereeConstants.ADD_TIME_FOR_USER_REQUEST:
      return {
        ...state,
        timeSaved: false,
        timeError: false
      };
    case refereeConstants.ADD_TIME_FOR_USER_SUCCESS:
      return {
        ...state,
        timeSaved: true
      };
    case refereeConstants.ADD_TIME_FOR_USER_FAILURE:
      return {
        ...state,
        timeSaved: false,
        timeError: true
      };
    default:
      return state;
  }
}
