import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { Title, LoadingCard, PageHeader } from '../Shared';
import { eventActions } from '../_actions';
import isEmpty from 'lodash/isEmpty';
import { date } from '../_helpers';

function EventView(props) {
  const { single, loading, getEvent } = props;
  const { path } = props.match.params;
  useEffect(() => {
    getEvent(path);
  }, [getEvent, path]);
  return (
    <Fragment>
      <Title text="Event" />
      {loading && <LoadingCard />}
      {!loading && !isEmpty(single) && (
        <Fragment>
          <PageHeader>
            <h3>{single.name}</h3>
          </PageHeader>
          <Container className="mt-4">
            <Row>
              <Col>
                <p>
                  {single.location.name}. {single.location.location}, view on{' '}
                  <a href={single.location.gMapsUrl} target="_blank" rel="noopener noreferrer">
                    Google Maps
                  </a>
                </p>
                <p>
                  {single.name} will be held between {date.event(single.startTime)} -{' '}
                  {date.event(single.endTime)}
                </p>
                <div dangerouslySetInnerHTML={{ __html: single.content }} />
              </Col>
            </Row>
          </Container>
        </Fragment>
      )}
    </Fragment>
  );
}
function mapState(state) {
  const { single, loading } = state.event;
  return { single, loading };
}
const mapDispatch = {
  getEvent: eventActions.getSingle
};

const connectedEventView = connect(
  mapState,
  mapDispatch
)(EventView);
export { connectedEventView as EventView };
