import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { Title, LoadingCard } from '../../Shared';
import { eventActions } from '../../_actions';

function EventSingle(props) {
  const { single, loading, getSingle } = props;
  useEffect(() => {
    const { path } = props.match.params;
    if (path) {
      getSingle(path);
    }
  }, [getSingle, props.match.params]);

  return (
    <Fragment>
      {loading && <LoadingCard />}
      {!loading && single && (
        <Fragment>
          <Title text={single.name} />
          <Container className="mt-4">
            <Row>
              <Col>
                <h3>{single.name}</h3>
                <div dangerouslySetInnerHTML={{ __html: single.content }} />
                <p>Date Time</p>
                <p>From: {new Date(single.startTime).toLocaleString()} </p>
                <p>To: {new Date(single.endTime).toLocaleString()}</p>
                <h4>Location</h4>
                <p>{single.location.name}</p>
                <p>{single.location.location}</p>
                <p>Funky google map goes here</p>
              </Col>
            </Row>
          </Container>
        </Fragment>
      )}
    </Fragment>
  );
}

function mapStateToProps(state) {
  const { single = null, loading = true } = state.event;
  return { single, loading };
}

const mapDispatchToProps = {
  getSingle: eventActions.getSingle
};

const connectedEventSingle = connect(
  mapStateToProps,
  mapDispatchToProps
)(EventSingle);
export { connectedEventSingle as EventSingle };
