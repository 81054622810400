import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button } from 'reactstrap';
import { isEmpty } from 'lodash';
import { Title, PageHeader, LoadingCard } from '../Shared';
import { UserLeaderboard } from './';
import { leaderboardActions } from '../_actions';

function MyResults(props) {
  const { getMyResults, myResults, loading } = props;

  useEffect(() => {
    getMyResults();
  }, [getMyResults]);


  return (
    <Fragment>
      <Title text="My Results" />
      <PageHeader>
        <Row>
          <Col>
            <h2>My Results</h2>
          </Col>
        </Row>
      </PageHeader>
      <Container className="mt-4">
        <Row>
          {loading && <LoadingCard />}
          {!isEmpty(myResults) && (
            <Fragment>
              <Col xs={12}>
                <h4>
                  You have completed {myResults.count} Park Warrior Courses, with{' '}
                  {myResults.totalOfficial} results on the Competition Leaderboard and{' '}
                  {myResults.totalUnofficial} on the Social Leaderboard.
                </h4>
                <h5>
                  You are {myResults.diffPercent} {myResults.faster ? `faster` : `slower`} than the
                  average across all Locations. Your average course completion time is{' '}
                  {myResults.time}.
                </h5>
              </Col>
              <Col xs={12}>
                <hr />
              </Col>
              <Col>
                <UserLeaderboard
                  data={myResults.official}
                  title={`Top ${myResults.countOfficial} Competition Results`}
                  className="comp"
                />
              </Col>
              <Col>
                <UserLeaderboard
                  data={myResults.unofficial}
                  title={`Top ${myResults.countUnofficial} Social Results`}
                  className="social"
                />
              </Col>
            </Fragment>
          )}
          {isEmpty(myResults) && (
            <Col xs={12}>
              <p>You currently have no results to display, but do not fear.</p>
              <p>Click the link below to get you started on the path to becoming a Park Warrior Hero.</p>
              <Button tag={Link} to="/RecordTime" color="primary">
                Come join the fun and find your local course.
              </Button>
            </Col>
          )}
        </Row>
      </Container>
    </Fragment>
  );
}
const mapDispatch = {
  getMyResults: leaderboardActions.myResults
};

function mapState(state) {
  const { myResults, loading } = state.leaderboard;
  return { myResults, loading };
}

const connectedMyResults = connect(
  mapState,
  mapDispatch
)(MyResults);
export { connectedMyResults as MyResults };
