import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { DragDropContext } from 'react-beautiful-dnd';
import { equipmentActions, locationEquipmentActions } from '../../_actions';
import { EquipmentOnSite, AllEquipment } from './';

const getItemStyle = (isDragging, draggableStyle) => ({
  // change background colour if dragging
  background: isDragging ? 'lightgreen' : '#dfdfdf',
  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : '#fff'
});

class LocationEquipment extends React.Component {
  componentDidMount = () => {
    const { path } = this.props;
    this.props.getAllEquipment();
    if (path) {
      this.props.getForLocation(path);
    }
  };

  onDragEnd = result => {
    const { source, destination, draggableId } = result;
    const { locationId } = this.props;
    const available = 'available';
    const onSite = 'onSite';
    // return;
    // dropped outside the list
    if (!destination) {
      return;
    }
    // not moved
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }
    // attempting to reorder source list
    if (destination.droppableId === available && source.droppableId === available) {
      return;
    }
    const { index } = destination;
    // adding equipment to location
    if (source.droppableId === available && destination.droppableId === onSite) {
      this.props.addToLocation({ equipmentId: draggableId, locationId, index });
    }
    // updating onsite equipment order
    if (source.droppableId === onSite && destination.droppableId === onSite) {
      this.props.updateIndex({ id: draggableId, locationId, index });
    }
    if (source.droppableId === onSite && destination.droppableId === available) {
      // this.props.removeFromLocation();
    }
  };

  removeFromLocation = locEquipId => {
    this.props.removeFromLocation(locEquipId);
  };

  addToLocation = equipmentId => {
    const { locationId } = this.props;
    this.props.addToLocation({ equipmentId, locationId });
  };

  render() {
    const { allEquipment = [], atSite = [] } = this.props;
    return (
      <Container className="mt-4">
        <Row>
          <Col>
            <h4>Manage Equipment</h4>
          </Col>
        </Row>
        <Row>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <EquipmentOnSite
              atSite={atSite}
              getListStyle={getListStyle}
              getItemStyle={getItemStyle}
            />
            <AllEquipment
              allEquipment={allEquipment}
              getListStyle={getListStyle}
              getItemStyle={getItemStyle}
            />
          </DragDropContext>
        </Row>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  const {
    equipment: { allEquipment = [] },
    locationEquipment: { atSite }
  } = state;
  return { allEquipment, atSite };
}

const mapDispatchToProps = {
  getAllEquipment: equipmentActions.getAll,
  addToLocation: locationEquipmentActions.addToLocation,
  removeFromLocation: locationEquipmentActions.removeFromLocation,
  getForLocation: locationEquipmentActions.getForLocation,
  updateIndex: locationEquipmentActions.updateIndex
};

const connectedLocationEquipment = connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationEquipment);
export { connectedLocationEquipment as LocationEquipment };
