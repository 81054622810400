import React from 'react';
import { Layout } from './Layout';
import { Route, Switch } from 'react-router-dom';
import {
  Home,
  PrivacyPolicy,
  Feedback,
  NotFound404,
  Unauthorized,
  About,
  Obstacles
} from './Pages/';
import {
  Login,
  SignUp,
  ConfirmEmail,
  ForgotPassword,
  ResetPassword,
  MyAccount,
  MyResults
} from './Account';
import { AdminRoute } from './AdminRoute';
import { RefereeRoute } from './RefereeRoute';
import { Admin, Event, Equipment, Location } from './Admin';
import { AddEvent, EventList, EventSingle, EventEdit } from './Admin/Event/';
import { AddEquipment, EquipmentList, EquipmentSingle, EquipmentEdit } from './Admin/Equipment/';
import {
  AddLocation,
  LocationList,
  LocationSingle,
  LocationEdit,
  LocationTime
} from './Admin/Location/';
import { Leaderboards } from './Leaderboard/';
import {
  Locations as AllLocations,
  LocationSingle as LocationView,
  LocationStopwatch
} from './Locations';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Events, EventView } from './Events/';
import { AddUser, UserEdit, UserSingle, UserList } from './Admin/UserManagement/';
import { RecordTimes, AddUser as CreateUser, Referee } from './Referee/';
import { AddContent, EditContent, ViewContent } from './Admin/Content';
import { Send } from './Admin/Sms';

export default function App() {
  return (
    <Layout>
      <Route
        render={({ location }) => (
          <TransitionGroup>
            <CSSTransition timeout={300} classNames="fade" exit={false} key={location.key}>
              <Switch location={location}>
                <Route exact path="/" component={Home} />
                {/* --- Pages --- */}
                <Route exact path="/PrivacyPolicy" component={PrivacyPolicy} />
                <Route exact path="/Feedback" component={Feedback} />
                <Route exact path="/About" component={About} />
                <Route exact path="/Obstacles" component={Obstacles} />
                {/* --- Account --- */}
                <Route exact path="/Account/SignUp" component={SignUp} />
                <Route exact path="/Account/Login" component={Login} />
                <Route exact path="/Account/ConfirmEmail/:userId/:code" component={ConfirmEmail} />
                <Route exact path="/Account/ForgotPassword" component={ForgotPassword} />
                <Route path="/Account/ResetPassword/:code" component={ResetPassword} />
                <Route path="/Account/MyAccount" component={MyAccount} />
                <Route path="/Account/MyResults" component={MyResults} />
                {/* --- Locations --- */}
                <Route exact path="/Locations" component={AllLocations} />
                <Route exact path="/Locations/View/:path" component={LocationView} />
                <Route
                  exact
                  path="/Locations/View/:locationId/Stopwatch"
                  component={LocationStopwatch}
                />
                {/* --- Leaderboard --- */}
                <Route exact path="/Leaderboards" component={Leaderboards} />
                {/* --- Events --- */}
                <Route exact path="/Events" component={Events} />
                <Route exact path="/Events/View/:path" component={EventView} />
                {/* --- Referee Routes --- */}
                <RefereeRoute exact path="/Referee" component={Referee} />
                <RefereeRoute exact path="/Referee/RecordTimes" component={RecordTimes} />
                <RefereeRoute exact path="/Referee/AddUser" component={CreateUser} />
                {/* --- Admin Routes --- */}
                <AdminRoute exact path="/Admin/" component={Admin} />
                {/* Events */}
                <AdminRoute exact path="/Admin/Event" component={Event} />
                <AdminRoute exact path="/Admin/Event/Add" component={AddEvent} />
                <AdminRoute exact path="/Admin/Event/Manage" component={EventList} />
                <AdminRoute exact path="/Admin/Event/Remove" remove={true} component={EventList} />
                <AdminRoute exact path="/Admin/Event/Single/:path" component={EventSingle} />
                <AdminRoute exact path="/Admin/Event/Edit/:path" component={EventEdit} />
                {/* Equipment */}
                <AdminRoute exact path="/Admin/Equipment" component={Equipment} />
                <AdminRoute exact path="/Admin/Equipment/Add" component={AddEquipment} />
                <AdminRoute exact path="/Admin/Equipment/Manage" component={EquipmentList} />
                <AdminRoute
                  exact
                  path="/Admin/Equipment/Remove"
                  remove={true}
                  component={EquipmentList}
                />
                <AdminRoute
                  exact
                  path="/Admin/Equipment/Single/:equipId"
                  component={EquipmentSingle}
                />
                <AdminRoute exact path="/Admin/Equipment/Edit/:equipId" component={EquipmentEdit} />
                {/* Locations */}
                <AdminRoute exact path="/Admin/Locations" component={Location} />
                <AdminRoute exact path="/Admin/Locations/Add" component={AddLocation} />
                <AdminRoute exact path="/Admin/Locations/Manage" component={LocationList} />
                <AdminRoute
                  exact
                  path="/Admin/Locations/Remove"
                  remove={true}
                  component={LocationList}
                />
                <AdminRoute exact path="/Admin/Locations/Single/:path" component={LocationSingle} />
                <AdminRoute exact path="/Admin/Locations/Edit/:path" component={LocationEdit} />
                <AdminRoute exact path="/Admin/Locations/Time/:path" component={LocationTime} />
                {/* User Management */}
                <AdminRoute exact path="/Admin/UserManagement/Add" component={AddUser} />
                <AdminRoute exact path="/Admin/UserManagement/Manage" component={UserList} />
                <AdminRoute
                  exact
                  path="/Admin/UserManagement/Remove"
                  remove={true}
                  component={UserList}
                />
                <AdminRoute
                  exact
                  path="/Admin/UserManagement/Single/:userId"
                  component={UserSingle}
                />
                <AdminRoute exact path="/Admin/UserManagement/Edit/:userId" component={UserEdit} />
                {/* Content */}
                <AdminRoute exact path="/Admin/Content/Add" component={AddContent} />
                <AdminRoute exact path="/Admin/Content/Edit/:sectionId?" component={EditContent} />
                <AdminRoute exact path="/Admin/Content/View" component={ViewContent} />
                {/* SMS */}
                <AdminRoute exact path="/Admin/Sms/Send" component={Send} />
                {/* --- Unauthorized Routes --- */}
                <Route path="/Unauthorized" component={Unauthorized} />
                {/* --- 404 --- */}
                <Route component={NotFound404} />
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        )}
      />
    </Layout>
  );
}
