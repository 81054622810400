import { leaderboardConstants } from '../_constants';

export function leaderboard(state = {}, action) {
  switch (action.type) {
    case leaderboardConstants.GET_LEADERBOARD_REQUEST:
    case leaderboardConstants.GET_USER_RESULTS_REQUEST:
      return {
        ...state
      };
    case leaderboardConstants.GET_LEADERBOARD_OVERVIEW_REQUEST:
      return {
        ...state,
        loading: true,
        error: false
      };
    case leaderboardConstants.GET_LEADERBOARD_SUCCESS:
    case leaderboardConstants.GET_USER_RESULTS_SUCCESS:
      return {
        ...action.data
      };
    case leaderboardConstants.GET_LEADERBOARD_OVERVIEW_SUCCESS:
      return {
        ...state,
        overviews: action.data,
        loading: false,
        error: false
      };
    case leaderboardConstants.ADD_TO_LEADERBOARD_REQUEST:
      return {
        ...state,
        timeSaved: false
      };
    case leaderboardConstants.ADD_TO_LEADERBOARD_SUCCESS:
      const { unofficial = [] } = state;
      unofficial.push(action.data);
      unofficial.sort((a, b) => a.duration - b.duration);
      return {
        ...state,
        unofficial: [...unofficial],
        timeSaved: true
      };
    case leaderboardConstants.GET_LEADERBOARD_OVERVIEW_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };
    case leaderboardConstants.MY_RESULTS_SUCCESS:
      return {
        ...state,
        myResults: action.data,
        loading: false
      };
    case leaderboardConstants.MY_RESULTS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case leaderboardConstants.MY_RESULTS_FAILURE:
      return {
        ...state,
        loading: false
      };
    case leaderboardConstants.GET_LEADERBOARD_FAILURE:
    case leaderboardConstants.GET_USER_RESULTS_FAILURE:
    case leaderboardConstants.ADD_TO_LEADERBOARD_FAILURE:
    default:
      return state;
  }
}
