import { contentConstants } from '../_constants';

export function content(state = { content: [], bySlug: {} }, action) {
  switch (action.type) {
    case contentConstants.GET_CONTENT_SECTIONS_SUCCESS:
      return {
        ...state,
        sections: action.data
      };
    case contentConstants.ADD_CONTENT_REQUEST:
    case contentConstants.UPDATE_CONTENT_REQUEST:
      return {
        ...state,
        saving: true,
        success: false,
        error: false
      };
    case contentConstants.ADD_CONTENT_SUCCESS:
    case contentConstants.UPDATE_CONTENT_SUCCESS:
      return {
        ...state,
        saving: false,
        success: true,
        error: true
      };
    case contentConstants.SECTION_HAS_CONTENT_SUCCESS:
      return {
        ...state,
        sectionHasContent: action.data
      };
    case contentConstants.UPDATE_CONTENT_FAILURE:
    case contentConstants.ADD_CONTENT_FAILURE:
      return {
        ...state,
        saving: false,
        success: false,
        error: true,
        sectionHasContent: false
      };
    case contentConstants.GET_CONTENT_SUCCESS:
      return {
        ...state,
        content: action.data
      };
    case contentConstants.GET_CONTENT_BY_SECTION_SLUG_SUCCESS:
      const { slug, bySlug } = state;
      bySlug[slug] = action.data;
      return {
        ...state,
        bySlug: { ...bySlug },
        slug: null
      };
    case contentConstants.GET_CONTENT_BY_SECTION_SLUG_REQUEST:
      return {
        ...state,
        slug: action.data
      };
    case contentConstants.ALL_CONTENT_BY_SLUG_SUCCESS:
      return {
        ...state,
        bySlug: action.data
      };
    case contentConstants.GET_CONTENT_BY_SECTION_SLUG_FAILURE:
    case contentConstants.SECTION_HAS_CONTENT_REQUEST:
    case contentConstants.SECTION_HAS_CONTENT_FAILURE:
    case contentConstants.GET_CONTENT_REQUEST:
    case contentConstants.GET_CONTENT_FAILURE:
    case contentConstants.GET_CONTENT_SECTIONS_REQUEST:
    case contentConstants.GET_CONTENT_SECTIONS_FAILURE:
    default:
      return state;
  }
}
