import React, { Fragment } from 'react';
import { Row } from 'reactstrap';
import { PanelCard } from '../Shared';

export function EquipmentPanels() {
  return (
    <Fragment>
      <h4>Manage Equipment</h4>
      <Row>
        <PanelCard icon="tools" url="/Admin/Equipment/Add">
          <p className="mb-0">Add new Equipment</p>
        </PanelCard>
        <PanelCard icon="toolbox" url="/Admin/Equipment/Manage">
          <p className="mb-0">Lookup</p>
        </PanelCard>
        <PanelCard icon="trash" url="/Admin/Equipment/Remove">
          <p className="mb-0">Remove Equipment</p>
        </PanelCard>
      </Row>
    </Fragment>
  );
}
