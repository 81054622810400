import React, { Fragment } from 'react';
import { Row } from 'reactstrap';
import { PanelCard } from '../Shared';

export function SmsPanels() {
  return (
    <Fragment>
      <h4>SMS</h4>
      <Row>
        <PanelCard icon="pencil-alt" url="/Admin/Sms/Send">
          <p className="mb-0">Send</p>
        </PanelCard>
        <PanelCard icon={['far', 'edit']} url="/Admin/Sms/Send">
          <p className="mb-0">???</p>
        </PanelCard>
        <PanelCard icon={['far', 'newspaper']} url="/Admin/Sms/Send">
          <p className="mb-0">???</p>
        </PanelCard>
      </Row>
    </Fragment>
  );
}
