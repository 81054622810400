import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { NavItem, NavLink } from 'reactstrap';
import { NavLink as RRNavLink } from 'react-router-dom';

export function NavItems({ navigated }) {
  return (
    <Fragment>
      <NavItem className="d-md-none">
        <NavLink tag={RRNavLink} exact to={'/'} onClick={navigated}>
          Home
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink tag={RRNavLink} to={'/Obstacles'} onClick={navigated}>
          Obstacles & Rules
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink className="locations" tag={RRNavLink} to={'/Locations'} onClick={navigated}>
          Locations
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink className="events" tag={RRNavLink} to={'/Events'} onClick={navigated}>
          Events
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink className="leaderboards" tag={RRNavLink} to={'/Leaderboards'} onClick={navigated}>
          Leaderboards
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink className="feedback" tag={RRNavLink} to={'/Feedback'} onClick={navigated}>
          Feedback
        </NavLink>
      </NavItem>
    </Fragment>
  );
}
NavItems.propTypes = {
  navigated: PropTypes.func.isRequired
};
