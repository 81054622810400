import { smsConstants } from '../_constants';
import { smsService } from '../_services';
import { toastr } from 'react-redux-toastr';
export const smsActions = {
  send,
  sendToGroup,
  locationSubscribers,
  getRecipientNumbers
};

function send(data) {
  return dispatch => {
    dispatch(request(data));
    smsService.send(data).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: smsConstants.SEND_SMS_REQUEST, data };
  }
  function success(data) {
    return { type: smsConstants.SEND_SMS_SUCCESS, data };
  }
  function failure(error) {
    return { type: smsConstants.SEND_SMS_FAILURE, error };
  }
}

function sendToGroup(data) {
  return dispatch => {
    dispatch(request(data));
    smsService.sendToGroup(data).then(
      data => {
        dispatch(success(data));
        toastr.success('Message sent successfully.');
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: smsConstants.SEND_GROUP_SMS_REQUEST, data };
  }
  function success(data) {
    return { type: smsConstants.SEND_GROUP_SMS_SUCCESS, data };
  }
  function failure(error) {
    return { type: smsConstants.SEND_GROUP_SMS_FAILURE, error };
  }
}

function getRecipientNumbers() {
  return dispatch => {
    dispatch(request());
    smsService.getRecipientNumbers().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: smsConstants.GET_RECIPIENT_NUMBERS_REQUEST, data };
  }
  function success(data) {
    return { type: smsConstants.GET_RECIPIENT_NUMBERS_SUCCESS, data };
  }
  function failure(error) {
    return { type: smsConstants.GET_RECIPIENT_NUMBERS_FAILURE, error };
  }
}

function locationSubscribers() {
  return dispatch => {
    dispatch(request());
    smsService.locationSubscribers().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: smsConstants.GET_LOCATION_SUBS_REQUEST, data };
  }
  function success(data) {
    return { type: smsConstants.GET_LOCATION_SUBS_SUCCESS, data };
  }
  function failure(error) {
    return { type: smsConstants.GET_LOCATION_SUBS_FAILURE, error };
  }
}
