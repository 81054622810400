import { equipmentConstants } from '../_constants';

export function equipment(state = {}, action) {
  switch (action.type) {
    case equipmentConstants.CREATE_EQUIPMENT_REQUEST:
      return {
        ...state,
        creating: true
      };
    case equipmentConstants.CREATE_EQUIPMENT_SUCCESS:
      const { allEquipment = [] } = state;
      allEquipment.push(action.data);
      return {
        ...state,
        creating: false,
        allEquipment,
        imgUrl: null
      };
    case equipmentConstants.CREATE_EQUIPMENT_FAILURE:
      return {
        ...state,
        creating: false,
        error: true
      };
    case equipmentConstants.LIST_EQUIPMENT_REQUEST:
      return {
        ...state,
        loading: true
      };

    case equipmentConstants.LIST_EQUIPMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        allEquipment: action.data
      };
    case equipmentConstants.LIST_EQUIPMENT_FAILURE:
      return {
        ...state,
        loading: false
      };
    case equipmentConstants.GET_EQUIPMENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case equipmentConstants.GET_EQUIPMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        single: action.data
      };
    case equipmentConstants.GET_EQUIPMENT_FAILURE:
      return {
        ...state,
        loading: false
      };
    case equipmentConstants.UPDATE_EQUIPMENT_REQUEST:
      return {
        ...state,
        updating: true
      };
    case equipmentConstants.UPDATE_EQUIPMENT_SUCCESS:
      return {
        ...state,
        updating: false,
        single: action.data,
        imgUrl: null
      };
    case equipmentConstants.UPDATE_EQUIPMENT_FAILURE:
      return {
        ...state,
        updating: false
      };
    case equipmentConstants.DELETE_EQUIPMENT_REQUEST:
      return {
        ...state,
        delete: true
      };
    case equipmentConstants.DELETE_EQUIPMENT_SUCCESS:
      const equipment = state.allEquipment.filter(e => e.id !== action.data);
      return {
        ...state,
        allEquipment: equipment,
        delete: true
      };
    case equipmentConstants.DELETE_EQUIPMENT_FAILURE:
      return {
        ...state,
        delete: false
      };
    case equipmentConstants.UPLOAD_IMAGE_REQUEST:
      return {
        ...state,
        imgUploading: true
      };
    case equipmentConstants.UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        imgUploading: false,
        imgUrl: action.data
      };
    case equipmentConstants.UPLOAD_IMAGE_FAILURE:
      return {
        ...state,
        imgUploading: false,
        imgUploadError: true
      };
      case equipmentConstants.FILTER_EQUIPMENT:
      const filtered = state.allEquipment.filter(
        e => e.name.indexOf(action.terms) >= 0
      );
      return {
        ...state,
        filtering: true,
        filtered
      };
      case equipmentConstants.CLEAR_FILTER:
      return {
        ...state,
        filtering: false,
        filtered: null
      };
    default:
      return state;
  }
}
