import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { isEmpty } from 'lodash';
import { LocationCard, LocationFilter } from './';
import { Title, PageHeader } from '../Shared';
import { locationActions, contentActions } from '../_actions';

function Locations(props) {
  const slug = 'locations';
  const { getLocationsAndStates, bySectionSlug, filtered, filtering, bySlug, states } = props;
  useEffect(() => {
    getLocationsAndStates();
    bySectionSlug(slug);
  }, [getLocationsAndStates, bySectionSlug]);

  const handleChange = e => {
    e.persist();
    props.filter(e.target.value);
  };

  const saveLocation = coords => {
    const { latitude, longitude } = coords;
    props.locationsByDistance({ latitude, longitude });
  };

  const content = bySlug[slug];
  return (
    <Fragment>
      <Title text="All Locations" />
      <PageHeader className="default">
        <Row>
          <Col>
            {isEmpty(content) ? (
              <h4>All Park Warrior Locations</h4>
            ) : (
              <Fragment>
                <h2>{content.title}</h2>
                <div dangerouslySetInnerHTML={{ __html: content.copy }} />
              </Fragment>
            )}
          </Col>
        </Row>
      </PageHeader>
      <Container className="mt-4">
        <Row>
          <Col>
            <LocationFilter
              states={states}
              handleChange={handleChange}
              saveLocation={saveLocation}
            />
          </Col>
        </Row>
        <Row>
          {filtering &&
            !isEmpty(filtered) &&
            filtered.map((loc, idx) => <LocationCard key={idx} location={loc} />)}
        </Row>
      </Container>
    </Fragment>
  );
}

function mapStateToProps(state) {
  const { states, filtered, filtering = false } = state.location;
  const { bySlug } = state.content;
  return { filtered, states, filtering, bySlug };
}

const mapDispatchToProps = {
  getLocationsAndStates: locationActions.getLocationsAndStates,
  filter: locationActions.filter,
  clearFilter: locationActions.removeFilter,
  locationsByDistance: locationActions.locationsByDistance,
  bySectionSlug: contentActions.bySectionSlug
};
const connectedLocations = connect(
  mapStateToProps,
  mapDispatchToProps
)(Locations);

export { connectedLocations as Locations };
