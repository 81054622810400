export const accountConstants = {
  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',
  FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILURE: 'FORGOT_PASSWORD_FAILURE',
  CONFIRM_EMAIL_REQUEST: 'CONFIRM_EMAIL_REQUEST',
  CONFIRM_EMAIL_SUCCESS: 'CONFIRM_EMAIL_SUCCESS',
  CONFIRM_EMAIL_FAILURE: 'CONFIRM_EMAIL_FAILURE',
  MY_ACCOUNT_REQUEST: 'MY_ACCOUNT_REQUEST',
  MY_ACCOUNT_SUCCESS: 'MY_ACCOUNT_SUCCESS',
  MY_ACCOUNT_FAILURE: 'MY_ACCOUNT_FAILURE',
  UPDATE_MY_ACCOUNT_REQUEST: 'UPDATE_MY_ACCOUNT_REQUEST',
  UPDATE_MY_ACCOUNT_SUCCESS: 'UPDATE_MY_ACCOUNT_SUCCESS',
  UPDATE_MY_ACCOUNT_FAILURE: 'UPDATE_MY_ACCOUNT_FAILURE',
  SIGN_UP_REQUEST: 'SIGN_UP_REQUEST',
  SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
  SIGN_UP_FAILURE: 'SIGN_UP_FAILURE',
  SIGN_UP_INLINE_REQUEST: 'SIGN_UP_INLINE_REQUEST',
  SIGN_UP_INLINE_SUCCESS: 'SIGN_UP_INLINE_SUCCESS',
  SIGN_UP_INLINE_FAILURE: 'SIGN_UP_INLINE_FAILURE',
  VALIDATE_PHONENUMBER_REQUEST: 'VALIDATE_PHONENUMBER_REQUEST',
  VALIDATE_PHONENUMBER_SUCCESS: 'VALIDATE_PHONENUMBER_SUCCESS',
  VALIDATE_PHONENUMBER_FAILURE: 'VALIDATE_PHONENUMBER_FAILURE',
  SUBSCRIBE_TO_EVENT_REQUEST: 'SUBSCRIBE_TO_EVENT_REQUEST',
  SUBSCRIBE_TO_EVENT_SUCCESS: 'SUBSCRIBE_TO_EVENT_SUCCESS',
  SUBSCRIBE_TO_EVENT_FAILURE: 'SUBSCRIBE_TO_EVENT_FAILURE',
  LOCATION_UNSUBSCRIBE_REQUEST: 'LOCATION_UNSUBSCRIBE_REQUEST',
  LOCATION_UNSUBSCRIBE_SUCCESS: 'LOCATION_UNSUBSCRIBE_SUCCESS',
  LOCATION_UNSUBSCRIBE_FAILURE: 'LOCATION_UNSUBSCRIBE_FAILURE',
  GET_LOCATION_SUBSCRIPTIONS_REQUEST: 'GET_LOCATION_SUBSCRIPTIONS_REQUEST',
  GET_LOCATION_SUBSCRIPTIONS_SUCCESS: 'GET_LOCATION_SUBSCRIPTIONS_SUCCESS',
  GET_LOCATION_SUBSCRIPTIONS_FAILURE: 'GET_LOCATION_SUBSCRIPTIONS_FAILURE',
  RESEND_PHONE_CODE_REQUEST: 'RESEND_PHONE_CODE_REQUEST',
  RESEND_PHONE_CODE_SUCCESS: 'RESEND_PHONE_CODE_SUCCESS',
  RESEND_PHONE_CODE_FAILURE: 'RESEND_PHONE_CODE_FAILURE',
  UPLOAD_PHOTO_REQUEST: 'UPLOAD_PHOTO_REQUEST',
  UPLOAD_PHOTO_SUCCESS: 'UPLOAD_PHOTO_SUCCESS',
  UPLOAD_PHOTO_FAILURE: 'UPLOAD_PHOTO_FAILURE'
};
