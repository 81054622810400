import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col, Form, FormGroup, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoginUserInput, PasswordInput, FormWrapper } from './';
import { authActions } from '../_actions';
import { inputValidation } from '../_helpers';

function Login(props) {
  const { login, logoutNoRedirect, loggingIn = false, error, message } = props;
  const { from } = props.location.state || { from: { pathname: '/' } };
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');

  const setPwValidation = () => {};

  useEffect(() => {
    logoutNoRedirect();
  }, [logoutNoRedirect]);

  const handleSubmit = e => {
    e.preventDefault();
    if (user && password) {
      login(user, password, from);
    }
  };

  const validatePassword = value => {
    setPwValidation(inputValidation.password(value));
  };

  return (
    <FormWrapper title="Login to your Account">
      <p>
        No account? <Link to="/Account/SignUp">Sign up</Link>
      </p>
      {error && <p style={{ fontSize: `1rem`, color: `red` }}>{message}</p>}
      <Form onSubmit={handleSubmit}>
        <LoginUserInput handleChange={setUser} user={user} />
        <PasswordInput
          handleChange={setPassword}
          password={password}
          validatePassword={validatePassword}
          validation={null}
        />
        <FormGroup className="mb-2" row>
          <Col className="d-flex justify-content-end">
            {loggingIn ? (
              <Button block color="info">
                <FontAwesomeIcon icon="spinner" spin /> Logging In
              </Button>
            ) : (
              <Button block color="primary">
                Login
              </Button>
            )}
          </Col>
        </FormGroup>
      </Form>
      <Row>
        <Col>
          <small className="form-text text-muted">
            <Link to="/Account/ForgotPassword">Forgot your password?</Link>
          </small>
        </Col>
        <Col className="text-right">
          <small>
            <Link to="/Account/SignUp">Sign Up</Link>
          </small>
        </Col>
      </Row>
    </FormWrapper>
  );
}

const mapDispatch = {
  login: authActions.login,
  logoutNoRedirect: authActions.logoutNoRedirect
};

function mapStateToProps(state) {
  const { loggingIn, error, message } = state.auth;
  return { loggingIn, error, message };
}

const connectedLogin = connect(
  mapStateToProps,
  mapDispatch
)(Login);
export { connectedLogin as Login };
