import { locationConstants } from '../_constants';
import { locationService } from '../_services';
import { toastr } from 'react-redux-toastr';
import { history } from '../_helpers';

export const locationActions = {
  create,
  update,
  disable,
  deleteTime,
  getAll,
  getLocationsAndStates,
  getLocationTimes,
  getState,
  getSingle,
  filter,
  removeFilter,
  locationsByDistance,
  locationOverviewResults
};

function filter(terms) {
  return dispatch => {
    if (terms.length) {
      dispatch(filter(terms));
    } else {
      dispatch(locationActions.removeFilter());
    }
  };
  function filter(terms) {
    return { type: locationConstants.FILTER_LOCATIONS, terms };
  }
}

function removeFilter() {
  return dispatch => {
    dispatch(clear());
  };
  function clear() {
    return { type: locationConstants.CLEAR_FILTER };
  }
}

function create(newLocation) {
  return dispatch => {
    dispatch(request(newLocation));
    locationService.create(newLocation).then(
      data => {
        dispatch(success(data));
        toastr.success('Success', `Created ${data.name}`);
        history.push(`/Admin/Locations/Edit/${data.path}`);
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: locationConstants.CREATE_LOCATION_REQUEST, data };
  }
  function success(data) {
    return { type: locationConstants.CREATE_LOCATION_SUCCESS, data };
  }
  function failure(error) {
    return { type: locationConstants.CREATE_LOCATION_FAILURE, error };
  }
}

function update(location) {
  return dispatch => {
    dispatch(request(location));
    locationService.update(location).then(
      data => {
        dispatch(success(data));
        toastr.success('Updated', `Successfully updated ${data.name}`);
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: locationConstants.UPDATE_LOCATION_REQUEST, data };
  }
  function success(data) {
    return { type: locationConstants.UPDATE_LOCATION_SUCCESS, data };
  }
  function failure(error) {
    return { type: locationConstants.UPDATE_LOCATION_FAILURE, error };
  }
}

function disable(locationId) {
  return dispatch => {
    dispatch(request(locationId));
    locationService.disable(locationId).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: locationConstants.DISABLE_LOCATION_REQUEST, data };
  }
  function success(data) {
    return { type: locationConstants.DISABLE_LOCATION_SUCCESS, data };
  }
  function failure(error) {
    return { type: locationConstants.DISABLE_LOCATION_FAILURE, error };
  }
}

function deleteTime(key) {
  return dispatch => {
    dispatch(request(key));
    locationService.deleteTime(key).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: locationConstants.DELETE_TIME_REQUEST, data };
  }
  function success(data) {
    return { type: locationConstants.DELETE_TIME_SUCCESS, data };
  }
  function failure(error) {
    return { type: locationConstants.DDELETE_TIME_FAILURE, error };
  }
}

function getAll() {
  return dispatch => {
    dispatch(request());
    locationService.getAll().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: locationConstants.LIST_LOCATION_REQUEST };
  }
  function success(data) {
    return { type: locationConstants.LIST_LOCATION_SUCCESS, data };
  }
  function failure(error) {
    return { type: locationConstants.LIST_LOCATION_FAILURE, error };
  }
}

function getLocationsAndStates() {
  return dispatch => {
    dispatch(request());
    locationService.getLocationsAndStates().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: locationConstants.GET_LOCATIONS_AND_STATES_REQUEST };
  }
  function success(data) {
    return { type: locationConstants.GET_LOCATIONS_AND_STATES_SUCCESS, data };
  }
  function failure(error) {
    return { type: locationConstants.GET_LOCATIONS_AND_STATES_FAILURE, error };
  }
}

function getLocationTimes(path) {
  return dispatch => {
    dispatch(request(path));
    locationService.getLocationTimes(path).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: locationConstants.LIST_LOCATION_TIMES_REQUEST, data };
  }
  function success(data) {
    return { type: locationConstants.LIST_LOCATION_TIMES_SUCCESS, data };
  }
  function failure(error) {
    return { type: locationConstants.LIST_LOCATION_TIMES_FAILURE, error };
  }
}

function getState() {
  return dispatch => {
    dispatch(request());
    locationService.getState().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: locationConstants.LIST_STATE_REQUEST };
  }
  function success(data) {
    return { type: locationConstants.LIST_STATE_SUCCESS, data };
  }
  function failure(error) {
    return { type: locationConstants.LIST_STATE_FAILURE, error };
  }
}

function getSingle(path) {
  return dispatch => {
    dispatch(request(path));
    locationService.getSingle(path).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: locationConstants.GET_LOCATION_REQUEST, data };
  }
  function success(data) {
    return { type: locationConstants.GET_LOCATION_SUCCESS, data };
  }
  function failure(error) {
    return { type: locationConstants.GET_LOCATION_FAILURE, error };
  }
}

function locationsByDistance(coords) {
  return dispatch => {
    dispatch(request(coords));
    locationService.locationsByDistance(coords).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: locationConstants.LOCATIONS_BY_DISTANCE_REQUEST, data };
  }
  function success(data) {
    return { type: locationConstants.LOCATIONS_BY_DISTANCE_SUCCESS, data };
  }
  function failure(error) {
    return { type: locationConstants.LOCATIONS_BY_DISTANCE_FAILURE, error };
  }
}

function locationOverviewResults() {
  return dispatch => {
    dispatch(request());
    locationService.getOverviewLeaderboard().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: locationConstants.GET_LOCATION_OVERVIEW_REQUEST };
  }
  function success(data) {
    return { type: locationConstants.GET_LOCATION_OVERVIEW_SUCCESS, data };
  }
  function failure(error) {
    return { type: locationConstants.GET_LOCATION_OVERVIEW_FAILURE, error };
  }
}
