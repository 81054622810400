import React, { Fragment } from 'react';
import { Row } from 'reactstrap';
import { PanelCard } from '../Shared';

export function EventPanels() {
  return (
    <Fragment>
      <h4>Manage Events</h4>
      <Row>
        <PanelCard icon="calendar-day" url="/Admin/Event/Add">
          <p className="mb-0">Add new Event</p>
        </PanelCard>
        <PanelCard icon="calendar-alt" url="/Admin/Event/Manage">
          <p className="mb-0">Lookup Event</p>
        </PanelCard>
        <PanelCard icon="calendar-minus" url="/Admin/Event/Remove">
          <p className="mb-0">Remove Event</p>
        </PanelCard>
      </Row>
    </Fragment>
  );
}
