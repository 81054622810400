import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';

export function EquipName(props) {
  const { handleChange, defaultValue } = props;
  return (
    <FormGroup>
      <Label for="name">Name</Label>
      <Input id="name" name="name" onChange={handleChange} defaultValue={defaultValue} />
    </FormGroup>
  );
}
