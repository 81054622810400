import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { Stopwatch } from '../Shared';
import { locationActions, leaderboardActions } from '../_actions';
import { LeaderboardRow } from '../Leaderboard';

class LocationStopwatch extends React.Component {
  componentDidMount = () => {
    const { locationId } = this.props.match.params;
    const { single } = this.props;
    if ((single && single.id !== parseInt(locationId)) || !single) {
      this.props.getLocation(locationId);
    }
    this.props.getLeaderboard(locationId);
  };

  saveTime = data => {
    const { locationId } = this.props.match.params;
    const { timeStarted, timeCompleted, runningTime } = data;
    this.props.addToLeaderboard({ locationId, timeStarted, timeCompleted, duration: runningTime });
  };

  render() {
    const { single, official, unofficial, added } = this.props;
    return (
      <Fragment>
        <Container className="mt-4">
          <Row>
            <Col>
              <h4>Record Your Time @ {single && single.name}</h4>
              <Stopwatch saveTime={this.saveTime} />
            </Col>
          </Row>
          <LeaderboardRow official={official} unofficial={unofficial} added={added} />
        </Container>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { single } = state.location;
  const { official, unofficial, added } = state.leaderboard;
  return { single, official, unofficial, added };
}

const mapDispatchToProps = {
  getLocation: locationActions.getSingle,
  addToLeaderboard: leaderboardActions.addToLeaderboard,
  getLeaderboard: leaderboardActions.listResults
};

const connectedLocationStopwatch = connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationStopwatch);
export { connectedLocationStopwatch as LocationStopwatch };
