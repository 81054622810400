import React from 'react';
import { Row, Col } from 'reactstrap';
import { LeaderboardCard } from '../Shared';

export function LeaderboardRow(props) {
  const { official, unofficial } = props;

  return (
    <Row>
      <Col xs={12}>
        <h4>Leaderboard</h4>
      </Col>
      <Col md={6}>
        <LeaderboardCard
          showTooltip
          title="Social"
          data={unofficial}
          className="social"
          tip="The Social Leaderboard lists times recorded outside of official events"
        />
      </Col>
      <Col md={6}>
        <LeaderboardCard
          showTooltip
          title="Comp"
          data={official}
          className="comp"
          tip="The Comp Leaderboard lists times recorded at official events"
        />
      </Col>
    </Row>
  );
}
