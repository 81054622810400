import React, { Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Title, PageHeader } from '../Shared';

export function Unauthorized() {
  return (
    <Fragment>
      <Title text="Unauthorized!" />
      <PageHeader className="events">
        <Row>
          <Col>
            <h2>Unauthorized</h2>
          </Col>
        </Row>
      </PageHeader>
      <Container className="mt-4">
        <Row>
          <Col>
            <p>
              You do not have permission to access that page. If you beleive this to be in error,
              please contact a site administrator.
            </p>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}
