import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import css from 'dom-css';

export class ShadowScrollbars extends React.Component {
  state = {
    scrollTop: 0,
    scrollHeight: 0,
    clientHeight: 0
  };

  handleUpdate = values => {
    const { shadowTop, shadowBottom } = this.refs;
    const { scrollTop, scrollHeight, clientHeight } = values;
    const shadowTopOpacity = (1 / 20) * Math.min(scrollTop, 20);
    const bottomScrollTop = scrollHeight - clientHeight;
    const shadowBottomOpacity =
      (1 / 20) * (bottomScrollTop - Math.max(scrollTop, bottomScrollTop - 20));
    css(shadowTop, { opacity: shadowTopOpacity });
    css(shadowBottom, { opacity: shadowBottomOpacity });
  };

  render() {
    const { style, ...props } = this.props;
    const containerStyle = {
      ...style,
      position: 'relative'
    };
    const sharedStyles = {
      position: 'absolute',
      left: 0,
      right: 0,
      height: 10
    };
    const shadowTopStyle = {
      ...sharedStyles,
      top: 0,
      background: 'linear-gradient(to bottom, rgba(0, 154, 217, 0.4) 0%, rgba(0, 154, 217, 0) 100%)'
    };
    const shadowBottomStyle = {
      ...sharedStyles,
      bottom: 0,
      background: 'linear-gradient(to top, rgba(243, 114, 90, 0.4) 0%, rgba(243, 114, 90, 0) 100%)'
    };
    return (
      <div style={containerStyle}>
        <Scrollbars
          ref="scrollbars"
          onUpdate={this.handleUpdate}
          autoHide
          autoHeight
          autoHeightMin={35}
          autoHeightMax={250}
          {...props}
        />
        <div ref="shadowTop" style={shadowTopStyle} />
        <div ref="shadowBottom" style={shadowBottomStyle} />
      </div>
    );
  }
}
