import React, { Fragment, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Col, Button, Label, Input, ButtonGroup } from 'reactstrap';
import AvatarEditor from 'react-avatar-editor';
import isEmpty from 'lodash/isEmpty';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { accountActions } from '../_actions';

function MyPhoto(props) {
  const editor = useRef(null);
  const [rotate, setRotate] = useState(0);
  const [scale, setScale] = useState(1.0);
  const [image, setImage] = useState(null);
  const { updatePhoto, current } = props;

  const handleSave = () => {
    if (editor) {
      const img = editor.current.getImageScaledToCanvas().toDataURL();
      const formData = new FormData();
      formData.append('file', img);
      updatePhoto(formData);
    }
  };

  const selectImage = e => {
    e.preventDefault();
    setImage(e.target.files[0]);
  };

  return (
    <Fragment>
      <Col>
        <h4>Upload a New Photo</h4>
        <AvatarEditor
          ref={editor}
          image={image}
          width={300}
          height={300}
          borderRadius={150}
          color={[0, 0, 0, 0.2]}
          border={3}
          scale={scale}
          rotate={rotate}
        />
        <div>
          <ButtonGroup size="lg">
            <Input
              id="profilePhoto"
              name="profilePhoto"
              type="file"
              multiple={false}
              accept="image/*"
              className="d-none"
              onChange={selectImage}
            />
            <Label className="btn btn-info my-0" for="profilePhoto">
              <FontAwesomeIcon icon="plus" />
            </Label>

            <Button color="primary" onClick={() => setRotate(rotate - 90)}>
              <FontAwesomeIcon icon="undo" />
            </Button>
            <Button color="primary" onClick={() => setRotate(rotate + 90)}>
              <FontAwesomeIcon icon="undo" style={{ transform: `rotateY(180deg)` }} />
            </Button>
            <Button color="primary" onClick={() => setScale(scale + 0.1)}>
              <FontAwesomeIcon icon="search-plus" />
            </Button>
            <Button color="primary" onClick={() => setScale(scale - 0.1)}>
              <FontAwesomeIcon icon="search-minus" />
            </Button>
            <Button color="info" onClick={handleSave} disabled={!image}>
              <FontAwesomeIcon icon={['far', 'save']} />
            </Button>
          </ButtonGroup>
        </div>
      </Col>
      {!isEmpty(current) && (
        <Col>
          <h4>Current Profile Photo</h4>
          <img style={{ borderRadius: `50%` }} alt="current" src={current.url} />
        </Col>
      )}
    </Fragment>
  );
}

function mapState(state) {
  const { myAccount } = state.account;
  const { current = {} } = myAccount;
  return { current };
}

const mapDispatch = {
  updatePhoto: accountActions.updatePhoto
};
const connectedMyPhoto = connect(
  mapState,
  mapDispatch
)(MyPhoto);

export { connectedMyPhoto as MyPhoto };
