import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const AdminRoute = ({ component: Component, ...rest }) => {
  const { loggedIn, user, remove = false } = rest;
  if (loggedIn && user.isAdmin) {
    return <Route {...rest} render={props => <Component {...props} remove={remove} />} />;
  } else if (!loggedIn) {
    return (
      <Route
        {...rest}
        render={props => (
          <Redirect
            to={{
              pathname: '/Account/Login',
              state: { from: props.location }
            }}
          />
        )}
      />
    );
  } else {
    return <Redirect to="/Unauthorized" />;
  }
};

function mapStateToProps(state) {
  const { loggedIn, user } = state.auth;
  return { loggedIn, user };
}

const connectedAdminRoute = connect(mapStateToProps)(AdminRoute);

export { connectedAdminRoute as AdminRoute };
