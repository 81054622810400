import { locationEquipmentConstants } from '../_constants';

export function locationEquipment(state = {}, action) {
  switch (action.type) {
    case locationEquipmentConstants.GET_SITE_EQUIPMENT_REQUEST:
      return {
        ...state
      };
    case locationEquipmentConstants.GET_SITE_EQUIPMENT_SUCCESS:
      return {
        ...state,
        equipment: action.data
      };
    case locationEquipmentConstants.GET_SITE_EQUIPMENT_FAILURE:
      return {
        ...state
      };
    case locationEquipmentConstants.GET_LOC_EQUIPMENT_REQUEST:
      return {
        atSite: []
      };
    case locationEquipmentConstants.GET_LOC_EQUIPMENT_SUCCESS:
      return {
        ...state,
        atSite: action.data
      };
    case locationEquipmentConstants.ADD_EQUIPMENT_TO_LOCATION_SUCCESS:
      return {
        ...state,
        atSite: action.data
      };
    case locationEquipmentConstants.UPDATE_EQUIPMENT_POSITION_REQUEST:
      // TODO: update this in react presuming the server will do it just fine
      // so it is presented in the correct way instantly.
      return {
        ...state
      };
    case locationEquipmentConstants.UPDATE_EQUIPMENT_POSITION_SUCCESS:
      return {
        ...state,
        atSite: action.data
      };
    case locationEquipmentConstants.UPDATE_EQUIPMENT_POSITION_FAILURE:
    case locationEquipmentConstants.GET_LOC_EQUIPMENT_FAILURE:
    case locationEquipmentConstants.ADD_EQUIPMENT_TO_LOCATION_FAILURE:
    case locationEquipmentConstants.ADD_EQUIPMENT_TO_LOCATION_REQUEST:
      return {
        ...state
      };
    case locationEquipmentConstants.REMOVE_EQUIPMENT_FROM_LOCATION_REQUEST:
      return {
        ...state,
        toRemoveId: action.data
      };
    case locationEquipmentConstants.REMOVE_EQUIPMENT_FROM_LOCATION_SUCCESS:
      const updated = state.atSite.filter(le => le.id !== state.toRemoveId);
      return {
        ...state,
        atSite: updated
      };
    case locationEquipmentConstants.REMOVE_EQUIPMENT_FROM_LOCATION_FAILURE:
      return {
        ...state
      };
    default:
      return state;
  }
}
