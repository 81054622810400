import React from 'react';
import PropTypes from 'prop-types';

export function LineSeparate({ color = '#939597' }) {
  return (
    <hr
      style={{
        color: color,
        background: color,
        height: 4
      }}
    />
  );
}
LineSeparate.propTypes = {
  color: PropTypes.string
};
