import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Card } from 'reactstrap';
import { FormWrapper } from './';
import { accountActions } from '../_actions';

function ConfirmEmail(props) {
  const { code, userId } = props.match.params;
  const { message, error, requesting, confirmEmail } = props;
  useEffect(() => {
    if (userId && code) {
      confirmEmail({ code, userId });
    }
  }, [confirmEmail, code, userId]);

  return (
    <FormWrapper title="Confirm Email">
      <Card style={{ padding: `8px` }}>
        {requesting && 'Confirming'}
        {message && message}
        {error && error}
      </Card>
    </FormWrapper>
  );
}

function mapState(state) {
  const { message, error, requesting } = state.account;
  return { message, error, requesting };
}

const mapDispatch = {
  confirmEmail: accountActions.confirmEmail
};

const connectedConfirmEmail = connect(
  mapState,
  mapDispatch
)(ConfirmEmail);
export { connectedConfirmEmail as ConfirmEmail };
