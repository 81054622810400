import { feedbackConstants } from '../_constants';

export function feedback(state = {}, action) {
  switch (action.type) {
    case feedbackConstants.CREATE_FEEDBACK_REQUEST:
      return {
        ...state,
        submitting: true
      };
    case feedbackConstants.CREATE_FEEDBACK_SUCCESS:
      return {
        ...state,
        comment: action.data,
        submitting: false,
        submitted: true
      };
    case feedbackConstants.CREATE_FEEDBACK_FAILURE:
      return {
        ...state,
        submitting: false,
        error: action.error
      };
    case feedbackConstants.CLEAR_STATE:
      return {};
    default:
      return state;
  }
}
