import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, InputGroup, CustomInput, Label } from 'reactstrap';
import { Title, Stopwatch } from '../Shared';
import { RefereeHeader } from './';
import { locationActions, refereeActions } from '../_actions';
import Select from 'react-select';
import debounce from 'lodash/debounce';
import { userSelectStyles } from './UserSelectStyles';

function RecordTimes(props) {
  const { getLocations, locations, locLoading, userLookup, users, user, timeSaved } = props;

  const [locationId, setLocationId] = useState(0);
  const [userId, setUserId] = useState(null);

  const rsOptions = users.map(u => {
    return { value: u.id, label: u.userName };
  });

  const delayedLookup = debounce(value => {
    if (value.length >= 1) {
      userLookup(value);
    }
  }, 250);

  useEffect(() => {
    getLocations();
  }, [getLocations]);
  // reset the form on successful save
  useEffect(() => {
    if (timeSaved) {
      setLocationId(0);
      setUserId(null);
    }
  }, [timeSaved]);

  const saveTime = data => {
    console.log(data);
    props.saveTime({ ...data, userId, locationId });
  };
  return (
    <Fragment>
      <Title text="Referee Tools" />
      <Container>
        <RefereeHeader title="Record Times" goBack />
        <Row>
          <Col>
            {!locLoading && locations && (
              <Fragment>
                <Label>Select a Location</Label>
                <InputGroup>
                  <CustomInput
                    type="select"
                    id="locationSelect"
                    name="locationId"
                    label="whatever"
                    onChange={e => setLocationId(e.target.value)}
                    value={locationId}
                    required
                  >
                    <option value="0">Select...</option>
                    {locations.map((l, idx) => (
                      <option key={idx} value={l.id}>
                        {l.name}
                      </option>
                    ))}
                  </CustomInput>
                </InputGroup>
              </Fragment>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>Find Competitor</Label>
            <Select
              options={rsOptions}
              onInputChange={val => delayedLookup(val)}
              onChange={val => setUserId(val.value)}
              styles={userSelectStyles}
              components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
            />
          </Col>
        </Row>
        {userId && locationId && (
          <Row>
            <Col xs={12}>
              <Stopwatch title="Record Time" saveTime={saveTime} user={user} />
            </Col>
          </Row>
        )}
      </Container>
    </Fragment>
  );
}
const mapDispatch = {
  getLocations: locationActions.getAll,
  userLookup: refereeActions.userLookup,
  saveTime: refereeActions.addToLeaderboard
};

function mapState(state) {
  const { locations, loading: locLoading = true } = state.location;
  const { users, timeSaved } = state.referee;
  const { user } = state.auth;
  return { locations, locLoading, users, user, timeSaved };
}

const connectedRecordTimes = connect(
  mapState,
  mapDispatch
)(RecordTimes);
export { connectedRecordTimes as RecordTimes };
