import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Container, Row } from 'reactstrap';
import { isEmpty } from 'lodash';
import { equipmentActions, contentActions } from '../_actions';
import { EquipmentCard } from '../Equipment';
import { Title, PageHeader, LoadingCard, FullscreenModal } from '../Shared';

function Obstacles(props) {
  const { getEquipment, loading, allEquipment, bySectionSlug, bySlug } = props;
  const [isOpen, setOpen] = useState(false);
  const [equipmentId, setEquipmentId] = useState(null);
  const slug = 'obstacles';
  const content = bySlug[slug];

  useEffect(() => {
    getEquipment();
    bySectionSlug(slug);
  }, [getEquipment, bySectionSlug]);

  const toggle = (e, id) => {
    if (id) {
      setEquipmentId(id);
    }
    setOpen(!isOpen);
  };

  const equipmentIndex = () => ({
    index: allEquipment.findIndex(e => e.id === equipmentId),
    arrLen: allEquipment.length - 1
  });

  const next = () => {
    const res = equipmentIndex();
    let next;
    if (res.index === res.arrLen) {
      next = allEquipment[0];
    } else {
      next = allEquipment[res.index + 1];
    }
    setEquipmentId(next.id);
  };

  const prev = () => {
    const res = equipmentIndex();
    let prev;
    if (res.index === 0) {
      prev = allEquipment[res.arrLen];
    } else {
      prev = allEquipment[res.index - 1];
    }
    setEquipmentId(prev.id);
  };

  return (
    <Fragment>
      <Title text="Obstacles &amp; Instructions" />
      <PageHeader className="default">
        {isEmpty(content) ? (
          <Fragment>
            <h2>Obstacles</h2>
            <p>Check out the obstacles, instructions, and rules for Park Warrior Obstacles</p>
          </Fragment>
        ) : (
          <Fragment>
            <h2>{content.title}</h2>
            <div dangerouslySetInnerHTML={{ __html: content.copy }} />
          </Fragment>
        )}
      </PageHeader>
      <Container className="mt-4">
        <Row>
          {loading && <LoadingCard />}
          {allEquipment &&
            allEquipment.map((equipment, idx) => (
              <EquipmentCard key={idx} equipment={equipment} toggle={toggle} />
            ))}
        </Row>
      </Container>
      <FullscreenModal
        isOpen={isOpen}
        toggle={toggle}
        equipmentId={equipmentId}
        equipment={allEquipment}
        next={next}
        prev={prev}
      />
    </Fragment>
  );
}

const mapDispatch = {
  getEquipment: equipmentActions.getAll,
  bySectionSlug: contentActions.bySectionSlug
};

function mapState(state) {
  const { allEquipment, loading } = state.equipment;
  const { bySlug } = state.content;
  return { allEquipment, loading, bySlug };
}
const connectedObstacles = connect(
  mapState,
  mapDispatch
)(Obstacles);
export { connectedObstacles as Obstacles };
