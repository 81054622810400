import React, { Fragment } from 'react';
import { NavMenu } from './Navigation';
import { Footer } from './Footer';
export const Layout = props => (
  <Fragment>
    <NavMenu />
    <div style={{ position: `relative`, minHeight: `calc(100vh - 173px)` }}>{props.children}</div>
    <Footer />
  </Fragment>
);
