import { authFetch, headers, handleResponse } from '../_helpers';

export const contentService = {
  getAll,
  add,
  update,
  getSections,
  sectionHasContent,
  bySectionSlug,
  allBySlug
};

async function getAll() {
  const td = await fetch(`/api/Content/GetContent`, headers.get());
  const data = handleResponse(td);
  return data;
}

async function add(content) {
  const td = await authFetch(`/api/Content/Add`, headers.post(content));
  return td;
}

async function update(content) {
  const td = await authFetch(`/api/Content/Update`, headers.post(content));
  return td;
}

async function getSections() {
  const td = await fetch(`/api/Content/GetSections`, headers.get());
  const data = await handleResponse(td);
  return data;
}

async function sectionHasContent(sectionId) {
  const td = await authFetch(`/api/Content/SectionHasContent`, headers.postPrimitive(sectionId));
  return td;
}

async function bySectionSlug(slug) {
  const td = await fetch(`/api/Content/BySectionSlug`, headers.postPrimitive(slug));
  const data = await handleResponse(td);
  return data;
}

async function allBySlug() {
  const td = await fetch(`/api/Content/AllBySlug`, headers.get());
  const data = await handleResponse(td);
  return data;
}
