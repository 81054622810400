import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { Title, LoadingCard } from '../../Shared';
import { userManagementActions } from '../../_actions';

class UserSingle extends React.Component {
  componentDidMount = () => {
    const { userId } = this.props.match.params;
    if (userId) { this.props.getSingle(userId);}
  };

  render() {
    const { user, loading } = this.props;
    return (
      <Fragment>
        {loading && <LoadingCard />}
        {!loading && user && (
          <Fragment>
            <Title text={user.firstName + " " + user.lastName } />
            <Container className="mt-4">
              <Row>
                <Col>
                <h3>{user.displayName}</h3>
                <p>Phone Number: {user.phoneNumber}</p>
                <p>Date Of Birth: {user.dateOfBirth}</p>
                <p>Email: {user.email}</p>
                <p>Role: {user.roles[0]}</p>
                </Col>
              </Row>
            </Container>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user = null, loading = true } = state.userManagement;
  return { user, loading };
}

const mapDispatchToProps = {
  getSingle: userManagementActions.getSingle
};

const connectedUserSingle = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserSingle);
export { connectedUserSingle as UserSingle };