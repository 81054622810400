import React, { Fragment } from 'react';
import { Title } from '../Shared';
import { Container } from 'reactstrap';
import { LocationPanels } from './';

export function Location() {
  return (
    <Fragment>
      <Title text="Location Manager" />
      <Container>
        <LocationPanels />
      </Container>
    </Fragment>
  );
}
