import { authFetch, headers } from '../_helpers';

export const refereeService = {
  userLookup,
  addToLeaderboard,
  createUser
};

async function userLookup(userName) {
  const res = await authFetch('/api/Referee/UserLookup', headers.postPrimitive(userName));
  return res;
}

async function addToLeaderboard(result) {
  const res = await authFetch('/api/Referee/AddToLeaderboard', headers.post(result));
  return res;
}

async function createUser(result) {
  const res = await authFetch('/api/Referee/CreateUser', headers.post(result));
  return res;
}
