import { locationEquipmentConstants } from '../_constants';
import { locationEquipmentService } from '../_services';

export const locationEquipmentActions = {
  getForLocation,
  getSiteEquipment,
  addToLocation,
  removeFromLocation,
  updateIndex
};

function updateIndex(newIndex) {
  return dispatch => {
    dispatch(request(newIndex));
    locationEquipmentService.updateIndex(newIndex).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: locationEquipmentConstants.UPDATE_EQUIPMENT_POSITION_REQUEST, data };
  }
  function success(data) {
    return { type: locationEquipmentConstants.UPDATE_EQUIPMENT_POSITION_SUCCESS, data };
  }
  function failure(error) {
    return { type: locationEquipmentConstants.UPDATE_EQUIPMENT_POSITION_FAILURE, error };
  }
}
function getSiteEquipment(path) {
  return dispatch => {
    dispatch(request(path));
    locationEquipmentService.getSiteEquipment(path).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: locationEquipmentConstants.GET_SITE_EQUIPMENT_REQUEST, data };
  }
  function success(data) {
    return { type: locationEquipmentConstants.GET_SITE_EQUIPMENT_SUCCESS, data };
  }
  function failure(error) {
    return { type: locationEquipmentConstants.GET_SITE_EQUIPMENT_FAILURE, error };
  }
}

function getForLocation(locationId) {
  return dispatch => {
    dispatch(request(locationId));
    locationEquipmentService.getForLocation(locationId).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: locationEquipmentConstants.GET_LOC_EQUIPMENT_REQUEST, data };
  }
  function success(data) {
    return { type: locationEquipmentConstants.GET_LOC_EQUIPMENT_SUCCESS, data };
  }
  function failure(error) {
    return { type: locationEquipmentConstants.GET_LOC_EQUIPMENT_FAILURE, error };
  }
}

function addToLocation(addEquipment) {
  return dispatch => {
    dispatch(request(addEquipment));
    locationEquipmentService.addEquipment(addEquipment).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: locationEquipmentConstants.ADD_EQUIPMENT_TO_LOCATION_REQUEST, data };
  }
  function success(data) {
    return { type: locationEquipmentConstants.ADD_EQUIPMENT_TO_LOCATION_SUCCESS, data };
  }
  function failure(error) {
    return { type: locationEquipmentConstants.ADD_EQUIPMENT_TO_LOCATION_FAILURE, error };
  }
}

function removeFromLocation(locEquipId) {
  return dispatch => {
    dispatch(request(locEquipId));
    locationEquipmentService.removeEquipment(locEquipId).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: locationEquipmentConstants.REMOVE_EQUIPMENT_FROM_LOCATION_REQUEST, data };
  }
  function success(data) {
    return { type: locationEquipmentConstants.REMOVE_EQUIPMENT_FROM_LOCATION_SUCCESS, data };
  }
  function failure(error) {
    return { type: locationEquipmentConstants.REMOVE_EQUIPMENT_FROM_LOCATION_FAILURE, error };
  }
}
