import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input } from 'reactstrap';

export function LoginUserInput(props) {
  const { handleChange, user } = props;
  return (
    <FormGroup>
      <Label for="user">Username or Email</Label>
      <Input
        id="user"
        name="user"
        type="text"
        onChange={e => handleChange(e.target.value)}
        value={user}
      />
    </FormGroup>
  );
}
LoginUserInput.propTypes = {
  handleChange: PropTypes.func.isRequired,
  user: PropTypes.string
};
