import React, { Fragment } from 'react';
import { Title } from '../Shared';
import { Container } from 'reactstrap';
import { EquipmentPanels } from './';
export function Equipment() {
  return (
    <Fragment>
      <Title text="Equipment Manager" />
      <Container>
        <EquipmentPanels />
      </Container>
    </Fragment>
  );
}
