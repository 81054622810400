import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { LoginSignUpSwitch } from '../Account';

function SignUp(props) {
  const { didRegister } = props;
  return (
    <Container>
      <Row>
        <Col>
          <h4>Sign up to Park Warrior</h4>
          <p>
            To save your times, post to leaderboards, view your history and obtain details of
            competition events, join now.
          </p>
        </Col>
        <Col xs={12}>
          <LoginSignUpSwitch title={false} didRegister={didRegister} />
        </Col>
      </Row>
    </Container>
  );
}

function mapState(state) {
  const { didRegister } = state.account;
  return { didRegister };
}

const connectedSignUp = connect(mapState)(SignUp);
export { connectedSignUp as SignUp };
