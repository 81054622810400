import { combineReducers } from 'redux';
import { nprogress } from 'redux-nprogress';
import { reducer as toastr } from 'react-redux-toastr';
import { account } from './account.reducer';
import { auth } from './auth.reducer';
import { content } from './content.reducer';
import { equipment } from './equipment.reducer';
import { event } from './event.reducer';
import { leaderboard } from './leaderboard.reducer';
import { feedback } from './feedback.reducer';
import { location } from './location.reducer';
import { locationEquipment } from './locationEquipment.reducer';
import { referee } from './referee.reducer';
import { userManagement } from './userManagement.reducer';
import { sms } from './sms.reducer';

const appReducer = combineReducers({
  account,
  auth,
  content,
  equipment,
  event,
  feedback,
  leaderboard,
  location,
  locationEquipment,
  referee,
  userManagement,
  sms,
  nprogress,
  toastr
});

export default appReducer;
