import React from 'react';
import { Container } from 'reactstrap';
export function PageHeader(props) {
  const { className = '' } = props;
  return (
    <Container className={`page-header ${className}`} fluid>
      <Container className="pt-4 pb-2 mb-1">{props.children}</Container>
    </Container>
  );
}
