import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Button } from 'reactstrap';
import { Title, LoadingCard, LookupFilter } from '../../Shared';
import { userManagementActions } from '../../_actions';
import { UserCard } from './';
import { AdminHeader, ViewEditFooter, RemoveFooter, RemoveModal } from '../';
import { isEmpty, debounce } from 'lodash';

function UserList(props) {
  const { users, loading, remove, getUsers, disable, filtered, filtering } = props;
  const [modal, setModal] = useState(false);
  const [active, setActive] = useState(false);
  const [disableId, setDisableId] = useState(null);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const toggleModal = () => {
    setModal(!modal);
  };

  const handleRemove = id => {
    setDisableId(id);
    toggleModal();
  };

  const handleDisable = id => {
    toggleModal();
    disable(id);
  };

  const delayedFilter = debounce(e => {
    props.filter(e.target.value);
  }, 350);

  const handleChange = e => {
    e.persist();
    delayedFilter(e);
  };

  const theUser = users && users.find(u => u.id === disableId);

  return (
    <Fragment>
      <Title text="User Lookup" />
      <Container>
        <AdminHeader goBack title="Manage Users" />
        <LookupFilter handleChange={handleChange} />
        <Row style={{ marginBottom: '20px', marginTop: '20px' }}>
          <Col>
            <Button color={!active ? 'secondary' : 'primary'} onClick={() => setActive(true)}>
              Disabled
            </Button>
            <Button color={active ? 'secondary' : 'primary'} onClick={() => setActive(false)}>
              Active
            </Button>
          </Col>
        </Row>
        <Row>
          {loading && <LoadingCard />}
          {!loading &&
            filtering &&
            !isEmpty(filtered) &&
            filtered
              .filter(c => c.disabled === active)
              .map((usr, idx) => (
                <UserCard key={idx} user={usr}>
                  {remove ? (
                    <RemoveFooter id={usr.id} handleRemove={handleRemove} />
                  ) : (
                    <ViewEditFooter id={usr.id} path={usr.id} urlPath="UserManagement" />
                  )}
                </UserCard>
              ))}
          {!filtering &&
            !isEmpty(users) &&
            users
              .filter(c => c.disabled === active)
              .map((usr, idx) => (
                <UserCard key={idx} user={usr}>
                  {remove ? (
                    <RemoveFooter id={usr.id} handleRemove={handleRemove} />
                  ) : (
                    <ViewEditFooter id={usr.id} path={usr.id} urlPath="UserManagement" />
                  )}
                </UserCard>
              ))}
        </Row>
      </Container>
      {theUser && (
        <RemoveModal
          item={theUser}
          modal={modal}
          toggle={toggleModal}
          handleDelete={handleDisable}
          title="Remove User"
        />
      )}
    </Fragment>
  );
}
const mapDispatch = {
  getUsers: userManagementActions.getUsers,
  disable: userManagementActions.disable,
  filter: userManagementActions.filter,
  removeFilter: userManagementActions.removeFilter
};
function mapStateToProps(state) {
  const { users, filtered, filtering = false, loading = true } = state.userManagement;
  return { users, loading, filtered, filtering };
}

const connectedUserList = connect(
  mapStateToProps,
  mapDispatch
)(UserList);
export { connectedUserList as UserList };
