import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Col, Card, CardBody } from 'reactstrap';

export function LocationCard(props) {
  const { location } = props;

  return (
    <Col xs={12} sm={4} className="mb-3 location-card">
      <Card className="shadow h-100">
        <CardBody tag={Link} to={`/Locations/View/${location.path}`}>
          <div className="loc-name">{location.name}</div>
          <div className="loc-addr">{location.location}</div>
        </CardBody>
      </Card>
    </Col>
  );
}

LocationCard.propTypes = {
  location: PropTypes.object.isRequired
};
