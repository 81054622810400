import React, { Fragment } from 'react';
import { Row } from 'reactstrap';
import { PanelCard } from '../Shared';

export function UserMgmtPanels() {
  return (
    <Fragment>
      <h4>User Management</h4>
      <Row>
        <PanelCard icon="user-plus" url="/Admin/UserManagement/Add">
          <p className="mb-0">Add User</p>
        </PanelCard>
        <PanelCard icon="user-edit" url="/Admin/UserManagement/Manage">
          <p className="mb-0">Lookup User</p>
        </PanelCard>
        <PanelCard icon="user-minus" url="/Admin/UserManagement/Remove">
          <p className="mb-0">Remove User</p>
        </PanelCard>
      </Row>
    </Fragment>
  );
}
