import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Title, LoadingCard, LocationFinder } from '../../Shared';
import { locationActions } from '../../_actions';
import { LocationEquipment } from './';

class LocationEdit extends Component {
  state = {};
  componentDidMount = () => {
    const { path } = this.props.match.params;
    if (path) {
      this.props.getSingle(path);
    }
  };
  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { single } = this.props;
    this.props.update({ ...single, ...this.state });
  };

  render() {
    const { single, loading, updating } = this.props;
    const { path } = this.props.match.params;

    return (
      <Fragment>
        <Container className="mt-4">
          <Row>
            {loading && <LoadingCard />}
            {!loading && single && (
              <Fragment>
                <Title text={`Editing ${single.name}`} />
                <Col>
                  <h3>Editing {single.name}</h3>
                  <Form onSubmit={this.handleSubmit}>
                    <FormGroup>
                      <LocationFinder handleChange={this.handleChange} location={single.location} />
                    </FormGroup>
                    <FormGroup>
                      <Label for="name">Name</Label>
                      <Input
                        id="name"
                        name="name"
                        onChange={this.handleChange}
                        defaultValue={single.name}
                      />
                    </FormGroup>
                    {updating ? (
                      <Button color="primary">
                        <FontAwesomeIcon icon="spinner" spin /> Saving
                      </Button>
                    ) : (
                      <Button color="primary">Save Changes</Button>
                    )}
                  </Form>
                </Col>
              </Fragment>
            )}
          </Row>
        </Container>
        {single && <LocationEquipment path={path} locationId={single.id} />}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { single, loading, updating } = state.location;
  return { single, loading, updating };
}

const mapDispatchToProps = {
  getSingle: locationActions.getSingle,
  update: locationActions.update
};

const connectedLocEdit = connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationEdit);
export { connectedLocEdit as LocationEdit };
