export const refereeConstants = {
  GET_USER_REQUEST: 'GET_USER_REQUEST',
  GET_USER_SUCCESS: 'GET_USER_SUCCESS',
  GET_USER_FAILURE: 'GET_USER_FAILURE',
  ADD_TIME_FOR_USER_REQUEST: 'ADD_TIME_FOR_USER_REQUEST',
  ADD_TIME_FOR_USER_SUCCESS: 'ADD_TIME_FOR_USER_SUCCESS',
  ADD_TIME_FOR_USER_FAILURE: 'ADD_TIME_FOR_USER_FAILURE',
  CREATE_USER_REQUEST: 'CREATE_USER_REQUEST',
  CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
  CREATE_USER_FAILURE: 'CREATE_USER_FAILURE'
};
