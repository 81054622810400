import { authConstants } from '../_constants';
import { authService } from '../_services';
import { beginTask, endTask } from 'redux-nprogress';
import { toastr } from 'react-redux-toastr';
import { history } from '../_helpers';

export const authActions = {
  login,
  loginNoRedirect,
  logout,
  logoutNoRedirect
};

function login(user, password, from) {
  return dispatch => {
    dispatch(beginTask());
    dispatch(request({ user }));
    authService
      .login(user, password)
      .then(
        user => {
          dispatch(success(user));
          if (user.redirectTo) {
            history.push(user.redirectTo);
          } else if (from !== '/') {
            history.push(from);
          } else {
            history.push('/');
          }
        },
        error => {
          dispatch(failure(error));
        }
      )
      .finally(() => dispatch(endTask()));
  };

  function request(user) {
    return { type: authConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: authConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: authConstants.LOGIN_FAILURE, error: 'Username or Password incorrect' };
  }
}

function loginNoRedirect(user, password) {
  return dispatch => {
    dispatch(request({ user }));
    authService.login(user, password).then(
      user => {
        dispatch(success(user));
        toastr.success('Thanks for logging in!');
      },
      error => {
        dispatch(failure(error));
      }
    );
  };

  function request(user) {
    return { type: authConstants.LOGIN_NO_REDIRECT_REQUEST, user };
  }
  function success(user) {
    return { type: authConstants.LOGIN_NO_REDIRECT_SUCCESS, user };
  }
  function failure(error) {
    return { type: authConstants.LOGIN_NO_REDIRECT_FAILURE, error };
  }
}
function logout() {
  authService.logout();
  history.push('/');
  return { type: authConstants.LOGOUT };
}
function logoutNoRedirect() {
  authService.logout();
  return { type: authConstants.LOGOUT };
}
