import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Title, LocationFinder } from '../../Shared';
import { locationActions } from '../../_actions';
import { AdminHeader } from '../';
class AddLocation extends React.Component {
  state = { name: '' };
  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.create({ ...this.state });
  };

  render() {
    const { creating } = this.props;
    return (
      <Fragment>
        <Title text="Add Location" />
        <Container>
          <AdminHeader goBack title="Add New Location" />
          <Row>
            <Col>
              <Form onSubmit={this.handleSubmit}>
                <FormGroup>
                  <LocationFinder handleChange={this.handleChange} />
                </FormGroup>
                <FormGroup>
                  <Label for="name">Name</Label>
                  <Input
                    id="name"
                    name="name"
                    value={this.state.name}
                    onChange={this.handleChange}
                    required
                  />
                </FormGroup>
                {creating ? (
                  <Button color="primary">
                    <FontAwesomeIcon icon="spinner" spin /> Create
                  </Button>
                ) : (
                  <Button color="primary">Create</Button>
                )}
              </Form>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { creating } = state.location;
  return { creating };
}

const mapDispatchToProps = {
  create: locationActions.create
};

const connectedAddLocation = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddLocation);
export { connectedAddLocation as AddLocation };
