import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import isEmpty from 'lodash/isEmpty';
import { ToolTips, ShadowScrollbars, LeaderboardResult } from '.';

export function LeaderboardCard(props) {
  const { data, title, tip, showTooltip, className } = props;

  return (
    <div className={`leaderboard ${className}`}>
      <div className="title">
        {title} Leaderboard {showTooltip && <ToolTips title={title} tip={tip} />}
      </div>

      <div className="results">
        {!isEmpty(data) ? (
          <Fragment>
            <table className="table table-sm">
              <thead>
                <tr>
                  <th style={{ paddingLeft: `1.25rem` }}>Competitor</th>
                  <th />
                  <th className="text-right">Time</th>
                </tr>
              </thead>
            </table>
            <ShadowScrollbars>
              <table className="table table-sm">
                <tbody>
                  {data.map((res, idx) => (
                    <LeaderboardResult key={idx} place={idx + 1} result={res} />
                  ))}
                </tbody>
              </table>
            </ShadowScrollbars>
          </Fragment>
        ) : (
          <Fragment>
            {title === 'Comp' ? (
              <div className="no-times">No {title} times recorded</div>
            ) : (
              <Button
                tag={Link}
                to={'./RecordTime'}
                color="link"
                style={{ textDecoration: 'underline' }}
              >
                Quick! Nobody has set a time, be the first to set the standard.
              </Button>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
}
