import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { accountActions } from '../_actions';

const btnStyles = {
  position: 'absolute',
  right: '15px',
  bottom: `8px`
};

function SubscribeHandler({ subscribe, unsubscribe, locationId, subscribed }) {
  const callback = subscribed ? unsubscribe : subscribe;
  return (
    <Button color="purple" onClick={() => callback(locationId)} style={btnStyles}>
      <FontAwesomeIcon icon="bell" color="white" /> {subscribed ? 'Unsubscribe' : 'Subscribe'}
    </Button>
  );
}

function PromptLogin({ path }) {
  return (
    <Button
      color="purple"
      style={btnStyles}
      tag={Link}
      to={{ pathname: '/Account/Login', state: { from: `/Locations/View/${path}` } }}
    >
      <FontAwesomeIcon icon="user" /> Login
    </Button>
  );
}
function EventSubscription(props) {
  const { user, getSubscriptions, location, subscriptions, subscribe, unsubscribe } = props;
  const locationId = location.id;

  useEffect(() => {
    if (user) {
      getSubscriptions();
    }
  }, [getSubscriptions, user]);

  if (!user) {
    return <PromptLogin path={location.path} />;
  }

  const subscribed = subscriptions.find(s => s === locationId);

  return (
    <SubscribeHandler
      subscribe={subscribe}
      unsubscribe={unsubscribe}
      subscribed={subscribed}
      locationId={locationId}
    />
  );
}

function mapStateToProps(state) {
  const { subscriptions } = state.account;
  return { subscriptions };
}

const mapDispatch = {
  subscribe: accountActions.subscribe,
  unsubscribe: accountActions.unsubscribe,
  getSubscriptions: accountActions.getLocationSubscriptions
};

const connectedEventSubscription = connect(
  mapStateToProps,
  mapDispatch
)(EventSubscription);
export { connectedEventSubscription as EventSubscription };
