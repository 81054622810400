import React from 'react';
import PropTypes from 'prop-types';
import {
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { NavLink as RRNavLink, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function LoggedInNavItems(props) {
  const { handleLogout, navigated } = props;
  return (
    <UncontrolledDropdown nav inNavbar>
      <DropdownToggle nav>
        Account <FontAwesomeIcon icon="caret-down" />
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem>
          <NavLink tag={RRNavLink} to="/Account/MyResults" onClick={navigated}>
            My Results
          </NavLink>
        </DropdownItem>
        <DropdownItem>
          <NavLink tag={RRNavLink} to="/Account/MyAccount" onClick={navigated}>
            My Account
          </NavLink>
        </DropdownItem>
        <DropdownItem>
          <NavLink className="logout" tag={Link} to="/" onClick={handleLogout}>
            Logout
          </NavLink>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}
LoggedInNavItems.propTypes = {
  handleLogout: PropTypes.func.isRequired,
  navigated: PropTypes.func.isRequired
};
