import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Form, FormGroup, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EmailInput, PasswordInput, UsernameInput } from './';
import { MobileInput } from '../Shared';
import { accountActions } from '../_actions';
import { inputValidation } from '../_helpers';

function SignUp(props) {
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [validate, setValidate] = useState({ phoneNumber: null, email: null, password: null });

  const validatePassword = value => {
    const password = inputValidation.password(value);
    setValidate({ ...validate, password });
  };
  const validatePhoneNumber = value => {
    const phoneNumber = inputValidation.mobile(value);
    setValidate({ ...validate, phoneNumber });
  };
  const validateEmail = value => {
    const email = inputValidation.email(value);
    setValidate({ ...validate, email });
  };

  const handleSubmit = e => {
    e.preventDefault();
    props.signUp({ userName, email, password, phoneNumber });
  };

  const { registering, error, message } = props;
  return (
    <Fragment>
      {message && <div style={{ color: '#66be6b' }}>{message}</div>}
      {error && <div style={{ color: 'red' }}>{error}</div>}
      {!message && (
        <Form onSubmit={handleSubmit}>
          <EmailInput
            handleChange={setEmail}
            email={email}
            validateEmail={validateEmail}
            validation={validate.email}
            withTip
          />
          <MobileInput
            handleChange={setPhoneNumber}
            phoneNumber={phoneNumber}
            validatePhoneNumber={validatePhoneNumber}
            validation={validate.phoneNumber}
            withTip
          />
          <UsernameInput handleChange={setUserName} userName={userName} withTip />
          <PasswordInput
            handleChange={setPassword}
            password={password}
            validatePassword={validatePassword}
            validation={validate.password}
          />

          <FormGroup>
            {registering ? (
              <Button block color="primary">
                <FontAwesomeIcon icon="spinner" spin /> Signing Up
              </Button>
            ) : (
              <Button block color="primary">
                Sign Up
              </Button>
            )}
          </FormGroup>
        </Form>
      )}
    </Fragment>
  );
}
const mapDispatch = {
  signUp: accountActions.inlineSignUp
};

function mapStateToProps(state) {
  const { registering, error, message } = state.auth;
  return { registering, error, message };
}

const connectedSignUp = connect(
  mapStateToProps,
  mapDispatch
)(SignUp);
export { connectedSignUp as InlineSignUp };
