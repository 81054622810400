import React from 'react';
import PropTypes from 'prop-types';
import { Col, Card, CardBody, CardFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const iconStyle = {
  position: 'absolute',
  top: `8px`,
  right: `8px`
};

export function UserCard(props) {
  const { user } = props;
  return (
    <Col xs={12} sm={4} md={3} className="mb-2 equipment-card">
      <Card className="shadow h-100">
        {user.disabled && <FontAwesomeIcon style={iconStyle} color="Grey" icon="frown" />}
        <CardBody className="text-center">
          <p className="mb-0">{user.userName} </p>
        </CardBody>
        <CardFooter>
          <div className="d-flex">{props.children}</div>
        </CardFooter>
      </Card>
    </Col>
  );
}

UserCard.propTypes = {
  user: PropTypes.object.isRequired
};
