import React from 'react';
import { FormGroup, Label, Input, FormFeedback, UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function MobileInput(props) {
  const { handleChange, phoneNumber, validatePhoneNumber, validation, withTip = false } = props;
  return (
    <FormGroup>
      <Label for="phoneNumber">
        Phone Number{' '}
        {withTip && <FontAwesomeIcon color="grey" icon="question-circle" id="phone-tooltip" />}
      </Label>
      {withTip && (
        <UncontrolledTooltip placement="top" target="phone-tooltip">
          We'll occasionally send SMS updates, you can opt out at any time.
        </UncontrolledTooltip>
      )}
      <Input
        name="phoneNumber"
        type="tel"
        id="PhoneNumber"
        required
        onChange={e => {
          handleChange(e.target.value);
          validatePhoneNumber(e.target.value);
        }}
        defaultValue={phoneNumber}
        valid={validation}
        invalid={validation !== null && !validation}
        autoComplete="off"
      />
      <FormFeedback invalid>
        Uh oh! Looks like there is an issue with your mobile number. Please input a correct number
        using 04 or +614.
      </FormFeedback>
    </FormGroup>
  );
}
