import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Title, LocationSelect, LoadingCard, DateTimePicker, RichEditor } from '../../Shared';
import { eventActions, locationActions } from '../../_actions';

class EventEdit extends React.Component {
  state = { startTime: null, endTime: null };
  componentDidMount = () => {
    const { path } = this.props.match.params;
    if (path) {
      this.props.getSingle(path);
      this.props.getAll();
    }
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { single } = this.props;
    this.props.update({ ...single, ...this.state });
  };

  render() {
    const { single, loading, updating, locations } = this.props;
    return (
      <Fragment>
        {loading && <LoadingCard />}
        {!loading && single && (
          <Fragment>
            <Title text={`Editing ${single.name}`} />
            <Container className="mt-4">
              <Row>
                <Col>
                  <h3>Editing {single.name}</h3>
                  <Form onSubmit={this.handleSubmit}>
                    <FormGroup>
                      <Label for="name">Name</Label>
                      <Input
                        id="name"
                        name="name"
                        onChange={this.handleChange}
                        defaultValue={single.name}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="content">Content</Label>
                      <RichEditor
                        name="content"
                        handleChange={this.handleChange}
                        initialState={single.content}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="locationId">Location</Label>
                      {locations && (
                        <LocationSelect
                          id={single.locationId}
                          allLocations={locations}
                          handleChange={this.handleChange}
                          defaultValue={single.locationId}
                        />
                      )}
                    </FormGroup>
                    <Row form>
                      <Col>
                        <FormGroup>
                          <Label for="startTime">Start Time</Label>
                          <DateTimePicker
                            name="startTime"
                            defaultValue={new Date(single.startTime)}
                            value={this.state.startTime}
                            handleChange={this.handleChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label for="endTime">End Time</Label>
                          <DateTimePicker
                            name="endTime"
                            defaultValue={new Date(single.endTime)}
                            value={this.state.endTime}
                            handleChange={this.handleChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    {updating ? (
                      <Button color="primary">
                        <FontAwesomeIcon icon="spinner" spin /> Saving
                      </Button>
                    ) : (
                      <Button color="primary">Save Changes</Button>
                    )}
                  </Form>
                </Col>
              </Row>
            </Container>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

const mapDispatch = {
  getSingle: eventActions.getSingle,
  getAll: locationActions.getAll,
  update: eventActions.update
};

function mapStateToProps(state) {
  const { single, loading, updating } = state.event;
  const { locations } = state.location;
  return { single, loading, updating, locations };
}

const connectedEventEdit = connect(
  mapStateToProps,
  mapDispatch
)(EventEdit);
export { connectedEventEdit as EventEdit };
