import React from 'react';
import { Button } from 'reactstrap';
import { date as dh } from '../../_helpers';

export function LocationTimeRow(props) {
  const { idx, locTime, handleDelete } = props;
  return (
      <tr key={idx}>
        <th scope="row">{locTime.user.userName}</th>
        <td>{locTime.tsDuration}</td>
        <td>{dh.getShortDate(new Date(locTime.timeStarted))}</td>
        <td>{locTime.officialTime ? 'Official Time' : 'Unofficial Time'}</td>
        <Button className="w-100 edit" color="danger" onClick={() => handleDelete(locTime.id)}>
          Delete
        </Button>
      </tr>
  );
}
