import { accountConstants } from '../_constants';

export function account(state = { myAccount: {}, subscriptions: [] }, action) {
  switch (action.type) {
    case accountConstants.CONFIRM_EMAIL_REQUEST:
    case accountConstants.RESET_PASSWORD_REQUEST:
    case accountConstants.FORGOT_PASSWORD_REQUEST:
    case accountConstants.VALIDATE_PHONENUMBER_REQUEST:
      return {
        ...state,
        requesting: true,
        message: null,
        error: null
      };
    case accountConstants.SIGN_UP_INLINE_REQUEST:
    case accountConstants.SIGN_UP_REQUEST:
      return {
        ...state,
        registering: true,
        error: null,
        message: null
      };
    case accountConstants.SIGN_UP_INLINE_FAILURE:
    case accountConstants.SIGN_UP_FAILURE:
      return {
        ...state,
        registering: false,
        error: action.error
      };
    case accountConstants.SIGN_UP_INLINE_SUCCESS:
    case accountConstants.SIGN_UP_SUCCESS:
      return {
        ...state,
        registering: false,
        message: action.data,
        didRegister: true
      };

    case accountConstants.VALIDATE_PHONENUMBER_SUCCESS:
      const { myAccount } = state;
      myAccount.phoneNumberConfirmed = true;
      return {
        ...state,
        message: action.data,
        requesting: false,
        myAccount: { ...myAccount }
      };
    case accountConstants.RESET_PASSWORD_SUCCESS:
    case accountConstants.FORGOT_PASSWORD_SUCCESS:
    case accountConstants.CONFIRM_EMAIL_SUCCESS:
      return {
        ...state,
        message: action.data,
        requesting: false,
        myAccount: { ...myAccount }
      };
    case accountConstants.CONFIRM_EMAIL_FAILURE:
    case accountConstants.RESET_PASSWORD_FAILURE:
    case accountConstants.FORGOT_PASSWORD_FAILURE:
    case accountConstants.VALIDATE_PHONENUMBER_FAILURE:
      return {
        ...state,
        error: action.error,
        requesting: false
      };

    case accountConstants.UPDATE_MY_ACCOUNT_SUCCESS:
    case accountConstants.MY_ACCOUNT_SUCCESS:
      return {
        ...state,
        myAccount: action.data
      };
    case accountConstants.SUBSCRIBE_TO_EVENT_REQUEST: {
      const { subscriptions } = state;
      subscriptions.push(action.data);
      return {
        ...state,
        subscribing: true,
        subscriptions: [...subscriptions]
      };
    }
    case accountConstants.LOCATION_UNSUBSCRIBE_REQUEST: {
      const { subscriptions } = state;
      const idx = subscriptions.indexOf(action.data);
      subscriptions.splice(idx, 1);
      return {
        ...state,
        subscriptions: [...subscriptions]
      };
    }
    case accountConstants.SUBSCRIBE_TO_EVENT_SUCCESS:
      return {
        ...state,
        subscribing: false,
        subscribed: true
      };
    case accountConstants.SUBSCRIBE_TO_EVENT_FAILURE:
      return {
        ...state,
        error: true,
        subscribing: false,
        subscribed: false
      };
    case accountConstants.GET_LOCATION_SUBSCRIPTIONS_REQUEST:
      return {
        ...state,
        getSubscriptions: true,
        getSubscriptionsError: false
      };
    case accountConstants.GET_LOCATION_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        subscriptions: action.data,
        getSubscriptions: true,
        getSubscriptionsError: false
      };
    case accountConstants.GET_LOCATION_SUBSCRIPTIONS_FAILURE:
      return {
        ...state,
        getSubscriptions: false,
        getSubscriptionsError: true,
        error: action.error
      };
    case accountConstants.UPLOAD_PHOTO_SUCCESS: {
      const { myAccount } = state;
      myAccount.current = action.data;
      return {
        ...state,
        myAccount: { ...myAccount }
      };
    }
    case accountConstants.UPLOAD_PHOTO_REQUEST:
    case accountConstants.UPLOAD_PHOTO_FAILURE:
    case accountConstants.UPDATE_MY_ACCOUNT_REQUEST:
    case accountConstants.MY_ACCOUNT_REQUEST:
    case accountConstants.MY_ACCOUNT_FAILURE:
    case accountConstants.UPDATE_MY_ACCOUNT_FAILURE:
    default:
      return state;
  }
}
