export const inputValidation = {
  email,
  password,
  firstName,
  lastName,
  userName,
  mobile
};

function email(email) {
  const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRex.test(email);
}

function password(password) {
  const re = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.{6,})/g;
  return re.test(password);
}

function firstName(value) {
  return value.length >= 2;
}

function mobile(mobile) {
  return (
    (mobile.startsWith('+614') && mobile.length === 12) ||
    (mobile.startsWith('04') && mobile.length === 10)
  );
}

function lastName(value) {
  return value.length >= 2;
}

function userName(value) {
  return value.length >= 2;
}
