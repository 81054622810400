import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';
/**
 * For use with Hooks only
 */
export function PasswordInput(props) {
  const { handleChange, password, validatePassword, validation } = props;
  return (
    <FormGroup>
      <Label for="password">Password</Label>
      <Input
        name="password"
        id="password"
        type="password"
        onChange={e => {
          handleChange(e.target.value);
          validatePassword(e.target.value);
        }}
        value={password}
        valid={validation}
        invalid={validation !== null && !validation}
      />

      <FormFeedback invalid>
        Password at a minimum must contain at least six characters, one uppercase, one lowercase,
        one number
      </FormFeedback>
    </FormGroup>
  );
}

PasswordInput.propTypes = {
  handleChange: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
  validatePassword: PropTypes.func.isRequired,
  validation: PropTypes.bool
};
