import React, { Fragment } from 'react';
import { Container, Row } from 'reactstrap';
import { Title, PanelCard } from '../Shared';
import { RefereeHeader } from './';

export function Referee() {
  return (
    <Fragment>
      <Title text="Referee Tools" />
      <Container>
        <RefereeHeader title="Referee Tools" />
        <Row>
          <PanelCard url="/Referee/RecordTimes" icon={['far', 'hourglass']}>
            <p className="mb-0">Record Times</p>
          </PanelCard>
          <PanelCard icon="user-plus" url="/Referee/AddUser">
            <p className="mb-0">Add User</p>
          </PanelCard>
        </Row>
      </Container>
    </Fragment>
  );
}
