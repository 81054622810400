import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { iconSet } from './components/_helpers';
import { library } from '@fortawesome/fontawesome-svg-core';
import { configureStore, history } from './components/_helpers';
import App from './components/App';
import { NProgress } from 'redux-nprogress';
import ReduxToastr from 'react-redux-toastr';
import * as serviceWorker from './serviceWorker';
import { contentActions } from './components/_actions';
import './index.scss';
library.add(...iconSet);

const store = configureStore();

store.dispatch(contentActions.allBySlug());

const render = Component => {
  return ReactDOM.render(
    <Provider store={store}>
      <Router history={history}>
        <div>
          <ReduxToastr />
          <NProgress />
          <Component />
        </div>
      </Router>
    </Provider>,
    document.getElementById('root')
  );
};
render(App);

if (module.hot) {
  module.hot.accept('./components/App', () => {
    const NextApp = require('./components/App').default;
    render(NextApp);
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
