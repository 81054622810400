import React, { Fragment, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
import { LoginSignUpSwitch } from '../Account';

function zeroPad(num) {
  return num <= 9 ? `0${num}` : num;
}

function msToTime(miliseconds) {
  let remaining = miliseconds / 1000;
  remaining %= 3600;
  const mm = parseInt(remaining / 60, 10);
  const ss = parseInt(remaining % 60, 10);
  const S = parseInt((miliseconds % 1000) / 10, 10);
  return {
    mm: zeroPad(mm),
    ss: zeroPad(ss),
    S: zeroPad(S)
  };
}

export function Stopwatch(props) {
  const [running, setRunning] = useState(false);
  const [runningTime, setRunningTime] = useState(0);
  const [timer, setTimer] = useState(null);
  const [timeStarted, setTimeStarted] = useState(null);
  const [timeCompleted, setTimeCompleted] = useState(null);
  const [isReset, setReset] = useState(false);
  const [saving, setSaving] = useState(false);
  const [timeStopped, setTimeStopped] = useState(false);

  const { timeSaved, didRegister, saveTime, user, title = 'Record your Time' } = props;

  useEffect(() => {
    return () => {
      clearInterval(timer);
    };
  }, [timer]);

  useEffect(() => {
    if (timeSaved) {
      setRunning(false);
      setRunningTime(0);
      setTimer(null);
      setTimeStarted(null);
      setTimeCompleted(null);
      setTimeStopped(false);
      setReset(false);
      setSaving(false);
    }
  }, [timeSaved]);

  const save = () => {
    if (!saving) {
      setSaving(true);
      saveTime({ timeStarted, timeCompleted, runningTime });
    }
  };

  const stop = () => {
    // the time completed has to be created as soon as the timer is stopped as
    // otherwise, due to the way react handles changes to state, it will not be
    // the correct time
    const tc = new Date();
    clearInterval(timer);
    setTimeCompleted(tc);
    setTimeStopped(true);
    setRunning(false);
  };

  const reset = () => {
    clearInterval(timer);
    setRunning(false);
    setRunningTime(0);
    setReset(true);
    setTimeStopped(false);
  };

  const start = () => {
    setRunning(true);
    setTimeStarted(new Date());
    const startTime = Date.now() - runningTime;
    setTimer(
      setInterval(() => {
        setRunningTime(Date.now() - startTime);
      })
    );
    setReset(false);
  };
  const time = msToTime(runningTime);
  return (
    <Fragment>
      <h4>{title}</h4>
      <div className="stopwatch">
        <div className="timer d-flex justify-content-center">
          {timer ? (
            <Fragment>
              <div style={{ width: `100px` }}>{time.mm}</div>
              <span className="sep">:</span>
              <div style={{ width: `100px` }}>{time.ss}</div>
              <span className="sep">.</span>
              <div style={{ width: `100px` }}>{time.S}</div>
            </Fragment>
          ) : (
            '00:00.00'
          )}
        </div>
        <div className="controls">
          <Button size="lg" color="success" onClick={start} disabled={running || timeStopped}>
            <FontAwesomeIcon icon="play" color="white" />
          </Button>
          <Button size="lg" color="danger" onClick={stop} disabled={!running}>
            <FontAwesomeIcon icon="stop" color="white" />
          </Button>
          <Button size="lg" color="warning" onClick={reset}>
            <FontAwesomeIcon icon="undo" color="white" />
          </Button>
        </div>

        {user && !running && !isReset && timer && (
          <div className="save-time">
            <Button color="info" onClick={() => save()}>
              Save Time
            </Button>
          </div>
        )}
      </div>
      <div className="w-100">
        {!user && !running && timer && <LoginSignUpSwitch didRegister={didRegister} />}
      </div>
    </Fragment>
  );
}
