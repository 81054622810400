import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

export function ViewEditFooter(props) {
  return (
    <Fragment>
      <Button
        tag={Link}
        to={`/Admin/${props.urlPath}/Single/${props.path}`}
        className="w-100 view"
        color="primary"
      >
        View
      </Button>
      <Button
        tag={Link}
        to={`/Admin/${props.urlPath}/Edit/${props.path}`}
        className="w-100 edit"
        color="secondary"
      >
        Edit
      </Button>
      {props.showTimes && (
        <Button
          tag={Link}
          to={`/Admin/${props.urlPath}/Time/${props.path}`}
          className="w-100 edit"
          color="warning"
        >
          Times
        </Button>
      )}
    </Fragment>
  );
}
ViewEditFooter.propTypes = {
  urlPath: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired
};
