import React from 'react';
import { InputGroup, InputGroupAddon, CustomInput, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function LocationFilter(props) {
  const { saveLocation, handleChange, states } = props;

  function lookup() {
    navigator.geolocation.getCurrentPosition(
      position => {
        saveLocation(position.coords);
      },
      error => {
        console.log(error);
      },
      { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 }
    );
  }
  return (
    <div className="geolocation">
      <InputGroup>
        <CustomInput name="terms" onChange={handleChange} type="select">
          <option value="">Select a State</option>
          {states &&
            states.map((s, idx) => (
              <option key={idx} value={s}>
                {s}
              </option>
            ))}
        </CustomInput>
        <InputGroupAddon addonType="prepend">
          <Button color="primary" onClick={lookup}>
            <FontAwesomeIcon icon="location-arrow" />
          </Button>
        </InputGroupAddon>
      </InputGroup>
    </div>
  );
}
