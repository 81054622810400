export const colors = {
  hex: {
    blue: '#009ad9',
    gray800: '#404042',
    gray600: '#939597',
    green: '#66be6b',
    red: '#f3725a'
  },
  rgb: {
    blue: 'rgb(0, 154, 217)',
    gray800: 'rgb(64, 64, 66)',
    gray600: 'rgb(147, 149, 151)',
    green: 'rgb(102, 190, 107)',
    red: 'rgb(243, 114, 90)'
  }
};
