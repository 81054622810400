import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function ToolTips(props) {
  const { tip, title } = props;
  return (
    <Fragment>
      <FontAwesomeIcon color="grey" icon="question-circle" id={title} />
      <UncontrolledTooltip placement="top" target={title}>
        {tip}
      </UncontrolledTooltip>
    </Fragment>
  );
}
ToolTips.propTypes = {
  tip: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};
