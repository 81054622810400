import { contentConstants } from '../_constants';
import { contentService } from '../_services';
import { history } from '../_helpers';
export const contentActions = {
  getAll,
  add,
  update,
  getSections,
  sectionHasContent,
  bySectionSlug,
  allBySlug
};

function getAll() {
  return dispatch => {
    dispatch(request());
    contentService.getAll().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: contentConstants.GET_CONTENT_REQUEST };
  }
  function success(data) {
    return { type: contentConstants.GET_CONTENT_SUCCESS, data };
  }
  function failure(error) {
    return { type: contentConstants.GET_CONTENT_FAILURE, error };
  }
}

function getSections() {
  return dispatch => {
    dispatch(request());
    contentService.getSections().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: contentConstants.GET_CONTENT_SECTIONS_REQUEST };
  }
  function success(data) {
    return { type: contentConstants.GET_CONTENT_SECTIONS_SUCCESS, data };
  }
  function failure(error) {
    return { type: contentConstants.GET_CONTENT_SECTIONS_FAILURE, error };
  }
}

function add(content) {
  return dispatch => {
    dispatch(request(content));
    contentService.add(content).then(
      data => {
        dispatch(success(data));
        history.push(`/Admin/Content/Edit/${data.contentSectionId}`);
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: contentConstants.ADD_CONTENT_REQUEST, data };
  }
  function success(data) {
    return { type: contentConstants.ADD_CONTENT_SUCCESS, data };
  }
  function failure(error) {
    return { type: contentConstants.ADD_CONTENT_FAILURE, error };
  }
}

function update(content) {
  return dispatch => {
    dispatch(request(content));
    contentService.update(content).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: contentConstants.UPDATE_CONTENT_REQUEST, data };
  }
  function success(data) {
    return { type: contentConstants.UPDATE_CONTENT_SUCCESS, data };
  }
  function failure(error) {
    return { type: contentConstants.UPDATE_CONTENT_FAILURE, error };
  }
}

function sectionHasContent(sectionId) {
  return dispatch => {
    dispatch(request(sectionId));
    contentService.sectionHasContent(sectionId).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: contentConstants.SECTION_HAS_CONTENT_REQUEST, data };
  }
  function success(data) {
    return { type: contentConstants.SECTION_HAS_CONTENT_SUCCESS, data };
  }
  function failure(error) {
    return { type: contentConstants.SECTION_HAS_CONTENT_FAILURE, error };
  }
}

function bySectionSlug(slug) {
  return dispatch => {
    dispatch(request(slug));
    contentService.bySectionSlug(slug).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: contentConstants.GET_CONTENT_BY_SECTION_SLUG_REQUEST, data };
  }
  function success(data) {
    return { type: contentConstants.GET_CONTENT_BY_SECTION_SLUG_SUCCESS, data };
  }
  function failure(error) {
    return { type: contentConstants.GET_CONTENT_BY_SECTION_SLUG_FAILURE, error };
  }
}

function allBySlug() {
  return dispatch => {
    dispatch(request());
    contentService.allBySlug().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: contentConstants.ALL_CONTENT_BY_SLUG_REQUEST };
  }
  function success(data) {
    return { type: contentConstants.ALL_CONTENT_BY_SLUG_SUCCESS, data };
  }
  function failure(error) {
    return { type: contentConstants.ALL_CONTENT_BY_SLUG_FAILURE, error };
  }
}
