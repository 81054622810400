import { feedbackConstants } from '../_constants';
import { feedbackService } from '../_services';
import { toastr } from 'react-redux-toastr';

export const feedbackActions = {
  clearState,
  submit
};

function clearState() {
  return dispatch => {
    dispatch(clear());
  };
  function clear() {
    return { type: feedbackConstants.CLEAR_STATE };
  }
}

function submit(feedback) {
  return dispatch => {
    dispatch(request(feedback));
    feedbackService.submit(feedback).then(
      data => {
        dispatch(success(data));
        toastr.success('Feedback submitted, Thankyou!');
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: feedbackConstants.CREATE_FEEDBACK_REQUEST, data };
  }
  function success(data) {
    return { type: feedbackConstants.CREATE_FEEDBACK_SUCCESS, data };
  }
  function failure(error) {
    return { type: feedbackConstants.CREATE_FEEDBACK_FAILURE, error };
  }
}
