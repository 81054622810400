import React, { useState, useEffect, Fragment } from 'react';
import { Nav, NavItem, NavLink, TabPane, TabContent } from 'reactstrap';
import classnames from 'classnames';
import { InlineSignUp, InlineLogin } from '../Account';

export function LoginSignUpSwitch(props) {
  const { didRegister, title = true } = props;
  const [tab, setTab] = useState('login');

  useEffect(() => {
    if (didRegister) {
      setTab('login');
    }
  }, [didRegister]);

  return (
    <Fragment>
      <div className="sign-up">
        {title && <p className="title my-2">Login or Sign up to save your time!</p>}

        <Nav fill tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: tab === 'login' })}
              onClick={() => setTab('login')}
            >
              Login
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: tab === 'sign up' })}
              onClick={() => setTab('sign up')}
            >
              Sign Up
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={tab}>
          <TabPane tabId="login">
            <div className="inline-login-rego">
              <InlineLogin />
            </div>
          </TabPane>
          <TabPane tabId="sign up">
            <div className="inline-login-rego">
              <InlineSignUp />
            </div>
          </TabPane>
        </TabContent>
      </div>
    </Fragment>
  );
}
