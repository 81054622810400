import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input, FormFeedback, UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/**
 * For use with Hooks only.
 */
export function EmailInput(props) {
  const { handleChange, email, validateEmail, validation, withTip = false } = props;

  return (
    <FormGroup>
      <Label for="email">
        Email{' '}
        {withTip && <FontAwesomeIcon color="grey" icon="question-circle" id="email-tooltip" />}
      </Label>
      {withTip && (
        <UncontrolledTooltip placement="top" target="email-tooltip">
          We'll send you updates from time to time, you can opt-out any time.
        </UncontrolledTooltip>
      )}

      <Input
        name="email"
        type="email"
        id="email"
        required
        onChange={e => {
          handleChange(e.target.value);
          validateEmail(e.target.value);
        }}
        defaultValue={email}
        valid={validation}
        invalid={validation !== null && !validation}
        autoComplete="off"
      />

      <FormFeedback invalid>
        Uh oh! Looks like there is an issue with your email. Please input a correct email.
      </FormFeedback>
    </FormGroup>
  );
}

EmailInput.propTypes = {
  handleChange: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  validateEmail: PropTypes.func.isRequired,
  validation: PropTypes.bool
};
