export const leaderboardConstants = {
  GET_LEADERBOARD_REQUEST: 'GET_LEADERBOARD_REQUEST',
  GET_LEADERBOARD_SUCCESS: 'GET_LEADERBOARD_SUCCESS',
  GET_LEADERBOARD_FAILURE: 'GET_LEADERBOARD_FAILURE',
  GET_USER_RESULTS_REQUEST: 'GET_USER_RESULTS__REQUEST',
  GET_USER_RESULTS_SUCCESS: 'GET_USER_RESULTS__SUCCESS',
  GET_USER_RESULTS_FAILURE: 'GET_USER_RESULTS__FAILURE',
  GET_LEADERBOARD_OVERVIEW_REQUEST: 'GET_LEADERBOARD_OVERVIEW_REQUEST',
  GET_LEADERBOARD_OVERVIEW_SUCCESS: 'GET_LEADERBOARD_OVERVIEW_SUCCESS',
  GET_LEADERBOARD_OVERVIEW_FAILURE: 'GET_LEADERBOARD_OVERVIEW_FAILURE',
  ADD_TO_LEADERBOARD_REQUEST: 'ADD_TO_LEADERBOARD_REQUEST',
  ADD_TO_LEADERBOARD_SUCCESS: 'ADD_TO_LEADERBOARD_SUCCESS',
  ADD_TO_LEADERBOARD_FAILURE: 'ADD_TO_LEADERBOARD_FAILURE',
  MY_RESULTS_REQUEST: 'MY_RESULTS_REQUEST',
  MY_RESULTS_SUCCESS: 'MY_RESULTS_SUCCESS',
  MY_RESULTS_FAILURE: 'MY_RESULTS_FAILURE'
};
