import {
  faBellSlash,
  faCalendarAlt,
  faCalendarDay,
  faCalendarMinus,
  faCheck,
  faCheckCircle,
  faMapMarkedAlt,
  faMapSigns,
  faQuestionCircle,
  faRunning,
  faSpinner,
  faToolbox,
  faTools,
  faTrash,
  faTrashAlt,
  faUser,
  faUserEdit,
  faUserPlus,
  faUserMinus,
  faUsersCog,
  faLocationArrow,
  faImage,
  faPencilAlt,
  faPlay,
  faStop,
  faFrown,
  faTimes,
  faBell,
  faUndo,
  faCaretDown,
  faPlus,
  faSearchPlus,
  faSearchMinus
} from '@fortawesome/free-solid-svg-icons';
import { faFacebookSquare, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faHourglass, faNewspaper, faEdit, faSave } from '@fortawesome/free-regular-svg-icons';
export const iconSet = [
  faBellSlash,
  faEdit,
  faCalendarAlt,
  faPencilAlt,
  faFacebookSquare,
  faInstagram,
  faNewspaper,
  faImage,
  faCalendarDay,
  faTimes,
  faCalendarMinus,
  faHourglass,
  faCaretDown,
  faSave,
  faPlus,
  faSearchPlus,
  faSearchMinus,
  faLocationArrow,
  faCheck,
  faHourglass,
  faCheckCircle,
  faMapMarkedAlt,
  faMapSigns,
  faQuestionCircle,
  faRunning,
  faSpinner,
  faFrown,
  faToolbox,
  faTools,
  faTrash,
  faTrashAlt,
  faUser,
  faUserEdit,
  faPlay,
  faStop,
  faUndo,
  faUserPlus,
  faUserMinus,
  faUsersCog,
  faBell
];
