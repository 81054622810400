import { eventConstants } from '../_constants';

export function event(state = {}, action) {
  switch (action.type) {
    case eventConstants.CREATE_EVENT_REQUEST:
      return {
        ...state,
        creating: true
      };
    case eventConstants.CREATE_EVENT_SUCCESS:
      const { events = [] } = state;
      events.push(action.data);
      return {
        ...state,
        creating: false,
        events
      };
    case eventConstants.CREATE_EVENT_FAILURE:
      return {
        ...state,
        creating: false,
        error: true
      };
    case eventConstants.GET_UPCOMING_EVENTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: false
      };
    case eventConstants.GET_UPCOMING_EVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        events: action.data.events,
        upcoming: action.data
      };
    case eventConstants.GET_PAST_EVENTS_SUCCESS:
      return {
        ...state,
        past: action.data
      };
    case eventConstants.LIST_EVENT_FAILURE:
    case eventConstants.GET_UPCOMING_EVENTS_FAILURE:
    case eventConstants.GET_PAST_EVENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };
    case eventConstants.GET_EVENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case eventConstants.GET_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        single: action.data
      };
    case eventConstants.GET_EVENT_FAILURE:
      return {
        ...state,
        loading: false
      };
    case eventConstants.LIST_EVENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case eventConstants.LIST_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        events: action.data
      };
    case eventConstants.UPDATE_EVENT_REQUEST:
      return {
        ...state,
        updating: true
      };
    case eventConstants.UPDATE_EVENT_SUCCESS:
      return {
        ...state,
        updating: false,
        single: action.data
      };
    case eventConstants.UPDATE_EVENT_FAILURE:
      return {
        ...state,
        updating: false
      };
    case eventConstants.DELETE_EVENT_REQUEST:
      return {
        ...state,
        delete: true
      };
    case eventConstants.DELETE_EVENT_SUCCESS: {
      const events = state.events.filter(e => e.id !== action.data);
      return {
        ...state,
        events
      };
    }
    case eventConstants.DELETE_EVENT_FAILURE:
      return {
        ...state,
        delete: false
      };
    case eventConstants.FILTER_EVENTS: {
      const filtered = state.events.filter(e => e.name.indexOf(action.data) >= 0);
      return {
        ...state,
        filtering: true,
        filtered
      };
    }
    case eventConstants.CLEAR_FILTER:
      return {
        ...state,
        filtering: false,
        filtered: null
      };
    default:
      return state;
  }
}
