import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Button
} from 'reactstrap';
import { RefereeHeader } from './';
import { Title } from '../Shared';
import { useAddUserForm } from '../_hooks';
import { refereeActions } from '../_actions';

function AddUser(props) {
  const { createUser, success, creating } = props;
  const { handleChange, handleSubmit, values, errors } = useAddUserForm(createUser, success);

  return (
    <Fragment>
      <Title text="Create a New User" />
      <Container>
        <RefereeHeader title="Add User" goBack />
        <Row>
          <Col>
            <h4>Add new User</h4>
            <Form onSubmit={handleSubmit}>
              <FormGroup row>
                <Col xs={12} md={6}>
                  <Label for="firstName">First Name</Label>
                  <Input
                    id="firstName"
                    name="firstName"
                    value={values.firstName}
                    onChange={handleChange}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Label for="lastName">Last Name</Label>
                  <Input
                    id="lastName"
                    name="lastName"
                    value={values.lastName}
                    onChange={handleChange}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col xs={12} md={6}>
                  <Label for="userName">User Name</Label>
                  <Input
                    id="userName"
                    name="userName"
                    value={values.userName}
                    onChange={handleChange}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Label for="email">Email</Label>
                  <Input
                    id="email"
                    name="email"
                    type="email"
                    value={values.email}
                    onChange={handleChange}
                    valid={errors.email}
                    invalid={errors.email !== null && !errors.email}
                  />
                  <FormFeedback invalid>The Email provided is currently invalid.</FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col xs={12} md={6}>
                  <Label for="phoneNumber">Mobile Number</Label>
                  <Input
                    id="phoneNumber"
                    name="phoneNumber"
                    type="text"
                    value={values.phoneNumber}
                    onChange={handleChange}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Label for="password">Password</Label>
                  <Input
                    id="password"
                    name="password"
                    type="password"
                    value={values.password}
                    onChange={handleChange}
                    valid={errors.password}
                    invalid={errors.password !== null && !errors.password}
                  />
                  <FormFeedback invalid>
                    This password is invalid, ensure it contains lower and uppercase text, symbols,
                    numbers and is 8 characters long.
                  </FormFeedback>
                </Col>
              </FormGroup>
              {creating ? (
                <Button color="primary">Creating</Button>
              ) : (
                <Button color="primary">Submit</Button>
              )}
            </Form>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}
function mapState(state) {
  const { success, creating } = state.referee;
  return { success, creating };
}
const mapDispatch = {
  createUser: refereeActions.createUser
};
const connectedAddUser = connect(
  mapState,
  mapDispatch
)(AddUser);
export { connectedAddUser as AddUser };
