import { locationConstants } from '../_constants';

export function location(state = {}, action) {
  switch (action.type) {
    case locationConstants.LOCATIONS_BY_DISTANCE_SUCCESS:
      return {
        ...state,
        locations: action.data
      };
    case locationConstants.CREATE_LOCATION_REQUEST:
      return {
        ...state,
        creating: true
      };
    case locationConstants.LOCATIONS_BY_DISTANCE_REQUEST:
    case locationConstants.LIST_STATE_REQUEST:
    case locationConstants.GET_LOCATIONS_AND_STATES_REQUEST:
    case locationConstants.LIST_LOCATION_REQUEST:
    case locationConstants.GET_LOCATION_REQUEST:
    case locationConstants.LIST_LOCATION_TIMES_REQUEST:
      return {
        ...state,
        loading: true,
        error: false
      };
    case locationConstants.GET_LOCATIONS_AND_STATES_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.data
      };
    case locationConstants.GET_LOCATIONS_AND_STATES_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };
    case locationConstants.CREATE_LOCATION_SUCCESS: {
      const { locations = [] } = state;
      locations.push(action.data);
      return {
        ...state,
        creating: false,
        locations
      };
    }
    case locationConstants.CREATE_LOCATION_FAILURE:
      return {
        ...state,
        creating: false,
        error: true
      };
    case locationConstants.LIST_LOCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        locations: action.data
      };
    case locationConstants.LIST_LOCATION_TIMES_SUCCESS:
      return {
        ...state,
        loading: false,
        times: action.data
      };
    case locationConstants.LIST_LOCATION_FAILURE:
    case locationConstants.LIST_LOCATION_TIMES_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };
    case locationConstants.LIST_STATE_SUCCESS:
      return {
        ...state,
        loading: false,
        allStates: action.data
      };
    case locationConstants.LOCATIONS_BY_DISTANCE_FAILURE:
    case locationConstants.LIST_STATE_FAILURE:
      return {
        ...state,
        loading: false
      };
    case locationConstants.GET_LOCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        single: action.data
      };
    case locationConstants.GET_LOCATION_FAILURE:
      return {
        ...state,
        loading: false
      };
    case locationConstants.UPDATE_LOCATION_REQUEST:
      return {
        ...state,
        updating: true
      };
    case locationConstants.UPDATE_LOCATION_SUCCESS:
      return {
        ...state,
        updating: false,
        single: action.data
      };
    case locationConstants.UPDATE_LOCATION_FAILURE:
      return {
        ...state,
        updating: false
      };
    case locationConstants.DISABLE_LOCATION_REQUEST:
      return {
        ...state,
        disable: true
      };
    case locationConstants.DISABLE_LOCATION_SUCCESS: {
      const locations = state.locations.filter(e => e.id !== action.data);
      return {
        ...state,
        locations,
        disable: true
      };
    }
    case locationConstants.DISABLE_LOCATION_FAILURE:
      return {
        ...state,
        disable: false
      };
    case locationConstants.DELETE_TIME_REQUEST:
      return {
        ...state,
        delete: true
      };
    case locationConstants.DELETE_TIME_SUCCESS:
      const times = state.times.filter(e => e.id !== action.data);
      return {
        ...state,
        delete: true,
        times
      };
    case locationConstants.DELETE_TIME_FAILURE:
      return {
        ...state,
        delete: false
      };
    case locationConstants.FILTER_LOCATIONS:
      const filtered = state.locations.filter(l => l.state === action.terms);
      return {
        ...state,
        filtering: true,
        filtered
      };
    case locationConstants.CLEAR_FILTER:
      return {
        ...state,
        filtering: false,
        filtered: null
      };
    default:
      return state;
  }
}
