import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';

export function ObstaclesRules() {
  return (
    <Container className="mt-4">
      <Row>
        <Col xs={12}>
          <h4>The Obstacles and Rules</h4>
          <p>
            Each Park Warrior course contains a set of obstacles. The number of obstacles at each
            course varies and each obstacle must be completed within the rules to obtain a valid
            time.
          </p>
        </Col>
        <Col xs={12} sm={4} className="my-2 loc-equip">
          <div className="card">
            <img
              src="https://pwassets.blknd.co/equipment/web-wall.jpg"
              className="img-fluid"
              alt="Web Wall"
            />
            <div className="eq-label">Web Wall</div>
          </div>
        </Col>
        <Col xs={12} sm={4} className="my-2 loc-equip">
          <div className="card">
            <img
              src="https://pwassets.blknd.co/equipment/jungle-bars.jpg"
              className="img-fluid"
              alt="Jungle Bars"
            />
            <div className="eq-label">Jungle Bars</div>
          </div>
        </Col>
        <Col xs={12} sm={4} className="my-2 loc-equip">
          <div className="card">
            <img
              src="https://pwassets.blknd.co/equipment/field-of-rings-alt-2.jpg"
              className="img-fluid"
              alt="Field of Rings "
            />
            <div className="eq-label">Field of Rings</div>
          </div>
        </Col>
        <Col>
          <p>
            <Link to="/Obstacles">View the full list of Obstacles »</Link>
          </p>
        </Col>
      </Row>
    </Container>
  );
}
