import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { Title, LoadingCard, MapEmbed, FullscreenModal, PageHeader, Stopwatch } from '../Shared';
import { EquipmentCard } from '../Equipment';
import { locationActions, locationEquipmentActions, leaderboardActions } from '../_actions';
import isEmpty from 'lodash/isEmpty';
import { LeaderboardRow } from '../Leaderboard';

class LocationSingle extends React.Component {
  state = { isOpen: false, equipmentId: null };
  toggle = (e, id) => {
    if (id) {
      this.setState({ equipmentId: id });
    }
    this.setState({ isOpen: !this.state.isOpen });
  };

  saveTime = data => {
    const locationId = this.props.single.id;
    const { timeStarted, timeCompleted, runningTime } = data;
    this.props.addToLeaderboard({ locationId, timeStarted, timeCompleted, duration: runningTime });
  };

  equipmentIndex = () => {
    const { equipment } = this.props;
    const { equipmentId } = this.state;
    return { index: equipment.findIndex(e => e.id === equipmentId), arrLen: equipment.length - 1 };
  };

  next = () => {
    const res = this.equipmentIndex();
    let next;
    if (res.index === res.arrLen) {
      next = this.props.equipment[0];
    } else {
      next = this.props.equipment[res.index + 1];
    }
    this.setState({ equipmentId: next.id });
  };

  prev = () => {
    const res = this.equipmentIndex();
    let prev;
    if (res.index === 0) {
      prev = this.props.equipment[res.arrLen];
    } else {
      prev = this.props.equipment[res.index - 1];
    }
    this.setState({ equipmentId: prev.id });
  };

  componentDidMount = () => {
    const { path } = this.props.match.params;
    if (path) {
      this.props.getLocation(path);
      this.props.equipmentForSite(path);
      this.props.getLeaderboard(path);
    }
  };

  render() {
    const {
      single,
      loading,
      equipment,
      official,
      unofficial,
      user,
      didRegister,
      timeSaved
    } = this.props;
    return (
      <Fragment>
        <Title text={isEmpty(single) ? 'Loading' : single.name} />
        <PageHeader className="locations pb-3">
          <Row>
            {loading && <LoadingCard />}
            {!loading && !isEmpty(single) && (
              <Fragment>
                <Col xs={12}>
                  <h3>Park Warrior @ {single.name}</h3>
                  <p className="lead">{single.location}</p>
                </Col>
                <Col>
                  <MapEmbed
                    lat={single.lat}
                    lng={single.lng}
                    mapElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `195px` }} />}
                  />
                </Col>
              </Fragment>
            )}
          </Row>
        </PageHeader>

        <Container className="mt-4">
          <Row className="mt-2">
            <Col>
              <h4>Obstacles, instructions, and rules for this location</h4>
              {!isEmpty(equipment) && (
                <Row>
                  {equipment.map((equipment, idx) => (
                    <EquipmentCard key={idx} equipment={equipment} toggle={this.toggle} />
                  ))}
                </Row>
              )}
            </Col>
          </Row>
          <hr />
          <Stopwatch
            user={user}
            saveTime={this.saveTime}
            didRegister={didRegister}
            timeSaved={timeSaved}
          />
          <hr />

          {official && <LeaderboardRow official={official} unofficial={unofficial} />}
        </Container>
        <FullscreenModal
          isOpen={this.state.isOpen}
          toggle={this.toggle}
          equipmentId={this.state.equipmentId}
          equipment={equipment}
          next={this.next}
          prev={this.prev}
        />
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  const { didRegister } = state.account;
  const { single, loading } = state.location;
  const { equipment } = state.locationEquipment;
  const { official, unofficial, timeSaved } = state.leaderboard;

  return { single, loading, equipment, official, unofficial, user, didRegister, timeSaved };
}

const mapDispatchToProps = {
  getLocation: locationActions.getSingle,
  addToLeaderboard: leaderboardActions.addToLeaderboard,
  equipmentForSite: locationEquipmentActions.getSiteEquipment,
  getLeaderboard: leaderboardActions.listResults
};
const connectedLocationSingle = connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationSingle);
export { connectedLocationSingle as LocationSingle };
