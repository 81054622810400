import { refereeService } from '../_services';
import { refereeConstants } from '../_constants';
import { toastr } from 'react-redux-toastr';

export const refereeActions = {
  userLookup,
  addToLeaderboard,
  createUser
};

function userLookup(userName) {
  return dispatch => {
    dispatch(request(userName));
    refereeService.userLookup(userName).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: refereeConstants.GET_USER_REQUEST, data };
  }
  function success(data) {
    return { type: refereeConstants.GET_USER_SUCCESS, data };
  }
  function failure(error) {
    return { type: refereeConstants.GET_USER_FAILURE, error };
  }
}

function addToLeaderboard(result) {
  return dispatch => {
    dispatch(request(result));
    refereeService.addToLeaderboard(result).then(
      data => {
        dispatch(success(data));
        toastr.success('Time successfully recorded');
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: refereeConstants.ADD_TIME_FOR_USER_REQUEST, data };
  }
  function success(data) {
    return { type: refereeConstants.ADD_TIME_FOR_USER_SUCCESS, data };
  }
  function failure(error) {
    return { type: refereeConstants.ADD_TIME_FOR_USER_FAILURE, error };
  }
}

function createUser(newUser) {
  return dispatch => {
    dispatch(request(newUser));
    refereeService.createUser(newUser).then(
      data => {
        dispatch(success(data));
        toastr.success('User Added');
      },
      error => {
        dispatch(failure(error));
        toastr.error('Unable to Add User', error);
      }
    );
  };
  function request(data) {
    return { type: refereeConstants.CREATE_USER_REQUEST, data };
  }
  function success(data) {
    return { type: refereeConstants.CREATE_USER_SUCCESS, data };
  }
  function failure(error) {
    return { type: refereeConstants.CREATE_USER_FAILURE, error };
  }
}
