import React from 'react';
import { connect } from 'react-redux';
import { Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { FormWrapper } from './';
import { accountActions } from '../_actions';
import { useForm } from '../_hooks';
import isEmpty from 'lodash/isEmpty';

function ResetPassword(props) {
  const { values, errors, handleChange, handleSubmit } = useForm(login);
  const { requesting, message, error } = props;

  function login() {
    const { code } = props.match.params;
    const { email, password } = values;
    props.resetPassword({ email, newPassword: password, code });
  }

  return (
    <FormWrapper title="Reset Password">
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          {message && <div style={{ color: '#66be6b' }}>{message}</div>}
          {error && <div style={{ color: 'red' }}>{error}</div>}

          <Label>Email</Label>
          <Input
            type="email"
            name="email"
            onChange={handleChange}
            value={values.email || ''}
            valid={
              (!isEmpty(errors) && isEmpty(errors.email)) || (isEmpty(errors) && !!values.email)
            }
            invalid={!isEmpty(errors.email)}
          />

          {errors.email && <div className="invalid-feedback">{errors.email}</div>}
        </FormGroup>
        <FormGroup>
          <Label>Password</Label>
          <Input
            type="password"
            name="password"
            onChange={handleChange}
            value={values.password || ''}
            valid={
              (!isEmpty(errors) && isEmpty(errors.password)) ||
              (isEmpty(errors) && !!values.password)
            }
            invalid={!isEmpty(errors.password)}
          />
          {errors.password && (
            <div className="invalid-feedback">
              Password must contain at least 6 characters, one uppercase, one lowercase, one number.
            </div>
          )}
        </FormGroup>
        {requesting ? (
          <Button block color="primary" outline disabled>
            Sit tight
          </Button>
        ) : (
          <Button block color="primary">
            Submit
          </Button>
        )}
      </Form>
    </FormWrapper>
  );
}

function mapState(state) {
  const { requesting, message, error } = state.account;
  return { requesting, message, error };
}

const mapDispatch = {
  resetPassword: accountActions.resetPassword
};

const connectedResetPassword = connect(
  mapState,
  mapDispatch
)(ResetPassword);
export { connectedResetPassword as ResetPassword };
