import React from 'react';
import PropTypes from 'prop-types';
import { InputGroup, CustomInput } from 'reactstrap';

export function LocationSelect(props) {
  const { allLocations, id, handleChange, defaultValue } = props;

  return (
    <InputGroup>
      <CustomInput
        type="select"
        id={id || 'locationselect'}
        name="locationId"
        onChange={handleChange}
        defaultValue={defaultValue || 0}
        required
      >
        <option value="0">Select a Location</option>
        {allLocations.map((l, idx) => (
          <option key={idx} value={l.id}>
            {l.name}
          </option>
        ))}
      </CustomInput>
    </InputGroup>
  );
}
LocationSelect.propTypes = {
  handleChange: PropTypes.func.isRequired,
  allLocations: PropTypes.array,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
