import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input, UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * For use with Hooks only
 */
export function UsernameInput(props) {
  const { handleChange, userName, withTip = false } = props;
  return (
    <FormGroup>
      <Label for="userName">
        Username{' '}
        {withTip && <FontAwesomeIcon color="grey" icon="question-circle" id="username-tooltip" />}
      </Label>
      {withTip && (
        <UncontrolledTooltip placement="top" target="username-tooltip">
          The name that appears on Leaderboards
        </UncontrolledTooltip>
      )}
      <Input
        name="userName"
        id="userName"
        required
        onChange={e => handleChange(e.target.value)}
        value={userName}
      />
    </FormGroup>
  );
}

UsernameInput.propTypes = {
  handleChange: PropTypes.func.isRequired,
  userName: PropTypes.string.isRequired
};
