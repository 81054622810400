import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  CustomInput
} from 'reactstrap';
import { MyPhoto } from './';
import { Title, MobileInput, PageHeader } from '../Shared';
import { accountActions } from '../_actions';
import { EmailInput } from '../Account';
import { inputValidation } from '../_helpers';
import isEmpty from 'lodash/isEmpty';

function MyAccount(props) {
  const { myAccount, getMyAccount } = props;
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [userName, setUserName] = useState('');
  const [smsNotifications, setSmsNotifications] = useState(null);
  const [emailNotifications, setEmailNotifications] = useState(null);
  const [emailState, setEmailState] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberState, setPhoneNumberState] = useState(null);

  useEffect(() => {
    getMyAccount();
  }, [getMyAccount]);

  const validateEmail = value => {
    setEmailState(inputValidation.email(value));
  };

  const validatePhoneNumber = value => {
    setPhoneNumberState(inputValidation.mobile(value));
  };

  const handleSubmit = e => {
    e.preventDefault();

    const data = {
      firstName,
      lastName,
      email,
      userName,
      phoneNumber,
      emailNotifications: myAccount.emailNotifications,
      smsNotifications: myAccount.smsNotifications
    };

    if (smsNotifications !== null) {
      data.smsNotifications = smsNotifications;
    }
    if (emailNotifications !== null) {
      data.emailNotifications = emailNotifications;
    }
    if (!isEmpty(data)) {
      props.updateAccount({ ...data });
    }
  };

  return (
    <Fragment>
      <Title text="My Account" />
      <PageHeader>
        <Row>
          <Col>
            <h4>My Account</h4>
            <p>Update your Account</p>
          </Col>
        </Row>
      </PageHeader>
      <Container className="mt-4">
        <Row>
          <Col>
            <Form onSubmit={handleSubmit}>
              <Row form>
                <Col xs={6}>
                  <FormGroup>
                    <Label for="firstName">First Name</Label>
                    <Input
                      id="firstName"
                      name="firstName"
                      type="text"
                      onChange={e => setFirstName(e.target.value)}
                      defaultValue={myAccount && myAccount.firstName}
                    />
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormGroup>
                    <Label for="lastName">Last Name</Label>
                    <Input
                      id="lastName"
                      name="lastName"
                      type="text"
                      onChange={e => setLastName(e.target.value)}
                      defaultValue={myAccount && myAccount.lastName}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col sm={6}>
                  <FormGroup>
                    <Label for="userName">User Name</Label>
                    <Input
                      id="userName"
                      name="userName"
                      type="text"
                      onChange={e => setUserName(e.target.value)}
                      defaultValue={myAccount && myAccount.userName}
                    />
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <EmailInput
                    handleChange={setEmail}
                    email={myAccount && myAccount.email}
                    validateEmail={validateEmail}
                    validation={emailState}
                  />
                </Col>
              </Row>
              <Row form>
                <Col md={6}>
                  <MobileInput
                    handleChange={setPhoneNumber}
                    phoneNumber={myAccount && myAccount.phoneNumber}
                    validatePhoneNumber={validatePhoneNumber}
                    validation={phoneNumberState}
                  />
                </Col>
                {myAccount && (
                  <Fragment>
                    <Col>
                      <FormGroup>
                        <Label>&nbsp;</Label>
                        <CustomInput
                          defaultChecked={myAccount.emailNotifications}
                          onClick={e => setEmailNotifications(e.target.checked)}
                          type="checkbox"
                          label="Email Notifications"
                          id="email-notifs"
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>&nbsp;</Label>
                        <CustomInput
                          defaultChecked={myAccount.smsNotifications}
                          onClick={e => setSmsNotifications(e.target.checked)}
                          type="checkbox"
                          label="SMS Notifications"
                          id="sms-notifs"
                        />
                      </FormGroup>
                    </Col>
                  </Fragment>
                )}
              </Row>
              <Button color="primary">Update</Button>
            </Form>
          </Col>
        </Row>
      </Container>
      {/* <PageHeader className="my-4">

      </PageHeader> */}
      <Container className="my-4">
        <Row>
          <MyPhoto />
        </Row>
      </Container>
    </Fragment>
  );
}
function mapState(state) {
  const { myAccount } = state.account;
  return { myAccount };
}

const mapDispatch = {
  getMyAccount: accountActions.myAccount,
  updateAccount: accountActions.updateAccount
};

const connectedMyAccount = connect(
  mapState,
  mapDispatch
)(MyAccount);
export { connectedMyAccount as MyAccount };
