import { equipmentConstants } from '../_constants';
import { equipmentService } from '../_services';
import { toastr } from 'react-redux-toastr';
import { history } from '../_helpers';

export const equipmentActions = {
  create,
  update,
  remove,
  getAll,
  getSingle,
  uploadImage,
  filter,
  removeFilter
};

function create(newEquip) {
  return dispatch => {
    dispatch(request(newEquip));
    equipmentService.create(newEquip).then(
      data => {
        dispatch(success(data));
        toastr.success('Success', `Created ${data.name}`);
        history.push(`/Admin/Equipment/Edit/${data.path}`);
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: equipmentConstants.CREATE_EQUIPMENT_REQUEST, data };
  }
  function success(data) {
    return { type: equipmentConstants.CREATE_EQUIPMENT_SUCCESS, data };
  }
  function failure(error) {
    return { type: equipmentConstants.CREATE_EQUIPMENT_FAILURE, error };
  }
}

function update(equip) {
  return dispatch => {
    dispatch(request(equip));
    equipmentService.update(equip).then(
      data => {
        dispatch(success(data));
        toastr.success('Updated', `Successfully updated ${data.name}`);
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: equipmentConstants.UPDATE_EQUIPMENT_REQUEST, data };
  }
  function success(data) {
    return { type: equipmentConstants.UPDATE_EQUIPMENT_SUCCESS, data };
  }
  function failure(error) {
    return { type: equipmentConstants.UPDATE_EQUIPMENT_FAILURE, error };
  }
}

function remove(equipId) {
  return dispatch => {
    dispatch(request(equipId));
    equipmentService.remove(equipId).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: equipmentConstants.DELETE_EQUIPMENT_REQUEST, data };
  }
  function success(data) {
    return { type: equipmentConstants.DELETE_EQUIPMENT_SUCCESS, data };
  }
  function failure(error) {
    return { type: equipmentConstants.DELETE_EQUIPMENT_FAILURE, error };
  }
}

function getAll() {
  return dispatch => {
    dispatch(request());
    equipmentService.getAll().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: equipmentConstants.LIST_EQUIPMENT_REQUEST };
  }
  function success(data) {
    return { type: equipmentConstants.LIST_EQUIPMENT_SUCCESS, data };
  }
  function failure(error) {
    return { type: equipmentConstants.LIST_EQUIPMENT_FAILURE, error };
  }
}

function getSingle(equipId) {
  return dispatch => {
    dispatch(request());
    equipmentService.getSingle(equipId).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: equipmentConstants.GET_EQUIPMENT_REQUEST };
  }
  function success(data) {
    return { type: equipmentConstants.GET_EQUIPMENT_SUCCESS, data };
  }
  function failure(error) {
    return { type: equipmentConstants.GET_EQUIPMENT_FAILURE, error };
  }
}

function uploadImage(file) {
  return dispatch => {
    dispatch(request(file));
    equipmentService.uploadImage(file).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: equipmentConstants.UPLOAD_IMAGE_REQUEST, data };
  }
  function success(data) {
    return { type: equipmentConstants.UPLOAD_IMAGE_SUCCESS, data };
  }
  function failure(error) {
    return { type: equipmentConstants.UPLOAD_IMAGE_FAILURE, error };
  }
}

function filter(terms) {
  return dispatch => {
    dispatch(filter(terms));
  };
  function filter(terms) {
    return { type: equipmentConstants.FILTER_EQUIPMENT, terms };
  }
}

function removeFilter() {
  return dispatch => {
    dispatch(clear());
  };
  function clear() {
    return { type: equipmentConstants.CLEAR_FILTER };
  }
}
