import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import isEmpty from 'lodash/isEmpty';
import { PageHeader, Title } from '../Shared';
import { contentActions } from '../_actions';
import { ObstaclesRules, FindLocation, EventsCalendar, SignUp } from './';

function Home(props) {
  const { bySectionSlug, bySlug, loggedIn } = props;

  const slug = 'homepage';

  useEffect(() => {
    bySectionSlug(slug);
  }, [bySectionSlug]);

  const content = bySlug[slug];

  return (
    <Fragment>
      <Title text="Welcome to Park Warrior" />
      <PageHeader className="home">
        <div className="lines">
          <div className="line" />
          <div className="line" />
          <div className="line" />
        </div>

        <Row>
          <Col>
            {isEmpty(content) ? (
              <DefaultContent />
            ) : (
              <Fragment>
                <h2>{content.title}</h2>
                <div dangerouslySetInnerHTML={{ __html: content.copy }} />
              </Fragment>
            )}
          </Col>
        </Row>
      </PageHeader>
      <Container className="pt-1 pb-2" style={{ backgroundColor: `#f8f8f8` }} fluid>
        <ObstaclesRules />
      </Container>

      <Container className="pt-3 pb-2" fluid>
        <FindLocation />
      </Container>

      <Container className="pt-3 pb-2" style={{ backgroundColor: `#f8f8f8` }} fluid>
        <EventsCalendar />
      </Container>
      {!loggedIn && (
        <Container className="pt-3 pb-2" fluid>
          <SignUp />
        </Container>
      )}
    </Fragment>
  );
}
const mapDispatch = {
  bySectionSlug: contentActions.bySectionSlug
};

function mapState(state) {
  const { bySlug } = state.content;
  const { loggedIn } = state.auth;
  return { bySlug, loggedIn };
}

const connectedHome = connect(
  mapState,
  mapDispatch
)(Home);
export { connectedHome as Home };

function DefaultContent() {
  return (
    <Fragment>
      <h2>Park Warrior</h2>
      <p className="lead">
        Park Warrior courses are designed to build strength, fitness, and agility while having fun.
      </p>
      <p>The aim is to make it through the course within the rules as fast as you can.</p>
      <p>You can time yourself and post your results to the Leaderboard using our timer.</p>
      <p>
        Select your location, and watch the video guides, as the mix of obstacles at each site is
        different.
      </p>
    </Fragment>
  );
}
