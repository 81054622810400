import React, { Fragment } from 'react';
import { Row } from 'reactstrap';
import { PanelCard } from '../Shared';

export function LocationPanels() {
  return (
    <Fragment>
      <h4>Manage Locations</h4>
      <Row>
        <PanelCard icon="map-marked-alt" url="/Admin/Locations/Add">
          <p className="mb-0">Add new Location</p>
        </PanelCard>
        <PanelCard icon="map-signs" url="/Admin/Locations/Manage">
          <p className="mb-0">Lookup</p>
        </PanelCard>
        <PanelCard icon="trash-alt" url="/Admin/Locations/Remove">
          <p className="mb-0">Remove Location</p>
        </PanelCard>
      </Row>
    </Fragment>
  );
}
