export const userSelectStyles = {
  control: (provided, { isFocused }) => ({
    ...provided,
    color: `#495057`,

    borderRadius: `0`,
    background: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23404042' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px`,
    backgroundSize: `8px 10px`,
    border: isFocused ? '1px solid #5acfff' : '1px solid #ced4da',
    boxShadow: isFocused ? `0 0 0 0.2rem rgba(0, 154, 217, 0.25)` : '',
    ':hover': {
      border: '1px solid #ced4da'
    },
    ':active': {
      boxShadow: `0 0 0 0.2rem rgba(0, 154, 217, 0.25)`,
      border: '1px solid #5acfff'
    }
  })
};
