import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  FormFeedback,
  CustomInput
} from 'reactstrap';
import { Title, RichEditor } from '../../Shared';
import { AdminHeader } from '../';
import { contentActions } from '../../_actions';

function AddContent(props) {
  const [title, setTitle] = useState('');
  const [copy, setCopy] = useState('');
  const [contentSectionId, setSectionId] = useState(0);
  const {
    getSections,
    addContent,
    sections,
    saving,
    success,
    checkSectionHasContent,
    sectionHasContent = false
  } = props;

  useEffect(() => {
    getSections();
  }, [getSections]);
  // reset form on success
  useEffect(() => {
    if (success) {
      setTitle('');
      setCopy('');
      setSectionId(0);
    }
  }, [success]);

  const handleSubmit = e => {
    e.preventDefault();
    addContent({ copy, title, contentSectionId });
  };

  const handleChange = e => {
    setCopy(e.target.value);
  };

  return (
    <Fragment>
      <Title text="Add Content" />
      <Container>
        <AdminHeader title="Add Content" goBack />
        <Row>
          <Col>
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="contentSectionId">Section</Label>
                <CustomInput
                  type="select"
                  id="contentSectionId"
                  name="contentSectionId"
                  value={contentSectionId}
                  onChange={e => {
                    setSectionId(e.target.value);
                    checkSectionHasContent(e.target.value);
                  }}
                  invalid={sectionHasContent}
                >
                  <option value="0">Select...</option>
                  {sections &&
                    sections.map((s, idx) => (
                      <option key={idx} value={s.id}>
                        {s.name}
                      </option>
                    ))}
                </CustomInput>
                <FormFeedback invalid>
                  This section already has content. Adding new content will overwrite this, do you
                  wish to edit the existing content instead?
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="title">Title</Label>
                <Input
                  name="title"
                  id="title"
                  type="text"
                  value={title}
                  onChange={e => setTitle(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label>Copy</Label>
                <RichEditor controlled handleChange={handleChange} initialState={copy} />
              </FormGroup>
              {saving ? (
                <Button color="primary" disabled>
                  Saving
                </Button>
              ) : (
                <Button color="primary">Add</Button>
              )}
            </Form>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

const mapDispatch = {
  addContent: contentActions.add,
  getSections: contentActions.getSections,
  checkSectionHasContent: contentActions.sectionHasContent
};

function mapState(state) {
  const { saving, success, error, sections, sectionHasContent } = state.content;
  return { saving, success, error, sections, sectionHasContent };
}

const connectedAddContent = connect(
  mapState,
  mapDispatch
)(AddContent);
export { connectedAddContent as AddContent };
