import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  CustomInput,
  FormFeedback
} from 'reactstrap';
import { Title } from '../../Shared';
import { userManagementActions } from '../../_actions';
import { AdminHeader } from '..';
import { inputValidation } from '../../_helpers';
class AddUser extends React.Component {
  state = {
    firstName: '',
    lastName: '',
    userName: '',
    email: '',
    password: '',
    organisation: '',
    validate: { emailState: null, passwordState: null }
  };

  componentDidMount = () => {
    this.props.listRoles();
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  autoUsername = () => {
    const { firstName, lastName } = this.state;
    const userName = `${firstName.charAt(0)}${lastName}`.toLowerCase();
    this.setState({ userName });
  };

  validatePassword = e => {
    const { validate } = this.state;
    validate.passwordState = inputValidation.password(e.target.value);
    this.setState({ validate });
  };

  validateEmail = e => {
    const { validate } = this.state;
    validate.emailState = inputValidation.email(e.target.value);
    this.setState({ validate });
  };

  // need to discuss some data validation with the big guy and best approach
  handleSubmit = e => {
    e.preventDefault();
    const { firstName, lastName, userName, email, password, roleId } = this.state;
    if (firstName === '' || lastName === '' || email === '' || password === '') {
      return;
    }
    this.props.create({ firstName, lastName, userName, email, password, roleId });
  };

  render() {
    const { roles } = this.props;
    const { emailState, passwordState } = this.state.validate;
    return (
      <Fragment>
        <Title text="Add User" />
        <Container>
          <AdminHeader goBack title="Add New User" />
          <Row>
            <Col>
              <Form onSubmit={this.handleSubmit}>
                <FormGroup>
                  <Label for="firstName">First Name</Label>
                  <Input
                    id="firstName"
                    name="firstName"
                    onBlur={this.autoUsername}
                    onChange={this.handleChange}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="lastName">Last Name</Label>
                  <Input
                    id="lastName"
                    name="lastName"
                    onBlur={this.autoUsername}
                    onChange={this.handleChange}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="organisation">Organisation</Label>
                  <Input id="organisation" name="organisation" onChange={this.handleChange} />
                </FormGroup>
                <FormGroup>
                  <Label for="userName">User Name</Label>
                  <Input id="userName" name="userName" onChange={this.handleChange} />
                </FormGroup>
                <FormGroup>
                  <Label for="email">Email</Label>
                  <Input
                    id="email"
                    name="email"
                    type="email"
                    required
                    onChange={e => {
                      this.validateEmail(e);
                      this.handleChange(e);
                    }}
                    valid={emailState}
                    invalid={emailState !== null && !emailState}
                  />
                  <FormFeedback invalid>The Email provided is currently invalid.</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for="password">Password</Label>
                  <Input
                    id="password"
                    name="password"
                    type="password"
                    required
                    onChange={e => {
                      this.handleChange(e);
                      this.validatePassword(e);
                    }}
                    valid={passwordState}
                    invalid={passwordState !== null && !passwordState}
                  />
                  <FormFeedback invalid>
                    Sorry your password is currently invalid, ensure it contains lower and uppercase
                    text, symbols, numbers and is 8 characters long.
                  </FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label>Role</Label>
                  <div>
                    {roles &&
                      roles.map((r, idx) => (
                        <CustomInput
                          key={idx}
                          inline
                          type="radio"
                          id={r.id}
                          name="roleId"
                          label={r.name}
                          value={r.id}
                          onChange={this.handleChange}
                        />
                      ))}
                  </div>
                </FormGroup>
                <Button color="primary">Create</Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}
const mapDispatch = {
  listRoles: userManagementActions.listRoles,
  create: userManagementActions.create
};

function mapStateToProps(state) {
  const { roles = null } = state.userManagement;
  return { roles };
}

const connectedAddUser = connect(
  mapStateToProps,
  mapDispatch
)(AddUser);
export { connectedAddUser as AddUser };
