import React from 'react';
import { Col, Card } from 'reactstrap';

export function EquipmentCard(props) {
  const { equipment, toggle } = props;
  return (
    <Col xs={6} md={4} xl={3} className="my-2 loc-equip" onClick={e => toggle(e, equipment.id)}>
      <Card>
        <img src={equipment.imageUrl} className="img-fluid" alt={equipment.name} />
        <div className="eq-label">{equipment.name}</div>
      </Card>
    </Col>
  );
}
