import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EquipName } from './EquipName';
import { Title, RichEditor, LoadingCard } from '../../Shared';
import { equipmentActions } from '../../_actions';
import { ImageDropzone } from '../';
class EquipmentEdit extends React.Component {
  state = {};
  componentDidMount = () => {
    const { equipId } = this.props.match.params;
    if (equipId) {
      this.props.getSingle(equipId);
    }
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    if (name === 'file') {
      this.uploadImage();
    }
  };

  uploadImage = () => {
    const formData = new FormData();
    formData.append('file', this.state.file);
    formData.append('equipmentId', 3);
    this.props.addImage(formData);
  };

  handleSubmit = e => {
    e.preventDefault();
    const { single } = this.props;
    const { imgUrl } = this.props;
    const data = { ...single, ...this.state };
    if (imgUrl) {
      data.imageUrl = imgUrl;
    }
    this.props.update({ ...data });
  };

  render() {
    const { single, loading, updating, imgUploading, imgUrl } = this.props;
    return (
      <Fragment>
        {loading && <LoadingCard />}
        {!loading && single && (
          <Fragment>
            <Title text={`Editing ${single.name}`} />
            <Container className="mt-4">
              <Row>
                <Col>
                  <h3>Editing {single.name}</h3>
                  <Form onSubmit={this.handleSubmit}>
                    <EquipName handleChange={this.handleChange} defaultValue={single.name} />
                    <FormGroup>
                      <Label for="videoUrl">Video Url</Label>
                      <Input
                        id="videoUrl"
                        name="videoUrl"
                        onChange={this.handleChange}
                        defaultValue={single.videoUrl}
                      />
                    </FormGroup>
                    <FormGroup row className="no-gutters">
                      <Col xs={12}>
                        <Label for="imageUrl">Image</Label>
                      </Col>
                      <Col>
                        <ImageDropzone handleChange={this.handleChange} />
                      </Col>
                      <Col
                        sm={3}
                        className="border p-2 d-flex justify-content-center align-items-center flex-column"
                      >
                        {!imgUrl && !single.imageUrl && !imgUploading && (
                          <Fragment>
                            <FontAwesomeIcon icon="image" size="3x" />
                            <p className="mb-0">No Image</p>
                          </Fragment>
                        )}
                        {imgUploading && (
                          <Fragment>
                            <FontAwesomeIcon icon="spinner" spin />
                            <p className="mb-0">Uploading</p>
                          </Fragment>
                        )}
                        {!imgUploading && (imgUrl || single.imageUrl) && (
                          <img
                            src={imgUrl || single.imageUrl}
                            alt={single.name}
                            className="img-fluid"
                          />
                        )}
                      </Col>
                    </FormGroup>
                    <FormGroup>
                      <Label>Instructions</Label>
                      <RichEditor
                        name="instructions"
                        handleChange={this.handleChange}
                        initialState={single.instructions}
                      />
                    </FormGroup>
                    {updating ? (
                      <Button color="primary">
                        <FontAwesomeIcon icon="spinner" spin /> Saving
                      </Button>
                    ) : (
                      <Button color="primary">Save Changes</Button>
                    )}
                  </Form>
                </Col>
              </Row>
            </Container>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { single, loading, updating, imgUploading, imgUrl } = state.equipment;
  return { single, loading, updating, imgUploading, imgUrl };
}

const mapDispatchToProps = {
  update: equipmentActions.update,
  getSingle: equipmentActions.getSingle,
  addImage: equipmentActions.uploadImage
};
const connectedEquipmentEdit = connect(
  mapStateToProps,
  mapDispatchToProps
)(EquipmentEdit);
export { connectedEquipmentEdit as EquipmentEdit };
