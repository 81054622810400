import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
// import { eventActions, contentActions } from '../_actions';

export function EventsCalendar(props) {
  // const { getUpcoming, upcoming } = props;

  // useEffect(() => {
  //   getUpcoming();
  // }, [getUpcoming]);

  return (
    <Container>
      <Row>
        <Col>
          <h4>Events Calendar</h4>
          <p>
            From time to time organised events where you can compete against others in an ’official’
            competition.
          </p>
          <p>
            <Link to="/Events">View upcoming Events »</Link>
          </p>
        </Col>
      </Row>
    </Container>
  );
}

// function mapState(state) {
//   const { upcoming, states, loading } = state.event;

//   return { upcoming, states, loading, bySlug };
// }

// const mapDispatch = {
//   getUpcoming: eventActions.getUpcoming,
//   bySectionSlug: contentActions.bySectionSlug,
//   filter: eventActions.filter
// };
// const connectedEventsCalendar = connect(
//   mapState,
//   mapDispatch
// )(EventsCalendar);
// export { connectedEventsCalendar as EventsCalendar };
