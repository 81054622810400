import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Form, FormGroup, Button, Input, Label } from 'reactstrap';
import { EmailInput } from '../Account';
import { inputValidation } from '../_helpers';
import { feedbackActions, contentActions } from '../_actions';
import { Title, PageHeader } from '../Shared';
import isEmpty from 'lodash/isEmpty';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Feedback(props) {
  const { user, bySectionSlug, bySlug, submit, submitting, submitted, clear } = props;
  const [email, setEmail] = useState('');
  const [userName, setUserName] = useState('');
  const [comment, setComment] = useState('');
  const [emailState, setEmailState] = useState(null);

  const validateEmail = value => {
    const valid = inputValidation.email(value);
    setEmailState(valid);
  };

  const handleSubmit = e => {
    e.preventDefault();
    submit({ replyTo: email, userName, comment });
  };

  const slug = 'feedback';

  useEffect(() => {
    clear();
    bySectionSlug(slug);
  }, [bySectionSlug, clear]);

  const content = bySlug[slug];

  return (
    <Fragment>
      <Title text="Feedback" />
      <PageHeader className="default">
        <Row>
          <Col>
            {isEmpty(content) ? (
              <DefaultContent />
            ) : (
              <Fragment>
                <h2>{content.title}</h2>
                <div dangerouslySetInnerHTML={{ __html: content.copy }} />
              </Fragment>
            )}
          </Col>
        </Row>
      </PageHeader>

      <Container className="mt-4">
        <Row>
          <Col sm={12}>
            {submitted ? (
              <ThankYouMessage />
            ) : (
              <Form onSubmit={handleSubmit}>
                {!user && (
                  <FormGroup row>
                    <Col xs={12} md={6}>
                      <Label for="userName">Name</Label>
                      <Input
                        name="userName"
                        id="userName"
                        required
                        onChange={e => setUserName(e.target.value)}
                        defaultValue={userName}
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <EmailInput
                        handleChange={setEmail}
                        email={email}
                        defaultValue={email}
                        validateEmail={validateEmail}
                        validation={emailState}
                      />
                    </Col>
                  </FormGroup>
                )}
                <FormGroup>
                  <Label for="comment">Comment</Label>
                  <Input
                    type="textarea"
                    maxLength="300"
                    name="comment"
                    id="comment"
                    defaultValue={comment}
                    onChange={e => setComment(e.target.value)}
                  />
                </FormGroup>
                {submitting ? (
                  <Button disabled color="primary">
                    <FontAwesomeIcon icon="spinner" spin /> Submitting
                  </Button>
                ) : (
                  <Button color="primary">Submit</Button>
                )}
              </Form>
            )}
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}
function ThankYouMessage() {
  return <p>Thanks for your feedback. Our team will review it within 1 business day.</p>;
}
function DefaultContent() {
  return <p>Have some feedback? Just fill out the form to let the team know!</p>;
}

const mapDispatch = {
  bySectionSlug: contentActions.bySectionSlug,
  submit: feedbackActions.submit,
  clear: feedbackActions.clearState
};

function mapStateToProps(state) {
  const { user, error, message } = state.auth;
  const { bySlug } = state.content;
  const { submitting, submitted } = state.feedback;
  return { user, error, message, bySlug, submitting, submitted };
}

const connectedFeedback = connect(
  mapStateToProps,
  mapDispatch
)(Feedback);
export { connectedFeedback as Feedback };
