export const locationEquipmentConstants = {
  GET_LOC_EQUIPMENT_REQUEST: 'GET_LOC_EQUIPMENT_REQUEST',
  GET_LOC_EQUIPMENT_SUCCESS: 'GET_LOC_EQUIPMENT_SUCCESS',
  GET_LOC_EQUIPMENT_FAILURE: 'GET_LOC_EQUIPMENT_FAILURE',
  GET_SITE_EQUIPMENT_FAILURE: 'GET_SITE_EQUIPMENT_FAILURE',
  GET_SITE_EQUIPMENT_REQUEST: 'GET_SITE_EQUIPMENT_REQUEST',
  GET_SITE_EQUIPMENT_SUCCESS: 'GET_SITE_EQUIPMENT_SUCCESS',
  ADD_EQUIPMENT_TO_LOCATION_REQUEST: 'ADD_EQUIPMENT_TO_LOCATION_REQUEST',
  ADD_EQUIPMENT_TO_LOCATION_SUCCESS: 'ADD_EQUIPMENT_TO_LOCATION_SUCCESS',
  ADD_EQUIPMENT_TO_LOCATION_FAILURE: 'ADD_EQUIPMENT_TO_LOCATION_FAILURE',
  REMOVE_EQUIPMENT_FROM_LOCATION_REQUEST: 'REMOVE_EQUIPMENT_FROM_LOCATION_REQUEST',
  REMOVE_EQUIPMENT_FROM_LOCATION_SUCCESS: 'REMOVE_EQUIPMENT_FROM_LOCATION_SUCCESS',
  REMOVE_EQUIPMENT_FROM_LOCATION_FAILURE: 'REMOVE_EQUIPMENT_FROM_LOCATION_FAILURE',
  UPDATE_EQUIPMENT_POSITION_REQUEST: 'UPDATE_EQUIPMENT_POSITION_REQUEST',
  UPDATE_EQUIPMENT_POSITION_SUCCESS: 'UPDATE_EQUIPMENT_POSITION_SUCCESS',
  UPDATE_EQUIPMENT_POSITION_FAILURE: 'UPDATE_EQUIPMENT_POSITION_FAILURE'
};
