import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  CustomInput,
  FormFeedback
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Title, LoadingCard } from '../../Shared';
import { userManagementActions } from '../../_actions';
import { inputValidation } from '../../_helpers';
import { toastr } from 'react-redux-toastr';

class UserEdit extends Component {
  state = {
    validate: { emailState: '', passwordState: '' }
  };

  componentDidMount = () => {
    const { userId } = this.props.match.params;
    if (userId) {
      this.props.getSingle(userId);
      this.props.listRoles();
    }
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  validatePassword = e => {
    const { validate } = this.state;
    validate.passwordState = inputValidation.password(e.target.value);
    this.setState({ validate });
  };

  validateEmail = e => {
    const { validate } = this.state;
    validate.emailState = inputValidation.email(e.target.value);
    this.setState({ validate });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { user, userName } = this.props;
    if (user.userName === userName) {
      toastr.error('You can not remove admin from yourself');
    } else {
      this.props.editUser({ ...user, ...this.state });
    }
  };

  render() {
    const { user, loading, updating, roles } = this.props;
    const { passwordState, emailState } = this.state.validate;
    return (
      <Fragment>
        <Container className="mt-4">
          <Row>
            {loading && <LoadingCard />}
            {!loading && user && (
              <Fragment>
                <Title text={`Editing ${user.displayName}`} />
                <Col>
                  <h3>Editing {user.displayName}</h3>
                  <Form onSubmit={this.handleSubmit}>
                    <FormGroup>
                      <Label for="name">Name</Label>
                      <Input
                        id="name"
                        name="name"
                        onChange={this.handleChange}
                        defaultValue={user.firstName}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="lastName">Last Name</Label>
                      <Input
                        id="lastName"
                        name="lastName"
                        defaultValue={user.lastName}
                        onChange={this.handleChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="userName">User Name</Label>
                      <Input
                        id="userName"
                        name="userName"
                        defaultValue={user.userName}
                        onChange={this.handleChange}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="email">Email</Label>
                      <Input
                        id="email"
                        name="email"
                        type="email"
                        defaultValue={user.email}
                        onChange={e => {
                          this.validateEmail(e);
                          this.handleChange(e);
                        }}
                        valid={emailState}
                        invalid={emailState !== null && !emailState}
                      />
                      <FormFeedback invalid>The Email provided is currently invalid.</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label for="password">Password</Label>
                      <Input
                        id="password"
                        name="password"
                        type="password"
                        onChange={e => {
                          this.handleChange(e);
                          this.validatePassword(e);
                        }}
                        valid={passwordState}
                        invalid={passwordState !== null && !passwordState}
                      />
                      <FormFeedback invalid>
                        Sorry your password is currently invalid, ensure it contains lower and
                        uppercase text, symbols, numbers and is 8 characters long.
                      </FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label>Role</Label>
                      <div>
                        {roles &&
                          roles.map((r, idx) => (
                            <CustomInput
                              key={idx}
                              inline
                              type="radio"
                              id={r.id}
                              name="roleId"
                              label={r.name}
                              defaultValue={r.id}
                              onChange={this.handleChange}
                              defaultChecked={user.roles[0] === r.name ? true : false}
                            />
                          ))}
                      </div>
                    </FormGroup>
                    {updating ? (
                      <Button color="primary">
                        <FontAwesomeIcon icon="spinner" spin /> Saving
                      </Button>
                    ) : (
                      <Button color="primary">Save Changes</Button>
                    )}
                  </Form>
                </Col>
              </Fragment>
            )}
          </Row>
        </Container>
      </Fragment>
    );
  }
}

const mapDispatch = {
  getSingle: userManagementActions.getSingle,
  listRoles: userManagementActions.listRoles,
  editUser: userManagementActions.editUser
};

function mapStateToProps(state) {
  const { user, loading, updating, roles = null } = state.userManagement;
  const { userName } = state.auth.user;
  return { user, loading, updating, roles, userName };
}

const connectedUserEdit = connect(
  mapStateToProps,
  mapDispatch
)(UserEdit);
export { connectedUserEdit as UserEdit };
