import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Title } from '../../Shared';
import { AdminHeader } from '../';
import { contentActions } from '../../_actions';

function ViewContent(props) {
  const { getSections, sections } = props;
  useEffect(() => {
    getSections();
  }, [getSections]);
  return (
    <Fragment>
      <Title text="Edit Content" />
      <Container>
        <AdminHeader title="Edit Content" goBack />
        <Row>
          {sections &&
            sections.map((s, idx) => (
              <Col sm={3} className="mb-4 flippable panel-card" key={idx}>
                <Card>
                  <CardBody>{s.name}</CardBody>
                </Card>
              </Col>
            ))}
        </Row>
      </Container>
    </Fragment>
  );
}

const mapDispatch = {
  getContent: contentActions.getAll,
  getSections: contentActions.getSections
};

function mapState(state) {
  const { loading, content, sections } = state.content;
  return { loading, content, sections };
}

const connectedViewContent = connect(
  mapState,
  mapDispatch
)(ViewContent);
export { connectedViewContent as ViewContent };
