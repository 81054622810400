export const smsConstants = {
  SEND_SMS_REQUEST: 'SEND_SMS_REQUEST',
  SEND_SMS_SUCCESS: 'SEND_SMS_SUCCESS',
  SEND_SMS_FAILURE: 'SEND_SMS_FAILURE',
  SEND_GROUP_SMS_REQUEST: 'SEND_GROUP_SMS_REQUEST',
  SEND_GROUP_SMS_SUCCESS: 'SEND_GROUP_SMS_SUCCESS',
  SEND_GROUP_SMS_FAILURE: 'SEND_GROUP_SMS_FAILURE',
  GET_LOCATION_SUBS_REQUEST: 'GET_LOCATION_SUBS_REQUEST',
  GET_LOCATION_SUBS_SUCCESS: 'GET_LOCATION_SUBS_SUCCESS',
  GET_LOCATION_SUBS_FAILURE: 'GET_LOCATION_SUBS_FAILURE',
  GET_RECIPIENT_NUMBERS_REQUEST: 'GET_RECIPIENT_NUMBERS_REQUEST',
  GET_RECIPIENT_NUMBERS_SUCCESS: 'GET_RECIPIENT_NUMBERS_SUCCESS',
  GET_RECIPIENT_NUMBERS_FAILURE: 'GET_RECIPIENT_NUMBERS_FAILURE'
};
