import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { Title, PageHeader } from '../Shared';

export function NotFound404() {
  return (
    <Fragment>
      <Title text="404 - Not Found!" />
      <PageHeader>
        <Row>
          <Col>
            <h2>Page Not Found</h2>
          </Col>
        </Row>
      </PageHeader>
      <Container className="mt-4">
        <Row>
          <Col>
            <p>
              We couldn't find the page you were looking for. If you're experiencing a problem
              please<Link to="/Feedback"> get in touch</Link>.
            </p>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}
