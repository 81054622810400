import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button, FormText } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Title, LocationSelect, DateTimePicker, RichEditor } from '../../Shared';
import { eventActions, locationActions } from '../../_actions';
import { AdminHeader } from '../';

class AddEvent extends React.Component {
  state = { startTime: null, endTime: null, name: '', content: '', locationId: 0 };

  componentWillMount = () => {
    this.props.getAll();
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { startTime, endTime, content, locationId, name } = this.state;

    if (startTime === null || endTime === null) {
      this.setState({ timeError: 'The Event times have not been set.' });
      return;
    }

    if (startTime > endTime) {
      this.setState({ timeError: 'The Event start cannot be after the finish.' });
      return;
    }

    this.props.create({ startTime, endTime, content, locationId, name });
  };

  render() {
    const { creating, locations } = this.props;
    return (
      <Fragment>
        <Title text="Add new Event" />
        <Container>
          <AdminHeader goBack title="Add New Event" />
          <Row>
            <Col>
              <Form onSubmit={this.handleSubmit}>
                <FormGroup>
                  <Label for="name">Name</Label>
                  <Input
                    id="name"
                    name="name"
                    onChange={this.handleChange}
                    value={this.state.name}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="locationId">Location</Label>
                  {locations && (
                    <LocationSelect allLocations={locations} handleChange={this.handleChange} />
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="content">Content</Label>
                  <RichEditor
                    controlled
                    name="content"
                    handleChange={this.handleChange}
                    initialState={this.state.content}
                  />
                </FormGroup>
                <Row form>
                  <Col>
                    <FormGroup>
                      <Label for="startTime">Start Time</Label>
                      <DateTimePicker
                        name="startTime"
                        value={this.state.startTime}
                        handleChange={this.handleChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="endTime">End Time</Label>
                      <DateTimePicker
                        name="endTime"
                        value={this.state.endTime}
                        handleChange={this.handleChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {this.state.timeError && <FormText>{this.state.timeError}</FormText>}
                {creating ? (
                  <Button color="primary">
                    <FontAwesomeIcon icon="spinner" spin /> Create
                  </Button>
                ) : (
                  <Button color="primary">Create</Button>
                )}
              </Form>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

const mapDispatch = {
  getAll: locationActions.getAll,
  create: eventActions.create
};

function mapStateToProps(state) {
  const { creating = false, error } = state.event;
  const { locations } = state.location;
  return { creating, error, locations };
}

const connectedAddEvent = connect(
  mapStateToProps,
  mapDispatch
)(AddEvent);
export { connectedAddEvent as AddEvent };
