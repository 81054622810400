import { inputValidation as validator } from '../_helpers';

export default function validate(values, errors) {
  if (values.email) {
    errors.email = validator.email(values.email);
  }
  if (values.password) {
    errors.password = validator.password(values.password);
  }
  if (values.firstName) {
    errors.firstName = validator.firstName(values.firstName);
  }
  if (values.lastName) {
    errors.lastName = validator.lastName(values.lastName);
  }
  if (values.userName) {
    errors.userName = validator.userName(values.userName);
  }
  if (values.phoneNumber) {
    errors.phoneNumber = validator.mobile(values.phoneNumber);
  }
  return errors;
}
