import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, TabPane, TabContent, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { Title, LoadingCard, PageHeader } from '../Shared';
import { EventList } from './';
import { eventActions, contentActions } from '../_actions';
import isEmpty from 'lodash/isEmpty';

function Events(props) {
  const [tab, setTab] = useState('upcoming');
  const { upcoming, past, getUpcoming, getPast, loading, bySlug, bySectionSlug } = props;
  const slug = 'events';

  useEffect(() => {
    getUpcoming();
    getPast();
    bySectionSlug(slug);
  }, [getUpcoming, getPast, bySectionSlug]);

  const content = bySlug[slug];

  return (
    <Fragment>
      <Title text="Upcoming Events" />
      <PageHeader className="default">
        <Row>
          <Col sm={12}>
            {isEmpty(content) ? (
              <h4>Upcoming Park Warrior Events</h4>
            ) : (
              <Fragment>
                <h2>{content.title}</h2>
                <div dangerouslySetInnerHTML={{ __html: content.copy }} />
              </Fragment>
            )}
          </Col>
        </Row>
      </PageHeader>
      <Container className="mt-4">
        {loading && (
          <Row>
            <LoadingCard />
          </Row>
        )}
        <Nav fill tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: tab === 'upcoming' })}
              onClick={() => setTab('upcoming')}
            >
              Upcoming
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: tab === 'past' })}
              onClick={() => setTab('past')}
            >
              Past
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={tab}>
          <TabPane tabId="upcoming">
            <EventList showNothingUpcoming data={upcoming} />
          </TabPane>
          <TabPane tabId="past">
            <EventList data={past} />
          </TabPane>
        </TabContent>
      </Container>
    </Fragment>
  );
}

function mapState(state) {
  const { upcoming, past, loading } = state.event;
  const { bySlug } = state.content;

  return { upcoming, past, loading, bySlug };
}

const mapDispatch = {
  getUpcoming: eventActions.getUpcoming,
  getPast: eventActions.getPast,
  bySectionSlug: contentActions.bySectionSlug,
  filter: eventActions.filter
};
const connectedEvents = connect(
  mapState,
  mapDispatch
)(Events);
export { connectedEvents as Events };
