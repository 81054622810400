import React from 'react';
import PropTypes from 'prop-types';
import { NavItem, NavLink } from 'reactstrap';
import { NavLink as RRNavLink } from 'react-router-dom';

export function LoginSignUp({ navigated }) {
  return (
    <NavItem>
      <NavLink className="nav-btn" tag={RRNavLink} to={`/Account/Login`} onClick={navigated}>
        Login
      </NavLink>
    </NavItem>
  );
}
LoginSignUp.propTypes = {
  navigated: PropTypes.func.isRequired
};
