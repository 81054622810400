export const contentConstants = {
  GET_CONTENT_REQUEST: 'GET_CONTENT_REQUEST',
  GET_CONTENT_SUCCESS: 'GET_CONTENT_SUCCESS',
  GET_CONTENT_FAILURE: 'GET_CONTENT_FAILURE',
  GET_CONTENT_SECTIONS_REQUEST: 'GET_CONTENT_SECTIONS_REQUEST',
  GET_CONTENT_SECTIONS_SUCCESS: 'GET_CONTENT_SECTIONS_SUCCESS',
  GET_CONTENT_SECTIONS_FAILURE: 'GET_CONTENT_SECTIONS_FAILURE',
  ADD_CONTENT_REQUEST: 'ADD_CONTENT_REQUEST',
  ADD_CONTENT_SUCCESS: 'ADD_CONTENT_SUCCESS',
  ADD_CONTENT_FAILURE: 'ADD_CONTENT_FAILURE',
  UPDATE_CONTENT_REQUEST: 'UPDATE_CONTENT_REQUEST',
  UPDATE_CONTENT_SUCCESS: 'UPDATE_CONTENT_SUCCESS',
  UPDATE_CONTENT_FAILURE: 'UPDATE_CONTENT_FAILURE',
  SECTION_HAS_CONTENT_REQUEST: 'SECTION_HAS_CONTENT_REQUEST',
  SECTION_HAS_CONTENT_SUCCESS: 'SECTION_HAS_CONTENT_SUCCESS',
  SECTION_HAS_CONTENT_FAILURE: 'SECTION_HAS_CONTENT_FAILURE',
  GET_CONTENT_BY_SECTION_SLUG_REQUEST: 'GET_CONTENT_BY_SECTION_SLUG_REQUEST',
  GET_CONTENT_BY_SECTION_SLUG_SUCCESS: 'GET_CONTENT_BY_SECTION_SLUG_SUCCESS',
  GET_CONTENT_BY_SECTION_SLUG_FAILURE: 'GET_CONTENT_BY_SECTION_SLUG_FAILURE',
  ALL_CONTENT_BY_SLUG_REQUEST: 'ALL_CONTENT_BY_SLUG_REQUEST',
  ALL_CONTENT_BY_SLUG_SUCCESS: 'ALL_CONTENT_BY_SLUG_SUCCESS',
  ALL_CONTENT_BY_SLUG_FAILURE: 'ALL_CONTENT_BY_SLUG_FAILURE'
};
