import { handleResponse, headers } from '../_helpers';

export const authService = {
  login,
  logout
};

async function login(user, password) {
  const td = await fetch(`/api/Auth/Login`, headers.post({ user, password }));
  const response = await handleResponse(td);
  if (response.token) {
    localStorage.setItem('user', JSON.stringify(response));
  }
  return response;
}

async function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('user');
}
