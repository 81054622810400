import { authFetch, handleResponse, headers } from '../_helpers';

export const equipmentService = {
  create,
  update,
  remove,
  getAll,
  getSingle,
  uploadImage
};

async function create(newEquip) {
  const td = await authFetch(`/api/Equipment/Create`, headers.post(newEquip));
  return td;
}

async function update(equip) {
  const td = await authFetch(`/api/Equipment/Update`, headers.post(equip));
  return td;
}

async function remove(equipId) {
  const td = await authFetch(`/api/Equipment/Delete`, headers.postPrimitive(equipId));
  return td;
}

async function getAll() {
  const td = await fetch(`/api/Equipment/ListAll`, headers.get());
  const response = await handleResponse(td);
  return response;
}

async function getSingle(equipId) {
  const td = await fetch(`/api/Equipment/GetSingle`, headers.postPrimitive(equipId));
  const response = await handleResponse(td);
  return response;
}

async function uploadImage(file) {
  const td = await authFetch(`/api/Equipment/AddImage`, headers.postFile(file));
  return td;
}
