import { leaderboardConstants } from '../_constants';
import { leaderboardService } from '../_services';
import { toastr } from 'react-redux-toastr';

export const leaderboardActions = {
  listResults,
  listUserResults,
  getOverviewLeaderboard,
  addToLeaderboard,
  myResults
};

function listResults(path) {
  return dispatch => {
    dispatch(request(path));
    leaderboardService.listResults(path).then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: leaderboardConstants.GET_LEADERBOARD_REQUEST, data };
  }
  function success(data) {
    return { type: leaderboardConstants.GET_LEADERBOARD_SUCCESS, data };
  }
  function failure(error) {
    return { type: leaderboardConstants.GET_LEADERBOARD_FAILURE, error };
  }
}

function listUserResults() {
  return dispatch => {
    dispatch(request());
    leaderboardService.listUserResults().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: leaderboardConstants.GET_USER_RESULTS_REQUEST, data };
  }
  function success(data) {
    return { type: leaderboardConstants.GET_USER_RESULTS_SUCCESS, data };
  }
  function failure(error) {
    return { type: leaderboardConstants.GET_USER_RESULTS_FAILURE, error };
  }
}

function getOverviewLeaderboard() {
  return dispatch => {
    dispatch(request());
    leaderboardService.getOverviewLeaderboard().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: leaderboardConstants.GET_LEADERBOARD_OVERVIEW_REQUEST };
  }
  function success(data) {
    return { type: leaderboardConstants.GET_LEADERBOARD_OVERVIEW_SUCCESS, data };
  }
  function failure(error) {
    return { type: leaderboardConstants.GET_LEADERBOARD_OVERVIEW_FAILURE, error };
  }
}

function addToLeaderboard(result) {
  return dispatch => {
    dispatch(request(result));
    leaderboardService.addToLeaderboard(result).then(
      data => {
        if (data.standing <= 10) {
          toastr.success(
            `Congratulations! You're in position #${data.standing} on the Social Leaderboard`
          );
        } else {
          toastr.success('Time Saved to Leaderboard');
        }
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request(data) {
    return { type: leaderboardConstants.ADD_TO_LEADERBOARD_REQUEST, data };
  }
  function success(data) {
    return { type: leaderboardConstants.ADD_TO_LEADERBOARD_SUCCESS, data };
  }
  function failure(error) {
    return { type: leaderboardConstants.ADD_TO_LEADERBOARD_FAILURE, error };
  }
}

function myResults() {
  return dispatch => {
    dispatch(request());
    leaderboardService.myResults().then(
      data => {
        dispatch(success(data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: leaderboardConstants.MY_RESULTS_REQUEST };
  }
  function success(data) {
    return { type: leaderboardConstants.MY_RESULTS_SUCCESS, data };
  }
  function failure(error) {
    return { type: leaderboardConstants.MY_RESULTS_FAILURE, error };
  }
}
