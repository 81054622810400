import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, InputGroup, CustomInput } from 'reactstrap';
import { EventCard } from '.';

function NothingUpcoming() {
  return (
    <p>
      There are no upcoming events scheduled. <Link to="/Account/SignUp">Create an account</Link> to
      stay up to date or check back soon.
    </p>
  );
}

export function EventList(props) {
  const [stateFilter, setStateFilter] = useState('0');
  const { data = {}, showNothingUpcoming = false } = props;
  const { states = [], events } = data;

  const visible = events ? events.filter(x => x.location.state === stateFilter) : [];
  const nothingToShow = !!!visible.length && stateFilter !== '0' && showNothingUpcoming;

  return (
    <Row>
      <Col xs={12}>
        <Row>
          <Col className="my-2">
            <InputGroup>
              <CustomInput
                type="select"
                id="stateSelect"
                name="stateName"
                onChange={e => setStateFilter(e.target.value)}
                defaultValue={0}
                required
              >
                <option value="0">Select a State</option>
                {states.map((s, idx) => (
                  <option key={idx} value={s}>
                    {s}
                  </option>
                ))}
              </CustomInput>
            </InputGroup>
          </Col>
          {props.children}
        </Row>
        {nothingToShow && <NothingUpcoming />}
      </Col>
      {visible.map((event, idx) => (
        <EventCard key={idx} event={event} />
      ))}
    </Row>
  );
}
