import { authFetch, handleResponse, headers } from '../_helpers';

export const locationService = {
  create,
  update,
  disable,
  deleteTime,
  getAll,
  getLocationsAndStates,
  getLocationTimes,
  getState,
  getSingle,
  addEquipment,
  removeEquipment,
  locationsByDistance
};

async function create(newLocation) {
  const td = await authFetch(`/api/Location/Create`, headers.post(newLocation));
  return td;
}

async function update(loc) {
  const td = await authFetch(`/api/Location/Update`, headers.post(loc));
  return td;
}

async function disable(locationId) {
  const td = await authFetch(`/api/Location/Disable`, headers.postPrimitive(locationId));
  return td;
}

async function deleteTime(timeId) {
  const td = await fetch(`/api/Location/DeleteTime`, headers.postPrimitiveAuth(timeId));
  const response = await handleResponse(td);
  return response;
}

async function getAll() {
  const td = await fetch(`/api/Location/ListAll`, headers.get());
  const response = await handleResponse(td);
  return response;
}

async function getLocationsAndStates() {
  const response = await fetch(`/api/Location/LocationsAndStates`, headers.get());
  const data = await handleResponse(response);
  return data;
}

async function getLocationTimes(path) {
  const td = await fetch(`/api/Location/ListTimes`, headers.postPrimitiveAuth(path));
  const response = await handleResponse(td);
  return response;
}

async function getState() {
  const td = await fetch(`/api/Location/ListState`, headers.get());
  const response = await handleResponse(td);
  return response;
}

async function getSingle(locationId) {
  const td = await fetch(`/api/Location/GetSingle`, headers.postPrimitive(locationId));
  const response = await handleResponse(td);
  return response;
}

async function addEquipment(addEquip) {
  const td = await authFetch(`/api/Location/AddEquipment`, headers.post(addEquip));
  return td;
}

async function removeEquipment(equipmentId) {
  const td = await authFetch(`/api/Location/RemoveEquipment`, headers.post(equipmentId));
  return td;
}

async function locationsByDistance(coords) {
  const td = await authFetch(`/api/Location/ByDistance`, headers.post(coords));
  return td;
}
