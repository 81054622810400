import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { contentActions } from '../_actions';
import { Title, PageHeader } from '../Shared';

function About(props) {
  const { bySectionSlug, bySlug } = props;
  const slug = 'about';
  useEffect(() => {
    bySectionSlug(slug);
  }, [bySectionSlug]);

  const content = bySlug[slug];

  return (
    <Fragment>
      <Title text="About" />
      <PageHeader className="locations">
        <Row>
          <Col>
            <h2>About Park Warrior</h2>
          </Col>
        </Row>
      </PageHeader>
      <Container className="mt-4">
        <Row>
          <Col>{content && <div dangerouslySetInnerHTML={{ __html: content.copy }} />}</Col>
        </Row>
      </Container>
    </Fragment>
  );
}

const mapDispatch = {
  bySectionSlug: contentActions.bySectionSlug
};

function mapState(state) {
  const { bySlug } = state.content;
  return { bySlug };
}

const connectedAbout = connect(
  mapState,
  mapDispatch
)(About);
export { connectedAbout as About };
