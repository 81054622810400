import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Title, RichEditor } from '../../Shared';
import { equipmentActions } from '../../_actions';
import { AdminHeader, ImageDropzone } from '../';

class AddEquipment extends React.Component {
  state = { name: '', videoUrl: '', instructions: '', content: '' };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    if (name === 'file') {
      this.uploadImage();
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    const { imgUrl } = this.props;
    this.props.create({ ...this.state, imageUrl: imgUrl });
  };

  uploadImage = () => {
    const formData = new FormData();
    formData.append('file', this.state.file);
    formData.append('equipmentId', 3);
    this.props.addImage(formData);
  };

  render() {
    const { creating, imgUploading, imgUrl } = this.props;
    return (
      <Fragment>
        <Title text="Add new Equipment" />
        <Container>
          <AdminHeader goBack title="Add New Equipment" />
          <Row>
            <Col>
              <Form onSubmit={this.handleSubmit}>
                <FormGroup>
                  <Label for="name">Name</Label>
                  <Input
                    id="name"
                    name="name"
                    value={this.state.name}
                    onChange={this.handleChange}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="videoUrl">Video Url</Label>
                  <Input
                    id="videoUrl"
                    name="videoUrl"
                    value={this.state.videoUrl}
                    onChange={this.handleChange}
                  />
                </FormGroup>
                <FormGroup row className="no-gutters">
                  <Col xs={12}>
                    <Label for="imageUrl">Image</Label>
                  </Col>
                  <Col>
                    <ImageDropzone handleChange={this.handleChange} />
                  </Col>
                  <Col
                    sm={3}
                    className="border p-2 d-flex justify-content-center align-items-center flex-column"
                  >
                    {!imgUrl && !imgUploading && (
                      <Fragment>
                        <FontAwesomeIcon icon="image" size="3x" />
                        <p className="mb-0">No Image</p>
                      </Fragment>
                    )}
                    {imgUploading && (
                      <Fragment>
                        <FontAwesomeIcon icon="spinner" spin />
                        <p className="mb-0">Uploading</p>
                      </Fragment>
                    )}
                    {!imgUploading && imgUrl && <img src={imgUrl} alt="" className="img-fluid" />}
                  </Col>
                </FormGroup>
                <FormGroup>
                  <Label for="instructions">Instructions</Label>
                  <RichEditor
                    controlled
                    name="instructions"
                    initialState={this.state.instructions}
                    handleChange={this.handleChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="content">Content</Label>
                  <Input
                    id="content"
                    name="content"
                    value={this.state.content}
                    onChange={this.handleChange}
                  />
                </FormGroup>
                {creating ? (
                  <Button color="primary">
                    <FontAwesomeIcon icon="spinner" spin /> Create
                  </Button>
                ) : (
                  <Button color="primary">Create</Button>
                )}
              </Form>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}
const mapDispatch = {
  create: equipmentActions.create,
  addImage: equipmentActions.uploadImage
};

function mapStateToProps(state) {
  const { creating = false, error, imgUploading, imgUrl } = state.equipment;
  return { creating, error, imgUploading, imgUrl };
}

const connectedAddEquipment = connect(
  mapStateToProps,
  mapDispatch
)(AddEquipment);
export { connectedAddEquipment as AddEquipment };
