import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Col, Card, CardHeader, CardBody, CardFooter, Button } from 'reactstrap';
import { date } from '../_helpers';

export function EventCard(props) {
  const { event } = props;
  return (
    <Col xs={12} sm={4} className="mb-3 event-card">
      <Card className="shadow h-100">
        <CardHeader>
          <h5>{event.name}</h5>
          <p>
            {date.event(event.startTime)} - {date.event(event.endTime)}
          </p>
        </CardHeader>
        <CardBody>
          <div className="content" dangerouslySetInnerHTML={{ __html: event.content }} />
          <Button tag={Link} block color="primary" to={`/Events/View/${event.path}`}>
            More
          </Button>
        </CardBody>
        <CardFooter tag={Link} to={`/Events/View/${event.path}`}>
          <div className="loc-name">{event.location.name}</div>
          <div className="loc-addr">{event.location.location}</div>
        </CardFooter>
      </Card>
    </Col>
  );
}
EventCard.propTypes = {
  event: PropTypes.object.isRequired
};
